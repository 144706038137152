import Axios, { Canceler } from "axios";
import { useEffect, useMemo } from "react";
import { PayloadBaseType } from "../types/common.types";

const CancelToken = Axios.CancelToken;

/** An extendable payload base for axios calls with cancel token functionality provided.
 * See: https://axios-http.com/docs/cancellation
 * @param widget widget name (required)
 * @param props optional payload params
 */
export const usePayloadBase = (widget?: string, props?: Record<string, unknown>) => {
  const cancels: Canceler[] = useMemo<Canceler[]>(() => [], []);

  const payloadBase: PayloadBaseType = useMemo(() => {
    return {
      widget,
      ...props,
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c);
      }),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widget]);

  useEffect(() => {
    // Clean up
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    payloadBase,
  };
};
