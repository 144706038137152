/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
//Get product info
export const UPDATE_CATEGORIES_STATE = "UPDATE_CATEGORIES_STATE_REQUESTED";
