import { HOME } from "constants/routes";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

/**
 *  A simple hook for just refreshing the current route
 *  See: https://dev.to/zbmarius/react-route-refresh-without-page-reload-1907
 **/

export const useRefresh = (resetRoute: string = HOME) => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname;

  let handler: any;

  const refresh = () => {
    if (path === HOME) {
      window.location.reload();
    } else {
      navigate(resetRoute);
      handler = setTimeout(() => navigate(path), 10);
    }
  };

  useEffect(() => {
    return () => handler && clearTimeout(handler);
  }, [handler]);

  return refresh;
};
