import { ADDRESS_SHIPPING, EMPTY_STRING } from "constants/common";

export const COMMON = {
  COUNTRY_CODE: "FI",
  CURRENCY_CODE: "EUR",
  LANGUAGE_ID: -103, // Finnish
};

export const ORDER = {
  TAXCATEGORY: {
    SALES: "VATFI",
    SHIPPING: "SHIPVATFI",
  },
};

export const DIMENSIONS = {
  THUMBNAIL: {
    WIDTH: 200,
    HEIGHT: 200,
  },
  FULL_IMAGE: {
    WIDTH: 800,
    HEIGHT: 800,
  },
  ZOOM_IMAGE: {
    WIDTH: 2000,
    HEIGHT: 1600,
  },
};

export const CATEGORY_IDENTIFIERS = {
  PRESALE: "SALES_1440396178133",
  SALES_BRANDS: "SALES_BRANDS",
  BRANDS: "3074457345637431168",
};

export const PRODUCT_ATTRIBUTES = {
  PRE_SALE: "PreSale",
  NEW_PRODUCT: "SuomiTradingNewProduct",
};

export const PRODUCT_USERDATA = {
  OFFERPRICE: "offerPrice",
  OFFERPRICEWITHOUTTAX: "offerPriceWithOutTax",
  CONTRACTPRICEWITHOUTTAX: "contractPriceWithOutTax",
  VATRATE: "vatRate",
};
export interface AddressFormData {
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  country: string;
  zipCode: string;
  phone1: string;
  nickName: string;
  email1: string;
  addressType: string;
}

export const ADDRESS_FORM_INIT: AddressFormData = {
  firstName: EMPTY_STRING,
  lastName: EMPTY_STRING,
  addressLine1: EMPTY_STRING,
  addressLine2: EMPTY_STRING,
  city: EMPTY_STRING,
  country: COMMON.COUNTRY_CODE,
  zipCode: EMPTY_STRING,
  phone1: EMPTY_STRING,
  nickName: EMPTY_STRING,
  email1: EMPTY_STRING,
  addressType: ADDRESS_SHIPPING,
};

export const ADDRESS_ATTRIBUTES = {
  ADDRESSFIELD1: "addressField1",
};

export const ProductUOMs = {
  /** Piece (kpl) */
  C62: "C62",
  /** Pair */
  PR: "PR",
  /** Display */
  DSP: "DSP",
};

// Product details
export const UOM = "UOM";
/** Batch size */
export const BX = "BX";
/** Pallet size */
export const PF = "PF";
export const EAN = "EAN";

export const SHIPPINGMODE_PICKUP = "PickUp";

export const SITE = {
  CONFIG: {
    STR_CARGO_LIMIT: "str.cargo.limit",
    STR_PRESALE_TOPCATEGORYIDENTIFIER: "str.preSale.topCategoryIdentifier",
  },
};
