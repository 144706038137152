/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CartCart } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CartUsablePaymentInfo } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { CartUsableShippingInfo } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceOrderBeansUsableBillingAddressListDataBeanIBMUsableBillingAddressListSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommercePaymentBeansBuyerPurchaseOrderDataBeanIBMBuyerPurchaseOrderSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommercePaymentBeansPaymentPolicyListDataBeanIBMPaymentPolicyListDetailed } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceUtfBeansPAttributeDataBeanIBMPAttributeDetailed } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds one or more order items to the shopping cart.
         * @summary Add items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription} [body] The request object for AddOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartAddOrderItem: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartAddOrderItem', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds a pre-configuration of a dynamic kit to the cart.
         * @summary Add order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest} [comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest] The request body for adding a dynamic kit configuration to cart.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartAddPreConfigurationOrderItem: async (storeId: string, responseFormat?: string, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest?: ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartAddPreConfigurationOrderItem', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/add_preconfiguration_to_cart`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Applies the quick checkout profile to the shopping cart.
         * @summary Apply quick checkout profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] Request body required for apply checkout profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartApplyCheckoutProfile: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartApplyCheckoutProfile', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/applyCheckoutProfile`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCalculateOrder1: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCalculateOrder1', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/calculate`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The request object for calculate order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCalculateOrder2: async (storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCalculateOrder2', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartCalculateOrder2', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/calculate`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels the specified order.
         * @summary Cancel order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCancelOrder: async (storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCancelOrder', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartCancelOrder', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/cancel_order`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forcedCancel !== undefined) {
                localVarQueryParameter['forcedCancel'] = forcedCancel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes all order items from the shopping cart.
         * @summary Delete items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCancelOrderInCart: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCancelOrderInCart', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks out the shopping cart.
         * @summary Check out
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest} [body] The request object for checkout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCheckOut: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCheckOut', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/checkout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies the order into a new order.
         * @summary Copy order
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest} [body] The request object for copy order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCopyOrder: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCopyOrder', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/copy_order`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an order.
         * @summary Create order
         * @param {string} storeId The store identifier.
         * @param {string} description The description for the order.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCreateOrder: async (storeId: string, description: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCreateOrder', 'storeId', storeId)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('cartCreateOrder', 'description', description)
            const localVarPath = `/store/{storeId}/cart/create_order`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the CSR to cancel the specified order.
         * @summary Cancel order (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCsrCancelOrder: async (storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCsrCancelOrder', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartCsrCancelOrder', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/csrcancel_order`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forcedCancel !== undefined) {
                localVarQueryParameter['forcedCancel'] = forcedCancel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the CSR to cancel the specific order on behalf of a shopper.
         * @summary Cancel order (CSR on behalf of)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCsrCancelOrderOnBehalf: async (storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartCsrCancelOrderOnBehalf', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartCsrCancelOrderOnBehalf', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/csrcancel_order_onbehalf`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forcedCancel !== undefined) {
                localVarQueryParameter['forcedCancel'] = forcedCancel;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified order item from the order.
         * @summary Delete order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest} [body] The request body for deleting an order item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartDeleteOrderItem: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartDeleteOrderItem', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/delete_order_item`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets buyer purchase order information for the specified buyer purchase order ID.
         * @summary Get purchase order information
         * @param {string} storeId The store identifier.
         * @param {string} buyerPurchaseOrderId The buyer purchase order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetBuyerPurchaseOrderDataBean: async (storeId: string, buyerPurchaseOrderId: string, responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetBuyerPurchaseOrderDataBean', 'storeId', storeId)
            // verify required parameter 'buyerPurchaseOrderId' is not null or undefined
            assertParamExists('cartGetBuyerPurchaseOrderDataBean', 'buyerPurchaseOrderId', buyerPurchaseOrderId)
            const localVarPath = `/store/{storeId}/cart/@self/buyer_purchase_order/{buyerPurchaseOrderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"buyerPurchaseOrderId"}}`, String(buyerPurchaseOrderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets order details for the shopping cart.
         * @summary Get order details
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the locale parameter will be used. If locale isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [sortOrderItemBy] The sort order of the results for example, sort by orderItemID.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetCart: async (storeId: string, langId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, sortOrderItemBy?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetCart', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (sortOrderItemBy !== undefined) {
                localVarQueryParameter['sortOrderItemBy'] = sortOrderItemBy;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets payment attribute order information for the terms and conditions ID.
         * @summary Get terms and conditions
         * @param {string} storeId The store identifier.
         * @param {string} initKeyReferenceNumber The referenceNumber key of the access bean.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetPAttributeDataBean: async (storeId: string, initKeyReferenceNumber: string, responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetPAttributeDataBean', 'storeId', storeId)
            // verify required parameter 'initKeyReferenceNumber' is not null or undefined
            assertParamExists('cartGetPAttributeDataBean', 'initKeyReferenceNumber', initKeyReferenceNumber)
            const localVarPath = `/store/{storeId}/cart/@self/pattribute/{initKey_referenceNumber}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"initKey_referenceNumber"}}`, String(initKeyReferenceNumber));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Lists payment policies for the store.
         * @summary Get payment policy list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetPaymentPolicyListDataBean: async (storeId: string, responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetPaymentPolicyListDataBean', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/payment_policy_list`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets billing address information for the specified order ID.
         * @summary Get billing address
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [paymentTCId] The payment Term &amp; Condition identifier for this payment instruction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableBillingAddressListTCDataBean: async (storeId: string, orderId: string, responseFormat?: string, profileName?: string, paymentTCId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetUsableBillingAddressListTCDataBean', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartGetUsableBillingAddressListTCDataBean', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/@self/usable_billing_address/{orderId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (paymentTCId !== undefined) {
                localVarQueryParameter['paymentTCId'] = paymentTCId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets payment information for the shopping cart.
         * @summary Get payment information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsablePaymentInfo: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartGetUsablePaymentInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/usable_payment_info`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the CSR to lock the shopping cart.
         * @summary Lock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartLockCart: async (storeId: string, cartId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartLockCart', 'storeId', storeId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartLockCart', 'cartId', cartId)
            const localVarPath = `/store/{storeId}/cart/{cartId}/lock`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"cartId"}}`, String(cartId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the buyer administrator or CSR to lock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Lock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartLockCartOnBehalf: async (storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartLockCartOnBehalf', 'storeId', storeId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartLockCartOnBehalf', 'cartId', cartId)
            const localVarPath = `/store/{storeId}/cart/{cartId}/lockOnBehalf`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"cartId"}}`, String(cartId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forUser !== undefined) {
                localVarQueryParameter['forUser'] = forUser;
            }

            if (forUserId !== undefined) {
                localVarQueryParameter['forUserId'] = forUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Moves order items from one order to another.
         * @summary Move order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest} [body] The request body for MoveOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartMoveOrderItem: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartMoveOrderItem', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/move_order_item`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Renews inventory status, price and address ID for order items. Remove order items that are out of stock.
         * @summary Renew inventory status
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartOrderItemDisplay: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartOrderItemDisplay', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/renew_order_items`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Prepares the shopping cart for checkout. This method must be called before the checkout service.
         * @summary Prepare for checkout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] The request object for preCheckout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartPreCheckout: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartPreCheckout', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/precheckout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a scheduled order. The order is related to a scheduled job.
         * @summary Create scheduled order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest} [body] The request object for scheduleOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartScheduleOrder: async (storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartScheduleOrder', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartScheduleOrder', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/schedule_order`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the specified order as the pending order.
         * @summary Set pending order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest} [body] The request object for SetPendingOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartSetPendingOrder: async (storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartSetPendingOrder', 'storeId', storeId)
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('cartSetPendingOrder', 'orderId', orderId)
            const localVarPath = `/store/{storeId}/cart/{orderId}/set_pending_order`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"orderId"}}`, String(orderId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unlocks the shopping cart by a CSR.
         * @summary Unlock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUnlockCart: async (storeId: string, cartId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartUnlockCart', 'storeId', storeId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartUnlockCart', 'cartId', cartId)
            const localVarPath = `/store/{storeId}/cart/{cartId}/unlock`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"cartId"}}`, String(cartId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the buyer administrator or CSR to unlock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Unlock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUnlockCartOnBehalf: async (storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartUnlockCartOnBehalf', 'storeId', storeId)
            // verify required parameter 'cartId' is not null or undefined
            assertParamExists('cartUnlockCartOnBehalf', 'cartId', cartId)
            const localVarPath = `/store/{storeId}/cart/{cartId}/unlockOnBehalf`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"cartId"}}`, String(cartId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (forUser !== undefined) {
                localVarQueryParameter['forUser'] = forUser;
            }

            if (forUserId !== undefined) {
                localVarQueryParameter['forUserId'] = forUserId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates one or more order items in the shopping cart.
         * @summary Update order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription} [body] Update order item body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUpdateOrderItem: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartUpdateOrderItem', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/update_order_item`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the reward option.
         * @summary Update reward option
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest} [body] The request body for updating a reward option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUpdateRewardOption: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('cartUpdateRewardOption', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/update_reward_option`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets usable shipping information for the shopping cart by address.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsableShippingMode: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getUsableShippingMode', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/usable_shipping_mode`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds one or more order items to the shopping cart.
         * @summary Add items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription} [body] The request object for AddOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartAddOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartAddOrderItem(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds a pre-configuration of a dynamic kit to the cart.
         * @summary Add order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest} [comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest] The request body for adding a dynamic kit configuration to cart.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartAddPreConfigurationOrderItem(storeId: string, responseFormat?: string, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest?: ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartAddPreConfigurationOrderItem(storeId, responseFormat, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Applies the quick checkout profile to the shopping cart.
         * @summary Apply quick checkout profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] Request body required for apply checkout profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartApplyCheckoutProfile(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartApplyCheckoutProfile(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCalculateOrder1(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCalculateOrder1(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The request object for calculate order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCalculateOrder2(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCalculateOrder2(storeId, orderId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancels the specified order.
         * @summary Cancel order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCancelOrder(storeId, orderId, responseFormat, forcedCancel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes all order items from the shopping cart.
         * @summary Delete items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCancelOrderInCart(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCancelOrderInCart(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks out the shopping cart.
         * @summary Check out
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest} [body] The request object for checkout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCheckOut(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCheckOut(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies the order into a new order.
         * @summary Copy order
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest} [body] The request object for copy order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCopyOrder(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCopyOrder(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an order.
         * @summary Create order
         * @param {string} storeId The store identifier.
         * @param {string} description The description for the order.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCreateOrder(storeId: string, description: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCreateOrder(storeId, description, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the CSR to cancel the specified order.
         * @summary Cancel order (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCsrCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCsrCancelOrder(storeId, orderId, responseFormat, forcedCancel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the CSR to cancel the specific order on behalf of a shopper.
         * @summary Cancel order (CSR on behalf of)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartCsrCancelOrderOnBehalf(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartCsrCancelOrderOnBehalf(storeId, orderId, responseFormat, forcedCancel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified order item from the order.
         * @summary Delete order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest} [body] The request body for deleting an order item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartDeleteOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartDeleteOrderItem(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets buyer purchase order information for the specified buyer purchase order ID.
         * @summary Get purchase order information
         * @param {string} storeId The store identifier.
         * @param {string} buyerPurchaseOrderId The buyer purchase order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetBuyerPurchaseOrderDataBean(storeId: string, buyerPurchaseOrderId: string, responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommercePaymentBeansBuyerPurchaseOrderDataBeanIBMBuyerPurchaseOrderSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetBuyerPurchaseOrderDataBean(storeId, buyerPurchaseOrderId, responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets order details for the shopping cart.
         * @summary Get order details
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the locale parameter will be used. If locale isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [sortOrderItemBy] The sort order of the results for example, sort by orderItemID.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetCart(storeId: string, langId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, sortOrderItemBy?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartCart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetCart(storeId, langId, responseFormat, pageNumber, pageSize, currency, sortOrderItemBy, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets payment attribute order information for the terms and conditions ID.
         * @summary Get terms and conditions
         * @param {string} storeId The store identifier.
         * @param {string} initKeyReferenceNumber The referenceNumber key of the access bean.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetPAttributeDataBean(storeId: string, initKeyReferenceNumber: string, responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceUtfBeansPAttributeDataBeanIBMPAttributeDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetPAttributeDataBean(storeId, initKeyReferenceNumber, responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Lists payment policies for the store.
         * @summary Get payment policy list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetPaymentPolicyListDataBean(storeId: string, responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommercePaymentBeansPaymentPolicyListDataBeanIBMPaymentPolicyListDetailed>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetPaymentPolicyListDataBean(storeId, responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets billing address information for the specified order ID.
         * @summary Get billing address
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [paymentTCId] The payment Term &amp; Condition identifier for this payment instruction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetUsableBillingAddressListTCDataBean(storeId: string, orderId: string, responseFormat?: string, profileName?: string, paymentTCId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceOrderBeansUsableBillingAddressListDataBeanIBMUsableBillingAddressListSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetUsableBillingAddressListTCDataBean(storeId, orderId, responseFormat, profileName, paymentTCId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets payment information for the shopping cart.
         * @summary Get payment information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartGetUsablePaymentInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartUsablePaymentInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartGetUsablePaymentInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the CSR to lock the shopping cart.
         * @summary Lock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartLockCart(storeId: string, cartId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartLockCart(storeId, cartId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the buyer administrator or CSR to lock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Lock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartLockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartLockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Moves order items from one order to another.
         * @summary Move order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest} [body] The request body for MoveOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartMoveOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartMoveOrderItem(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Renews inventory status, price and address ID for order items. Remove order items that are out of stock.
         * @summary Renew inventory status
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartOrderItemDisplay(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartOrderItemDisplay(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Prepares the shopping cart for checkout. This method must be called before the checkout service.
         * @summary Prepare for checkout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] The request object for preCheckout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartPreCheckout(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartPreCheckout(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a scheduled order. The order is related to a scheduled job.
         * @summary Create scheduled order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest} [body] The request object for scheduleOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartScheduleOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartScheduleOrder(storeId, orderId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets the specified order as the pending order.
         * @summary Set pending order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest} [body] The request object for SetPendingOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartSetPendingOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartSetPendingOrder(storeId, orderId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unlocks the shopping cart by a CSR.
         * @summary Unlock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartUnlockCart(storeId: string, cartId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartUnlockCart(storeId, cartId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the buyer administrator or CSR to unlock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Unlock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartUnlockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartUnlockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates one or more order items in the shopping cart.
         * @summary Update order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription} [body] Update order item body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartUpdateOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartUpdateOrderItem(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the reward option.
         * @summary Update reward option
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest} [body] The request body for updating a reward option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cartUpdateRewardOption(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cartUpdateRewardOption(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets usable shipping information for the shopping cart by address.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsableShippingMode(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartUsableShippingInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsableShippingMode(storeId, responseFormat, pageNumber, pageSize, orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * Adds one or more order items to the shopping cart.
         * @summary Add items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription} [body] The request object for AddOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartAddOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer> {
            return localVarFp.cartAddOrderItem(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds a pre-configuration of a dynamic kit to the cart.
         * @summary Add order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest} [comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest] The request body for adding a dynamic kit configuration to cart.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartAddPreConfigurationOrderItem(storeId: string, responseFormat?: string, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest?: ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer> {
            return localVarFp.cartAddPreConfigurationOrderItem(storeId, responseFormat, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Applies the quick checkout profile to the shopping cart.
         * @summary Apply quick checkout profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] Request body required for apply checkout profile.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartApplyCheckoutProfile(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartApplyCheckoutProfile(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCalculateOrder1(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any): AxiosPromise<object> {
            return localVarFp.cartCalculateOrder1(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Calculates the price of the order.
         * @summary Calculate order price
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The request object for calculate order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCalculateOrder2(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any): AxiosPromise<void> {
            return localVarFp.cartCalculateOrder2(storeId, orderId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels the specified order.
         * @summary Cancel order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): AxiosPromise<object> {
            return localVarFp.cartCancelOrder(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes all order items from the shopping cart.
         * @summary Delete items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCancelOrderInCart(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cartCancelOrderInCart(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Checks out the shopping cart.
         * @summary Check out
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest} [body] The request object for checkout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCheckOut(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartCheckOut(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies the order into a new order.
         * @summary Copy order
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest} [body] The request object for copy order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCopyOrder(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest> {
            return localVarFp.cartCopyOrder(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates an order.
         * @summary Create order
         * @param {string} storeId The store identifier.
         * @param {string} description The description for the order.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest} [body] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCreateOrder(storeId: string, description: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest, options?: any): AxiosPromise<object> {
            return localVarFp.cartCreateOrder(storeId, description, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the CSR to cancel the specified order.
         * @summary Cancel order (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCsrCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cartCsrCancelOrder(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the CSR to cancel the specific order on behalf of a shopper.
         * @summary Cancel order (CSR on behalf of)
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartCsrCancelOrderOnBehalf(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cartCsrCancelOrderOnBehalf(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified order item from the order.
         * @summary Delete order item
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest} [body] The request body for deleting an order item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartDeleteOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartDeleteOrderItem(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets buyer purchase order information for the specified buyer purchase order ID.
         * @summary Get purchase order information
         * @param {string} storeId The store identifier.
         * @param {string} buyerPurchaseOrderId The buyer purchase order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetBuyerPurchaseOrderDataBean(storeId: string, buyerPurchaseOrderId: string, responseFormat?: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommercePaymentBeansBuyerPurchaseOrderDataBeanIBMBuyerPurchaseOrderSummary> {
            return localVarFp.cartGetBuyerPurchaseOrderDataBean(storeId, buyerPurchaseOrderId, responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets order details for the shopping cart.
         * @summary Get order details
         * @param {string} storeId The store identifier.
         * @param {string} [langId] Language identifier. If not specified, the locale parameter will be used. If locale isn\&#39;t specified, then the store default language shall be used.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [sortOrderItemBy] The sort order of the results for example, sort by orderItemID.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetCart(storeId: string, langId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, sortOrderItemBy?: string, profileName?: string, options?: any): AxiosPromise<CartCart> {
            return localVarFp.cartGetCart(storeId, langId, responseFormat, pageNumber, pageSize, currency, sortOrderItemBy, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets payment attribute order information for the terms and conditions ID.
         * @summary Get terms and conditions
         * @param {string} storeId The store identifier.
         * @param {string} initKeyReferenceNumber The referenceNumber key of the access bean.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetPAttributeDataBean(storeId: string, initKeyReferenceNumber: string, responseFormat?: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceUtfBeansPAttributeDataBeanIBMPAttributeDetailed> {
            return localVarFp.cartGetPAttributeDataBean(storeId, initKeyReferenceNumber, responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Lists payment policies for the store.
         * @summary Get payment policy list
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetPaymentPolicyListDataBean(storeId: string, responseFormat?: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommercePaymentBeansPaymentPolicyListDataBeanIBMPaymentPolicyListDetailed> {
            return localVarFp.cartGetPaymentPolicyListDataBean(storeId, responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets billing address information for the specified order ID.
         * @summary Get billing address
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [paymentTCId] The payment Term &amp; Condition identifier for this payment instruction.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsableBillingAddressListTCDataBean(storeId: string, orderId: string, responseFormat?: string, profileName?: string, paymentTCId?: string, options?: any): AxiosPromise<ComIbmCommerceOrderBeansUsableBillingAddressListDataBeanIBMUsableBillingAddressListSummary> {
            return localVarFp.cartGetUsableBillingAddressListTCDataBean(storeId, orderId, responseFormat, profileName, paymentTCId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets payment information for the shopping cart.
         * @summary Get payment information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartGetUsablePaymentInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): AxiosPromise<CartUsablePaymentInfo> {
            return localVarFp.cartGetUsablePaymentInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the CSR to lock the shopping cart.
         * @summary Lock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartLockCart(storeId: string, cartId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartLockCart(storeId, cartId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the buyer administrator or CSR to lock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Lock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartLockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartLockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Moves order items from one order to another.
         * @summary Move order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest} [body] The request body for MoveOrderItem.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartMoveOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest> {
            return localVarFp.cartMoveOrderItem(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Renews inventory status, price and address ID for order items. Remove order items that are out of stock.
         * @summary Renew inventory status
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartOrderItemDisplay(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.cartOrderItemDisplay(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Prepares the shopping cart for checkout. This method must be called before the checkout service.
         * @summary Prepare for checkout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] The request object for preCheckout.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartPreCheckout(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartPreCheckout(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a scheduled order. The order is related to a scheduled job.
         * @summary Create scheduled order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest} [body] The request object for scheduleOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartScheduleOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest> {
            return localVarFp.cartScheduleOrder(storeId, orderId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Sets the specified order as the pending order.
         * @summary Set pending order
         * @param {string} storeId The store identifier.
         * @param {string} orderId The order ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest} [body] The request object for SetPendingOrder.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartSetPendingOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest> {
            return localVarFp.cartSetPendingOrder(storeId, orderId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Unlocks the shopping cart by a CSR.
         * @summary Unlock cart (CSR)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUnlockCart(storeId: string, cartId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartUnlockCart(storeId, cartId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the buyer administrator or CSR to unlock the shopping cart. The administrator must have already established a session to act on behalf of a user.
         * @summary Unlock cart (buyer administrator)
         * @param {string} storeId The store identifier.
         * @param {string} cartId Order identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [forUser] User name to act on behalf of.
         * @param {string} [forUserId] User identifier to act on behalf of.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUnlockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer> {
            return localVarFp.cartUnlockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates one or more order items in the shopping cart.
         * @summary Update order items
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription} [body] Update order item body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUpdateOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerOrderWithOrderItemContainer> {
            return localVarFp.cartUpdateOrderItem(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the reward option.
         * @summary Update reward option
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest} [body] The request body for updating a reward option.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cartUpdateRewardOption(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest> {
            return localVarFp.cartUpdateRewardOption(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets usable shipping information for the shopping cart by address.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
         * @param {string} [orderId] The order ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsableShippingMode(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any): AxiosPromise<CartUsableShippingInfo> {
            return localVarFp.getUsableShippingMode(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * Adds one or more order items to the shopping cart.
     * @summary Add items
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription} [body] The request object for AddOrderItem.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartAddOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerAddOrderItemBodyDescription, options?: any) {
        return CartApiFp(this.configuration).cartAddOrderItem(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds a pre-configuration of a dynamic kit to the cart.
     * @summary Add order item
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest} [comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest] The request body for adding a dynamic kit configuration to cart.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartAddPreConfigurationOrderItem(storeId: string, responseFormat?: string, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest?: ComIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options?: any) {
        return CartApiFp(this.configuration).cartAddPreConfigurationOrderItem(storeId, responseFormat, comIbmCommerceRestOrderHandlerCartHandlerAddPreConfigurationToCartRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Applies the quick checkout profile to the shopping cart.
     * @summary Apply quick checkout profile
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] Request body required for apply checkout profile.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartApplyCheckoutProfile(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any) {
        return CartApiFp(this.configuration).cartApplyCheckoutProfile(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculates the price of the order.
     * @summary Calculate order price
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The body data required for the REST method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCalculateOrder1(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any) {
        return CartApiFp(this.configuration).cartCalculateOrder1(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Calculates the price of the order.
     * @summary Calculate order price
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest} [body] The request object for calculate order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCalculateOrder2(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCalculateOrderRequest, options?: any) {
        return CartApiFp(this.configuration).cartCalculateOrder2(storeId, orderId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels the specified order.
     * @summary Cancel order
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any) {
        return CartApiFp(this.configuration).cartCancelOrder(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes all order items from the shopping cart.
     * @summary Delete items
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCancelOrderInCart(storeId: string, responseFormat?: string, options?: any) {
        return CartApiFp(this.configuration).cartCancelOrderInCart(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Checks out the shopping cart.
     * @summary Check out
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest} [body] The request object for checkout.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCheckOut(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCheckoutRequest, options?: any) {
        return CartApiFp(this.configuration).cartCheckOut(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies the order into a new order.
     * @summary Copy order
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest} [body] The request object for copy order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCopyOrder(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCopyOrderRequest, options?: any) {
        return CartApiFp(this.configuration).cartCopyOrder(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates an order.
     * @summary Create order
     * @param {string} storeId The store identifier.
     * @param {string} description The description for the order.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest} [body] The body data required for the REST method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCreateOrder(storeId: string, description: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerCreateOrderRequest, options?: any) {
        return CartApiFp(this.configuration).cartCreateOrder(storeId, description, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the CSR to cancel the specified order.
     * @summary Cancel order (CSR)
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCsrCancelOrder(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any) {
        return CartApiFp(this.configuration).cartCsrCancelOrder(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the CSR to cancel the specific order on behalf of a shopper.
     * @summary Cancel order (CSR on behalf of)
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forcedCancel] This parameter is used to cancel an order that has deposited payment.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartCsrCancelOrderOnBehalf(storeId: string, orderId: string, responseFormat?: string, forcedCancel?: string, options?: any) {
        return CartApiFp(this.configuration).cartCsrCancelOrderOnBehalf(storeId, orderId, responseFormat, forcedCancel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified order item from the order.
     * @summary Delete order item
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest} [body] The request body for deleting an order item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartDeleteOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerDeleteOrderItemRequest, options?: any) {
        return CartApiFp(this.configuration).cartDeleteOrderItem(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets buyer purchase order information for the specified buyer purchase order ID.
     * @summary Get purchase order information
     * @param {string} storeId The store identifier.
     * @param {string} buyerPurchaseOrderId The buyer purchase order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetBuyerPurchaseOrderDataBean(storeId: string, buyerPurchaseOrderId: string, responseFormat?: string, profileName?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetBuyerPurchaseOrderDataBean(storeId, buyerPurchaseOrderId, responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets order details for the shopping cart.
     * @summary Get order details
     * @param {string} storeId The store identifier.
     * @param {string} [langId] Language identifier. If not specified, the locale parameter will be used. If locale isn\&#39;t specified, then the store default language shall be used.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {string} [sortOrderItemBy] The sort order of the results for example, sort by orderItemID.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetCart(storeId: string, langId?: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, sortOrderItemBy?: string, profileName?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetCart(storeId, langId, responseFormat, pageNumber, pageSize, currency, sortOrderItemBy, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets payment attribute order information for the terms and conditions ID.
     * @summary Get terms and conditions
     * @param {string} storeId The store identifier.
     * @param {string} initKeyReferenceNumber The referenceNumber key of the access bean.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetPAttributeDataBean(storeId: string, initKeyReferenceNumber: string, responseFormat?: string, profileName?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetPAttributeDataBean(storeId, initKeyReferenceNumber, responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Lists payment policies for the store.
     * @summary Get payment policy list
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetPaymentPolicyListDataBean(storeId: string, responseFormat?: string, profileName?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetPaymentPolicyListDataBean(storeId, responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets billing address information for the specified order ID.
     * @summary Get billing address
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [paymentTCId] The payment Term &amp; Condition identifier for this payment instruction.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetUsableBillingAddressListTCDataBean(storeId: string, orderId: string, responseFormat?: string, profileName?: string, paymentTCId?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetUsableBillingAddressListTCDataBean(storeId, orderId, responseFormat, profileName, paymentTCId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets payment information for the shopping cart.
     * @summary Get payment information
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [orderId] The order ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartGetUsablePaymentInfo(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any) {
        return CartApiFp(this.configuration).cartGetUsablePaymentInfo(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the CSR to lock the shopping cart.
     * @summary Lock cart (CSR)
     * @param {string} storeId The store identifier.
     * @param {string} cartId Order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartLockCart(storeId: string, cartId: string, responseFormat?: string, options?: any) {
        return CartApiFp(this.configuration).cartLockCart(storeId, cartId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the buyer administrator or CSR to lock the shopping cart. The administrator must have already established a session to act on behalf of a user.
     * @summary Lock cart (buyer administrator)
     * @param {string} storeId The store identifier.
     * @param {string} cartId Order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forUser] User name to act on behalf of.
     * @param {string} [forUserId] User identifier to act on behalf of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartLockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any) {
        return CartApiFp(this.configuration).cartLockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Moves order items from one order to another.
     * @summary Move order items
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest} [body] The request body for MoveOrderItem.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartMoveOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderItemMoveRequest, options?: any) {
        return CartApiFp(this.configuration).cartMoveOrderItem(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Renews inventory status, price and address ID for order items. Remove order items that are out of stock.
     * @summary Renew inventory status
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartOrderItemDisplay(storeId: string, responseFormat?: string, options?: any) {
        return CartApiFp(this.configuration).cartOrderItemDisplay(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Prepares the shopping cart for checkout. This method must be called before the checkout service.
     * @summary Prepare for checkout
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer} [body] The request object for preCheckout.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartPreCheckout(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderIdContainer, options?: any) {
        return CartApiFp(this.configuration).cartPreCheckout(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a scheduled order. The order is related to a scheduled job.
     * @summary Create scheduled order
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest} [body] The request object for scheduleOrder.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartScheduleOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerOrderScheduleRequest, options?: any) {
        return CartApiFp(this.configuration).cartScheduleOrder(storeId, orderId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets the specified order as the pending order.
     * @summary Set pending order
     * @param {string} storeId The store identifier.
     * @param {string} orderId The order ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest} [body] The request object for SetPendingOrder.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartSetPendingOrder(storeId: string, orderId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerSetPendingOrderRequest, options?: any) {
        return CartApiFp(this.configuration).cartSetPendingOrder(storeId, orderId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unlocks the shopping cart by a CSR.
     * @summary Unlock cart (CSR)
     * @param {string} storeId The store identifier.
     * @param {string} cartId Order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartUnlockCart(storeId: string, cartId: string, responseFormat?: string, options?: any) {
        return CartApiFp(this.configuration).cartUnlockCart(storeId, cartId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the buyer administrator or CSR to unlock the shopping cart. The administrator must have already established a session to act on behalf of a user.
     * @summary Unlock cart (buyer administrator)
     * @param {string} storeId The store identifier.
     * @param {string} cartId Order identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [forUser] User name to act on behalf of.
     * @param {string} [forUserId] User identifier to act on behalf of.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartUnlockCartOnBehalf(storeId: string, cartId: string, responseFormat?: string, forUser?: string, forUserId?: string, options?: any) {
        return CartApiFp(this.configuration).cartUnlockCartOnBehalf(storeId, cartId, responseFormat, forUser, forUserId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates one or more order items in the shopping cart.
     * @summary Update order items
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription} [body] Update order item body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartUpdateOrderItem(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerUpdateOrderItemBodyDescription, options?: any) {
        return CartApiFp(this.configuration).cartUpdateOrderItem(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the reward option.
     * @summary Update reward option
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest} [body] The request body for updating a reward option.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public cartUpdateRewardOption(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestOrderHandlerCartHandlerRewardChoiceUpdateRequest, options?: any) {
        return CartApiFp(this.configuration).cartUpdateRewardOption(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets usable shipping information for the shopping cart by address.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&quot;pageSize\&quot; must be specified for paging to work.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&quot;pageNumber\&quot; must be specified for paging to work.
     * @param {string} [orderId] The order ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public getUsableShippingMode(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, orderId?: string, options?: any) {
        return CartApiFp(this.configuration).getUsableShippingMode(storeId, responseFormat, pageNumber, pageSize, orderId, options).then((request) => request(this.axios, this.basePath));
    }
}
