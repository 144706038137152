/* tslint:disable */
/* eslint-disable */
/**
 * API definitions for Seller organization requests approval service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { Comment } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { CommentBody } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { CommentCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerAdministrator } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerAdministratorCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerAdministratorPatchRequest } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerCategory } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerCategoryCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerDescription } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerDescriptionCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerMarketplace } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerMarketplaceCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerOrganizationPatchRequest } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerOrganizationRequest } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerOrganizationRequestBody } from '../../../../../com/hcl/commerce/typescript/approvals';
// @ts-ignore
import { SellerOrganizationRequestCollection } from '../../../../../com/hcl/commerce/typescript/approvals';
/**
 * SellerOrganizationRequestsApi - axios parameter creator
 * @export
 */
export const SellerOrganizationRequestsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approve the seller organization request
         * @summary Approve the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveSellerOrganizationRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approveSellerOrganizationRequest', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/approve`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the seller organization request
         * @summary Create the seller organization request
         * @param {SellerOrganizationRequestBody} sellerOrganizationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequest: async (sellerOrganizationRequestBody: SellerOrganizationRequestBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sellerOrganizationRequestBody' is not null or undefined
            assertParamExists('createSellerOrganizationRequest', 'sellerOrganizationRequestBody', sellerOrganizationRequestBody)
            const localVarPath = `/seller-organization-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerOrganizationRequestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a seller organization request administrator.
         * @summary Create a seller organization request administrator.
         * @param {number} id The seller organization registration request id
         * @param {SellerAdministrator} sellerAdministrator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestAdministrator: async (id: number, sellerAdministrator: SellerAdministrator, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSellerOrganizationRequestAdministrator', 'id', id)
            // verify required parameter 'sellerAdministrator' is not null or undefined
            assertParamExists('createSellerOrganizationRequestAdministrator', 'sellerAdministrator', sellerAdministrator)
            const localVarPath = `/seller-organization-requests/{id}/administrators`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerAdministrator, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a seller organization request category
         * @summary Create a seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {SellerCategory} sellerCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestCategory: async (id: number, sellerCategory: SellerCategory, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSellerOrganizationRequestCategory', 'id', id)
            // verify required parameter 'sellerCategory' is not null or undefined
            assertParamExists('createSellerOrganizationRequestCategory', 'sellerCategory', sellerCategory)
            const localVarPath = `/seller-organization-requests/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create the seller organization request comment
         * @summary Create the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {CommentBody} commentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestComment: async (id: number, commentBody: CommentBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSellerOrganizationRequestComment', 'id', id)
            // verify required parameter 'commentBody' is not null or undefined
            assertParamExists('createSellerOrganizationRequestComment', 'commentBody', commentBody)
            const localVarPath = `/seller-organization-requests/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a seller organization request description
         * @summary Create a seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {SellerDescription} sellerDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestDescription: async (id: number, sellerDescription: SellerDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSellerOrganizationRequestDescription', 'id', id)
            // verify required parameter 'sellerDescription' is not null or undefined
            assertParamExists('createSellerOrganizationRequestDescription', 'sellerDescription', sellerDescription)
            const localVarPath = `/seller-organization-requests/{id}/descriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a seller organization request marketplace
         * @summary Create a seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {SellerMarketplace} sellerMarketplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestMarketplace: async (id: number, sellerMarketplace: SellerMarketplace, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createSellerOrganizationRequestMarketplace', 'id', id)
            // verify required parameter 'sellerMarketplace' is not null or undefined
            assertParamExists('createSellerOrganizationRequestMarketplace', 'sellerMarketplace', sellerMarketplace)
            const localVarPath = `/seller-organization-requests/{id}/marketplaces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerMarketplace, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the seller organization request
         * @summary Delete the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequest', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the seller organization administrator
         * @summary Delete the seller organization administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestAdministrator: async (id: number, logonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestAdministrator', 'id', id)
            // verify required parameter 'logonId' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestAdministrator', 'logonId', logonId)
            const localVarPath = `/seller-organization-requests/{id}/administrators/{logonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"logonId"}}`, encodeURIComponent(String(logonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the seller organization request category
         * @summary Delete the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestCategory: async (id: number, categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestCategory', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestCategory', 'categoryId', categoryId)
            const localVarPath = `/seller-organization-requests/{id}/categories/{categoryId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete the seller organization request comment
         * @summary delete the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestComment: async (id: number, commentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestComment', 'id', id)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestComment', 'commentId', commentId)
            const localVarPath = `/seller-organization-requests/{id}/comments/{commentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the seller organization request description
         * @summary Delete the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestDescription: async (id: number, languageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestDescription', 'id', id)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestDescription', 'languageId', languageId)
            const localVarPath = `/seller-organization-requests/{id}/descriptions/{languageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete the seller organization request marketplace
         * @summary Delete the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestMarketplace: async (id: number, marketplaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestMarketplace', 'id', id)
            // verify required parameter 'marketplaceId' is not null or undefined
            assertParamExists('deleteSellerOrganizationRequestMarketplace', 'marketplaceId', marketplaceId)
            const localVarPath = `/seller-organization-requests/{id}/marketplaces/{marketplaceId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"marketplaceId"}}`, encodeURIComponent(String(marketplaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request administrator
         * @summary Get a seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestAdministrator: async (id: number, logonId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestAdministrator', 'id', id)
            // verify required parameter 'logonId' is not null or undefined
            assertParamExists('getSellerOrganizationRequestAdministrator', 'logonId', logonId)
            const localVarPath = `/seller-organization-requests/{id}/administrators/{logonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"logonId"}}`, encodeURIComponent(String(logonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request administrator
         * @summary Get the seller organization request administrators
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;logonId will order the items based on the logonId value in ascending order. &lt;br/&gt;Available values for sort parameter: logonId, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestAdministrators: async (id: number, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestAdministrators', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/administrators`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request
         * @summary Get the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestById', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request categories
         * @summary Get the seller organization request categories
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;categoryId will order the items based on the categoryId value in ascending order. &lt;br/&gt;Available values for sort parameter: categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCategories: async (id: number, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestCategories', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request category
         * @summary Get the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCategoryById: async (id: number, categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestCategoryById', 'id', id)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getSellerOrganizationRequestCategoryById', 'categoryId', categoryId)
            const localVarPath = `/seller-organization-requests/{id}/categories/{categoryId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request comment
         * @summary Get the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCommentById: async (id: number, commentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestCommentById', 'id', id)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getSellerOrganizationRequestCommentById', 'commentId', commentId)
            const localVarPath = `/seller-organization-requests/{id}/comments/{commentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request comments
         * @summary Get the seller organization request comments
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;commentedBy will order the items based on the commentedBy value in ascending order. &lt;br/&gt;Available values for sort parameter: commentedBy,commentedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestComments: async (id: number, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestComments', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/comments`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request description
         * @summary Get the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestDescriptionById: async (id: number, languageId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestDescriptionById', 'id', id)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('getSellerOrganizationRequestDescriptionById', 'languageId', languageId)
            const localVarPath = `/seller-organization-requests/{id}/descriptions/{languageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request descriptions
         * @summary Get the seller organization request descriptions
         * @param {number} id The seller organization registration request id
         * @param {string} [dataLanguageIds] The integer(s) for identifying the language of the seller organization request descriptions. For a list of the integers that map to the supported default languages, refer to the Knowledge Center. Multiple identifiers should be specified as a comma-separated list.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;languageId will order the items based on the languageId value in ascending order. &lt;br/&gt;Available values for sort parameter: languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestDescriptions: async (id: number, dataLanguageIds?: string, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestDescriptions', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/descriptions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dataLanguageIds !== undefined) {
                localVarQueryParameter['dataLanguageIds'] = dataLanguageIds;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request marketplace
         * @summary Get the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestMarketplaceById: async (id: number, marketplaceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestMarketplaceById', 'id', id)
            // verify required parameter 'marketplaceId' is not null or undefined
            assertParamExists('getSellerOrganizationRequestMarketplaceById', 'marketplaceId', marketplaceId)
            const localVarPath = `/seller-organization-requests/{id}/marketplaces/{marketplaceId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"marketplaceId"}}`, encodeURIComponent(String(marketplaceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request marketplaces
         * @summary Get the seller organization request marketplaces
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;storeIdentifier will order the items based on the storeIdentifier value in ascending order. &lt;br/&gt;Available values for sort parameter: storeIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestMarketplaces: async (id: number, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSellerOrganizationRequestMarketplaces', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/marketplaces`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the seller organization request records.
         * @summary Get the seller organization request records
         * @param {number} [storeId] The unique numeric ID for identifying the store
         * @param {string} [searchString] Limits the results to those that match the specified search string. The search will look for matching seller organization names and submitter e-mail addresses.
         * @param {string} [organizationName] Filter the records by seller organization name
         * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status] Filter the records by Status
         * @param {string} [startDate] Filter the records by date range. Specify start date
         * @param {string} [endDate] Filter the records by date range. Specify end date
         * @param {string} [submitterEmail] Filter records by submitter\&#39;s email
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;sellerName will order the items based on the sellerName value in ascending order. &lt;br/&gt;Available values for sort parameter: sellerName, createdBy, createdTime, status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequests: async (storeId?: number, searchString?: string, organizationName?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED', startDate?: string, endDate?: string, submitterEmail?: string, offset?: number, limit?: number, sort?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/seller-organization-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (searchString !== undefined) {
                localVarQueryParameter['searchString'] = searchString;
            }

            if (organizationName !== undefined) {
                localVarQueryParameter['organizationName'] = organizationName;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['startDate'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['endDate'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (submitterEmail !== undefined) {
                localVarQueryParameter['submitterEmail'] = submitterEmail;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject the seller organization request
         * @summary Reject the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectSellerOrganizationRequest: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rejectSellerOrganizationRequest', 'id', id)
            const localVarPath = `/seller-organization-requests/{id}/reject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the seller organization request
         * @summary Update the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {SellerOrganizationPatchRequest} sellerOrganizationPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequest: async (id: number, sellerOrganizationPatchRequest: SellerOrganizationPatchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSellerOrganizationRequest', 'id', id)
            // verify required parameter 'sellerOrganizationPatchRequest' is not null or undefined
            assertParamExists('updateSellerOrganizationRequest', 'sellerOrganizationPatchRequest', sellerOrganizationPatchRequest)
            const localVarPath = `/seller-organization-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerOrganizationPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the seller organization request administrator
         * @summary Update the seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {SellerAdministratorPatchRequest} sellerAdministratorPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestAdministrator: async (id: number, logonId: string, sellerAdministratorPatchRequest: SellerAdministratorPatchRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestAdministrator', 'id', id)
            // verify required parameter 'logonId' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestAdministrator', 'logonId', logonId)
            // verify required parameter 'sellerAdministratorPatchRequest' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestAdministrator', 'sellerAdministratorPatchRequest', sellerAdministratorPatchRequest)
            const localVarPath = `/seller-organization-requests/{id}/administrators/{logonId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"logonId"}}`, encodeURIComponent(String(logonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerAdministratorPatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the seller organization request comment
         * @summary Update the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {CommentBody} commentBody The seller organization request comments body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestComment: async (id: number, commentId: number, commentBody: CommentBody, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestComment', 'id', id)
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestComment', 'commentId', commentId)
            // verify required parameter 'commentBody' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestComment', 'commentBody', commentBody)
            const localVarPath = `/seller-organization-requests/{id}/comments/{commentId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"commentId"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(commentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the seller organization request description
         * @summary Update the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {SellerDescription} sellerDescription The seller organization request description body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestDescription: async (id: number, languageId: number, sellerDescription: SellerDescription, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestDescription', 'id', id)
            // verify required parameter 'languageId' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestDescription', 'languageId', languageId)
            // verify required parameter 'sellerDescription' is not null or undefined
            assertParamExists('updateSellerOrganizationRequestDescription', 'sellerDescription', sellerDescription)
            const localVarPath = `/seller-organization-requests/{id}/descriptions/{languageId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"languageId"}}`, encodeURIComponent(String(languageId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sellerDescription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SellerOrganizationRequestsApi - functional programming interface
 * @export
 */
export const SellerOrganizationRequestsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SellerOrganizationRequestsApiAxiosParamCreator(configuration)
    return {
        /**
         * Approve the seller organization request
         * @summary Approve the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approveSellerOrganizationRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approveSellerOrganizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the seller organization request
         * @summary Create the seller organization request
         * @param {SellerOrganizationRequestBody} sellerOrganizationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequest(sellerOrganizationRequestBody: SellerOrganizationRequestBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequest(sellerOrganizationRequestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a seller organization request administrator.
         * @summary Create a seller organization request administrator.
         * @param {number} id The seller organization registration request id
         * @param {SellerAdministrator} sellerAdministrator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequestAdministrator(id: number, sellerAdministrator: SellerAdministrator, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequestAdministrator(id, sellerAdministrator, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a seller organization request category
         * @summary Create a seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {SellerCategory} sellerCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequestCategory(id: number, sellerCategory: SellerCategory, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequestCategory(id, sellerCategory, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create the seller organization request comment
         * @summary Create the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {CommentBody} commentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequestComment(id: number, commentBody: CommentBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequestComment(id, commentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a seller organization request description
         * @summary Create a seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {SellerDescription} sellerDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequestDescription(id: number, sellerDescription: SellerDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequestDescription(id, sellerDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a seller organization request marketplace
         * @summary Create a seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {SellerMarketplace} sellerMarketplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSellerOrganizationRequestMarketplace(id: number, sellerMarketplace: SellerMarketplace, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSellerOrganizationRequestMarketplace(id, sellerMarketplace, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the seller organization request
         * @summary Delete the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the seller organization administrator
         * @summary Delete the seller organization administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequestAdministrator(id, logonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the seller organization request category
         * @summary Delete the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequestCategory(id: number, categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequestCategory(id, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * delete the seller organization request comment
         * @summary delete the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequestComment(id: number, commentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequestComment(id, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the seller organization request description
         * @summary Delete the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequestDescription(id: number, languageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequestDescription(id, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete the seller organization request marketplace
         * @summary Delete the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteSellerOrganizationRequestMarketplace(id: number, marketplaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteSellerOrganizationRequestMarketplace(id, marketplaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request administrator
         * @summary Get a seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerAdministrator>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestAdministrator(id, logonId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request administrator
         * @summary Get the seller organization request administrators
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;logonId will order the items based on the logonId value in ascending order. &lt;br/&gt;Available values for sort parameter: logonId, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestAdministrators(id: number, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerAdministratorCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestAdministrators(id, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request
         * @summary Get the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerOrganizationRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request categories
         * @summary Get the seller organization request categories
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;categoryId will order the items based on the categoryId value in ascending order. &lt;br/&gt;Available values for sort parameter: categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestCategories(id: number, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerCategoryCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestCategories(id, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request category
         * @summary Get the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestCategoryById(id: number, categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestCategoryById(id, categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request comment
         * @summary Get the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestCommentById(id: number, commentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestCommentById(id, commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request comments
         * @summary Get the seller organization request comments
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;commentedBy will order the items based on the commentedBy value in ascending order. &lt;br/&gt;Available values for sort parameter: commentedBy,commentedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestComments(id: number, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestComments(id, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request description
         * @summary Get the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestDescriptionById(id: number, languageId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerDescription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestDescriptionById(id, languageId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request descriptions
         * @summary Get the seller organization request descriptions
         * @param {number} id The seller organization registration request id
         * @param {string} [dataLanguageIds] The integer(s) for identifying the language of the seller organization request descriptions. For a list of the integers that map to the supported default languages, refer to the Knowledge Center. Multiple identifiers should be specified as a comma-separated list.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;languageId will order the items based on the languageId value in ascending order. &lt;br/&gt;Available values for sort parameter: languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestDescriptions(id: number, dataLanguageIds?: string, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerDescriptionCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestDescriptions(id, dataLanguageIds, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request marketplace
         * @summary Get the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestMarketplaceById(id: number, marketplaceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerMarketplace>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestMarketplaceById(id, marketplaceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request marketplaces
         * @summary Get the seller organization request marketplaces
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;storeIdentifier will order the items based on the storeIdentifier value in ascending order. &lt;br/&gt;Available values for sort parameter: storeIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequestMarketplaces(id: number, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerMarketplaceCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequestMarketplaces(id, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the seller organization request records.
         * @summary Get the seller organization request records
         * @param {number} [storeId] The unique numeric ID for identifying the store
         * @param {string} [searchString] Limits the results to those that match the specified search string. The search will look for matching seller organization names and submitter e-mail addresses.
         * @param {string} [organizationName] Filter the records by seller organization name
         * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status] Filter the records by Status
         * @param {string} [startDate] Filter the records by date range. Specify start date
         * @param {string} [endDate] Filter the records by date range. Specify end date
         * @param {string} [submitterEmail] Filter records by submitter\&#39;s email
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;sellerName will order the items based on the sellerName value in ascending order. &lt;br/&gt;Available values for sort parameter: sellerName, createdBy, createdTime, status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSellerOrganizationRequests(storeId?: number, searchString?: string, organizationName?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED', startDate?: string, endDate?: string, submitterEmail?: string, offset?: number, limit?: number, sort?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SellerOrganizationRequestCollection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSellerOrganizationRequests(storeId, searchString, organizationName, status, startDate, endDate, submitterEmail, offset, limit, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reject the seller organization request
         * @summary Reject the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectSellerOrganizationRequest(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectSellerOrganizationRequest(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the seller organization request
         * @summary Update the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {SellerOrganizationPatchRequest} sellerOrganizationPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerOrganizationRequest(id: number, sellerOrganizationPatchRequest: SellerOrganizationPatchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerOrganizationRequest(id, sellerOrganizationPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the seller organization request administrator
         * @summary Update the seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {SellerAdministratorPatchRequest} sellerAdministratorPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerOrganizationRequestAdministrator(id: number, logonId: string, sellerAdministratorPatchRequest: SellerAdministratorPatchRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerOrganizationRequestAdministrator(id, logonId, sellerAdministratorPatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the seller organization request comment
         * @summary Update the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {CommentBody} commentBody The seller organization request comments body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerOrganizationRequestComment(id: number, commentId: number, commentBody: CommentBody, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerOrganizationRequestComment(id, commentId, commentBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the seller organization request description
         * @summary Update the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {SellerDescription} sellerDescription The seller organization request description body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSellerOrganizationRequestDescription(id: number, languageId: number, sellerDescription: SellerDescription, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSellerOrganizationRequestDescription(id, languageId, sellerDescription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SellerOrganizationRequestsApi - factory interface
 * @export
 */
export const SellerOrganizationRequestsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SellerOrganizationRequestsApiFp(configuration)
    return {
        /**
         * Approve the seller organization request
         * @summary Approve the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveSellerOrganizationRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.approveSellerOrganizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the seller organization request
         * @summary Create the seller organization request
         * @param {SellerOrganizationRequestBody} sellerOrganizationRequestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequest(sellerOrganizationRequestBody: SellerOrganizationRequestBody, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequest(sellerOrganizationRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a seller organization request administrator.
         * @summary Create a seller organization request administrator.
         * @param {number} id The seller organization registration request id
         * @param {SellerAdministrator} sellerAdministrator 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestAdministrator(id: number, sellerAdministrator: SellerAdministrator, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequestAdministrator(id, sellerAdministrator, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a seller organization request category
         * @summary Create a seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {SellerCategory} sellerCategory 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestCategory(id: number, sellerCategory: SellerCategory, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequestCategory(id, sellerCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * Create the seller organization request comment
         * @summary Create the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {CommentBody} commentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestComment(id: number, commentBody: CommentBody, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequestComment(id, commentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a seller organization request description
         * @summary Create a seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {SellerDescription} sellerDescription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestDescription(id: number, sellerDescription: SellerDescription, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequestDescription(id, sellerDescription, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a seller organization request marketplace
         * @summary Create a seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {SellerMarketplace} sellerMarketplace 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSellerOrganizationRequestMarketplace(id: number, sellerMarketplace: SellerMarketplace, options?: any): AxiosPromise<void> {
            return localVarFp.createSellerOrganizationRequestMarketplace(id, sellerMarketplace, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the seller organization request
         * @summary Delete the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the seller organization administrator
         * @summary Delete the seller organization administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequestAdministrator(id, logonId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the seller organization request category
         * @summary Delete the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestCategory(id: number, categoryId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequestCategory(id, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * delete the seller organization request comment
         * @summary delete the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestComment(id: number, commentId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequestComment(id, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the seller organization request description
         * @summary Delete the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestDescription(id: number, languageId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequestDescription(id, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete the seller organization request marketplace
         * @summary Delete the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSellerOrganizationRequestMarketplace(id: number, marketplaceId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteSellerOrganizationRequestMarketplace(id, marketplaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request administrator
         * @summary Get a seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any): AxiosPromise<SellerAdministrator> {
            return localVarFp.getSellerOrganizationRequestAdministrator(id, logonId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request administrator
         * @summary Get the seller organization request administrators
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;logonId will order the items based on the logonId value in ascending order. &lt;br/&gt;Available values for sort parameter: logonId, email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestAdministrators(id: number, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<SellerAdministratorCollection> {
            return localVarFp.getSellerOrganizationRequestAdministrators(id, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request
         * @summary Get the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestById(id: number, options?: any): AxiosPromise<SellerOrganizationRequest> {
            return localVarFp.getSellerOrganizationRequestById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request categories
         * @summary Get the seller organization request categories
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;categoryId will order the items based on the categoryId value in ascending order. &lt;br/&gt;Available values for sort parameter: categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCategories(id: number, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<SellerCategoryCollection> {
            return localVarFp.getSellerOrganizationRequestCategories(id, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request category
         * @summary Get the seller organization request category
         * @param {number} id The seller organization registration request id
         * @param {string} categoryId The category ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCategoryById(id: number, categoryId: string, options?: any): AxiosPromise<SellerCategory> {
            return localVarFp.getSellerOrganizationRequestCategoryById(id, categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request comment
         * @summary Get the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestCommentById(id: number, commentId: number, options?: any): AxiosPromise<Comment> {
            return localVarFp.getSellerOrganizationRequestCommentById(id, commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request comments
         * @summary Get the seller organization request comments
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;commentedBy will order the items based on the commentedBy value in ascending order. &lt;br/&gt;Available values for sort parameter: commentedBy,commentedTime
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestComments(id: number, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<CommentCollection> {
            return localVarFp.getSellerOrganizationRequestComments(id, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request description
         * @summary Get the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestDescriptionById(id: number, languageId: number, options?: any): AxiosPromise<SellerDescription> {
            return localVarFp.getSellerOrganizationRequestDescriptionById(id, languageId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request descriptions
         * @summary Get the seller organization request descriptions
         * @param {number} id The seller organization registration request id
         * @param {string} [dataLanguageIds] The integer(s) for identifying the language of the seller organization request descriptions. For a list of the integers that map to the supported default languages, refer to the Knowledge Center. Multiple identifiers should be specified as a comma-separated list.
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;languageId will order the items based on the languageId value in ascending order. &lt;br/&gt;Available values for sort parameter: languageId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestDescriptions(id: number, dataLanguageIds?: string, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<SellerDescriptionCollection> {
            return localVarFp.getSellerOrganizationRequestDescriptions(id, dataLanguageIds, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request marketplace
         * @summary Get the seller organization request marketplace
         * @param {number} id The seller organization registration request id
         * @param {number} marketplaceId The marketplace store ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestMarketplaceById(id: number, marketplaceId: number, options?: any): AxiosPromise<SellerMarketplace> {
            return localVarFp.getSellerOrganizationRequestMarketplaceById(id, marketplaceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request marketplaces
         * @summary Get the seller organization request marketplaces
         * @param {number} id The seller organization registration request id
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;storeIdentifier will order the items based on the storeIdentifier value in ascending order. &lt;br/&gt;Available values for sort parameter: storeIdentifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequestMarketplaces(id: number, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<SellerMarketplaceCollection> {
            return localVarFp.getSellerOrganizationRequestMarketplaces(id, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the seller organization request records.
         * @summary Get the seller organization request records
         * @param {number} [storeId] The unique numeric ID for identifying the store
         * @param {string} [searchString] Limits the results to those that match the specified search string. The search will look for matching seller organization names and submitter e-mail addresses.
         * @param {string} [organizationName] Filter the records by seller organization name
         * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status] Filter the records by Status
         * @param {string} [startDate] Filter the records by date range. Specify start date
         * @param {string} [endDate] Filter the records by date range. Specify end date
         * @param {string} [submitterEmail] Filter records by submitter\&#39;s email
         * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
         * @param {number} [limit] The maximum number of records to return
         * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;sellerName will order the items based on the sellerName value in ascending order. &lt;br/&gt;Available values for sort parameter: sellerName, createdBy, createdTime, status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSellerOrganizationRequests(storeId?: number, searchString?: string, organizationName?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED', startDate?: string, endDate?: string, submitterEmail?: string, offset?: number, limit?: number, sort?: string, options?: any): AxiosPromise<SellerOrganizationRequestCollection> {
            return localVarFp.getSellerOrganizationRequests(storeId, searchString, organizationName, status, startDate, endDate, submitterEmail, offset, limit, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Reject the seller organization request
         * @summary Reject the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectSellerOrganizationRequest(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.rejectSellerOrganizationRequest(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the seller organization request
         * @summary Update the seller organization request
         * @param {number} id The seller organization registration request id
         * @param {SellerOrganizationPatchRequest} sellerOrganizationPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequest(id: number, sellerOrganizationPatchRequest: SellerOrganizationPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSellerOrganizationRequest(id, sellerOrganizationPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the seller organization request administrator
         * @summary Update the seller organization request administrator
         * @param {number} id The seller organization registration request id
         * @param {string} logonId Seller organization administrator logon id
         * @param {SellerAdministratorPatchRequest} sellerAdministratorPatchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestAdministrator(id: number, logonId: string, sellerAdministratorPatchRequest: SellerAdministratorPatchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSellerOrganizationRequestAdministrator(id, logonId, sellerAdministratorPatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the seller organization request comment
         * @summary Update the seller organization request comment
         * @param {number} id The seller organization registration request id
         * @param {number} commentId The unique identifier for identifying the comment
         * @param {CommentBody} commentBody The seller organization request comments body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestComment(id: number, commentId: number, commentBody: CommentBody, options?: any): AxiosPromise<void> {
            return localVarFp.updateSellerOrganizationRequestComment(id, commentId, commentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the seller organization request description
         * @summary Update the seller organization request description
         * @param {number} id The seller organization registration request id
         * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
         * @param {SellerDescription} sellerDescription The seller organization request description body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSellerOrganizationRequestDescription(id: number, languageId: number, sellerDescription: SellerDescription, options?: any): AxiosPromise<void> {
            return localVarFp.updateSellerOrganizationRequestDescription(id, languageId, sellerDescription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SellerOrganizationRequestsApi - object-oriented interface
 * @export
 * @class SellerOrganizationRequestsApi
 * @extends {BaseAPI}
 */
export class SellerOrganizationRequestsApi extends BaseAPI {
    /**
     * Approve the seller organization request
     * @summary Approve the seller organization request
     * @param {number} id The seller organization registration request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public approveSellerOrganizationRequest(id: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).approveSellerOrganizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the seller organization request
     * @summary Create the seller organization request
     * @param {SellerOrganizationRequestBody} sellerOrganizationRequestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequest(sellerOrganizationRequestBody: SellerOrganizationRequestBody, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequest(sellerOrganizationRequestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a seller organization request administrator.
     * @summary Create a seller organization request administrator.
     * @param {number} id The seller organization registration request id
     * @param {SellerAdministrator} sellerAdministrator 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequestAdministrator(id: number, sellerAdministrator: SellerAdministrator, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequestAdministrator(id, sellerAdministrator, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a seller organization request category
     * @summary Create a seller organization request category
     * @param {number} id The seller organization registration request id
     * @param {SellerCategory} sellerCategory 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequestCategory(id: number, sellerCategory: SellerCategory, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequestCategory(id, sellerCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create the seller organization request comment
     * @summary Create the seller organization request comment
     * @param {number} id The seller organization registration request id
     * @param {CommentBody} commentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequestComment(id: number, commentBody: CommentBody, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequestComment(id, commentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a seller organization request description
     * @summary Create a seller organization request description
     * @param {number} id The seller organization registration request id
     * @param {SellerDescription} sellerDescription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequestDescription(id: number, sellerDescription: SellerDescription, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequestDescription(id, sellerDescription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a seller organization request marketplace
     * @summary Create a seller organization request marketplace
     * @param {number} id The seller organization registration request id
     * @param {SellerMarketplace} sellerMarketplace 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public createSellerOrganizationRequestMarketplace(id: number, sellerMarketplace: SellerMarketplace, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).createSellerOrganizationRequestMarketplace(id, sellerMarketplace, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the seller organization request
     * @summary Delete the seller organization request
     * @param {number} id The seller organization registration request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequest(id: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the seller organization administrator
     * @summary Delete the seller organization administrator
     * @param {number} id The seller organization registration request id
     * @param {string} logonId Seller organization administrator logon id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequestAdministrator(id, logonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the seller organization request category
     * @summary Delete the seller organization request category
     * @param {number} id The seller organization registration request id
     * @param {string} categoryId The category ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequestCategory(id: number, categoryId: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequestCategory(id, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * delete the seller organization request comment
     * @summary delete the seller organization request comment
     * @param {number} id The seller organization registration request id
     * @param {number} commentId The unique identifier for identifying the comment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequestComment(id: number, commentId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequestComment(id, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the seller organization request description
     * @summary Delete the seller organization request description
     * @param {number} id The seller organization registration request id
     * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequestDescription(id: number, languageId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequestDescription(id, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete the seller organization request marketplace
     * @summary Delete the seller organization request marketplace
     * @param {number} id The seller organization registration request id
     * @param {number} marketplaceId The marketplace store ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public deleteSellerOrganizationRequestMarketplace(id: number, marketplaceId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).deleteSellerOrganizationRequestMarketplace(id, marketplaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request administrator
     * @summary Get a seller organization request administrator
     * @param {number} id The seller organization registration request id
     * @param {string} logonId Seller organization administrator logon id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestAdministrator(id: number, logonId: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestAdministrator(id, logonId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request administrator
     * @summary Get the seller organization request administrators
     * @param {number} id The seller organization registration request id
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;logonId will order the items based on the logonId value in ascending order. &lt;br/&gt;Available values for sort parameter: logonId, email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestAdministrators(id: number, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestAdministrators(id, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request
     * @summary Get the seller organization request
     * @param {number} id The seller organization registration request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestById(id: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request categories
     * @summary Get the seller organization request categories
     * @param {number} id The seller organization registration request id
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;categoryId will order the items based on the categoryId value in ascending order. &lt;br/&gt;Available values for sort parameter: categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestCategories(id: number, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestCategories(id, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request category
     * @summary Get the seller organization request category
     * @param {number} id The seller organization registration request id
     * @param {string} categoryId The category ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestCategoryById(id: number, categoryId: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestCategoryById(id, categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request comment
     * @summary Get the seller organization request comment
     * @param {number} id The seller organization registration request id
     * @param {number} commentId The unique identifier for identifying the comment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestCommentById(id: number, commentId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestCommentById(id, commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request comments
     * @summary Get the seller organization request comments
     * @param {number} id The seller organization registration request id
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;commentedBy will order the items based on the commentedBy value in ascending order. &lt;br/&gt;Available values for sort parameter: commentedBy,commentedTime
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestComments(id: number, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestComments(id, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request description
     * @summary Get the seller organization request description
     * @param {number} id The seller organization registration request id
     * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestDescriptionById(id: number, languageId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestDescriptionById(id, languageId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request descriptions
     * @summary Get the seller organization request descriptions
     * @param {number} id The seller organization registration request id
     * @param {string} [dataLanguageIds] The integer(s) for identifying the language of the seller organization request descriptions. For a list of the integers that map to the supported default languages, refer to the Knowledge Center. Multiple identifiers should be specified as a comma-separated list.
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;languageId will order the items based on the languageId value in ascending order. &lt;br/&gt;Available values for sort parameter: languageId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestDescriptions(id: number, dataLanguageIds?: string, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestDescriptions(id, dataLanguageIds, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request marketplace
     * @summary Get the seller organization request marketplace
     * @param {number} id The seller organization registration request id
     * @param {number} marketplaceId The marketplace store ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestMarketplaceById(id: number, marketplaceId: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestMarketplaceById(id, marketplaceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request marketplaces
     * @summary Get the seller organization request marketplaces
     * @param {number} id The seller organization registration request id
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;storeIdentifier will order the items based on the storeIdentifier value in ascending order. &lt;br/&gt;Available values for sort parameter: storeIdentifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequestMarketplaces(id: number, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequestMarketplaces(id, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the seller organization request records.
     * @summary Get the seller organization request records
     * @param {number} [storeId] The unique numeric ID for identifying the store
     * @param {string} [searchString] Limits the results to those that match the specified search string. The search will look for matching seller organization names and submitter e-mail addresses.
     * @param {string} [organizationName] Filter the records by seller organization name
     * @param {'PENDING' | 'APPROVED' | 'REJECTED'} [status] Filter the records by Status
     * @param {string} [startDate] Filter the records by date range. Specify start date
     * @param {string} [endDate] Filter the records by date range. Specify end date
     * @param {string} [submitterEmail] Filter records by submitter\&#39;s email
     * @param {number} [offset] The position within the resulting dataset where the query begins returning item records. If the offset is \&quot;5\&quot;, the records that returned begin with the sixth record that matches the query parameters. If the offset is \&quot;0\&quot;, the records that are returned begin with the first record that matches the query parameters.
     * @param {number} [limit] The maximum number of records to return
     * @param {string} [sort] The comma-separated set of properties that control the order of the listed items. Properties can be prefixed by either (-) to sort in descending order, or (+) to sort in ascending order. By default, properties are sorted in ascending order. For example, sort&#x3D;sellerName will order the items based on the sellerName value in ascending order. &lt;br/&gt;Available values for sort parameter: sellerName, createdBy, createdTime, status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public getSellerOrganizationRequests(storeId?: number, searchString?: string, organizationName?: string, status?: 'PENDING' | 'APPROVED' | 'REJECTED', startDate?: string, endDate?: string, submitterEmail?: string, offset?: number, limit?: number, sort?: string, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).getSellerOrganizationRequests(storeId, searchString, organizationName, status, startDate, endDate, submitterEmail, offset, limit, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reject the seller organization request
     * @summary Reject the seller organization request
     * @param {number} id The seller organization registration request id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public rejectSellerOrganizationRequest(id: number, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).rejectSellerOrganizationRequest(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the seller organization request
     * @summary Update the seller organization request
     * @param {number} id The seller organization registration request id
     * @param {SellerOrganizationPatchRequest} sellerOrganizationPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public updateSellerOrganizationRequest(id: number, sellerOrganizationPatchRequest: SellerOrganizationPatchRequest, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).updateSellerOrganizationRequest(id, sellerOrganizationPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the seller organization request administrator
     * @summary Update the seller organization request administrator
     * @param {number} id The seller organization registration request id
     * @param {string} logonId Seller organization administrator logon id
     * @param {SellerAdministratorPatchRequest} sellerAdministratorPatchRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public updateSellerOrganizationRequestAdministrator(id: number, logonId: string, sellerAdministratorPatchRequest: SellerAdministratorPatchRequest, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).updateSellerOrganizationRequestAdministrator(id, logonId, sellerAdministratorPatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the seller organization request comment
     * @summary Update the seller organization request comment
     * @param {number} id The seller organization registration request id
     * @param {number} commentId The unique identifier for identifying the comment
     * @param {CommentBody} commentBody The seller organization request comments body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public updateSellerOrganizationRequestComment(id: number, commentId: number, commentBody: CommentBody, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).updateSellerOrganizationRequestComment(id, commentId, commentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the seller organization request description
     * @summary Update the seller organization request description
     * @param {number} id The seller organization registration request id
     * @param {number} languageId The integer for identifying the language of the seller organization request description. For a list of the integers that map to the supported default languages, refer to the Knowledge Center.
     * @param {SellerDescription} sellerDescription The seller organization request description body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SellerOrganizationRequestsApi
     */
    public updateSellerOrganizationRequestDescription(id: number, languageId: number, sellerDescription: SellerDescription, options?: any) {
        return SellerOrganizationRequestsApiFp(this.configuration).updateSellerOrganizationRequestDescription(id, languageId, sellerDescription, options).then((request) => request(this.axios, this.basePath));
    }
}
