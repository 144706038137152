/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { AnyAction, createReducer } from "@reduxjs/toolkit";
//Redux
import { LOGIN_SUCCESS_ACTION, LOGOUT_SUCCESS_ACTION } from "redux/actions/user";
import {
  ENTITLED_ORG_SUCCESS_ACTION,
  GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
  GET_ORGANIZATION_SHIPPING_ADDRESSES_SUCCESS_ACTION,
  ORG_SWITCH_SUCCESS_ACTION,
} from "../actions/organization";
import initStates from "./initStates";
import { OrganizationReducerState } from "./reducerStateInterface";
/**
 * Organization reducer
 */
const organizationReducer = createReducer(initStates.organization, (builder) => {
  builder.addCase(ENTITLED_ORG_SUCCESS_ACTION, (state: OrganizationReducerState | any, action: AnyAction) => {
    Object.assign(state, {
      ...action.payload,
      // Sort alphabetically
      entitledOrganizations:
        action.payload.entitledOrganizations?.length > 0
          ? action.payload.entitledOrganizations.sort((a, b) =>
              a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase())
            )
          : [],
    });
  });
  builder.addCase(
    GET_ORGANIZATION_ADDRESS_SUCCESS_ACTION,
    (state: OrganizationReducerState | any, action: AnyAction) => {
      state.organizationDetails = action.payload;
    }
  );
  builder.addCase(
    GET_ORGANIZATION_SHIPPING_ADDRESSES_SUCCESS_ACTION,
    (state: OrganizationReducerState | any, action: AnyAction) => {
      state.shippingAddresses = action.payload.contact;
    }
  );
  // SQSTRCOM-748: clear org addresses when user logs out
  builder.addCase(LOGOUT_SUCCESS_ACTION, (state: OrganizationReducerState | any) => {
    state.shippingAddresses = null;
  });
  // SQSTRCOM-844: clear org addresses when switching organization (and when logging in)
  builder.addCase(ORG_SWITCH_SUCCESS_ACTION, (state: OrganizationReducerState | any) => {
    state.shippingAddresses = null;
  });
  builder.addCase(LOGIN_SUCCESS_ACTION, (state: OrganizationReducerState | any) => {
    state.shippingAddresses = null;
  });
});
export default organizationReducer;
