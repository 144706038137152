import axios, { AxiosPromise } from "axios";
import { site } from "_foundation/constants/site";
import { getSite } from "_foundation/hooks/useSite";

export const STRStore = {
  getContactInfo() {
    return getOnlineStoreData().then((r) => {
      return r.data.resultList[0].contactInfo[0];
    });
  },
  getLocationInfo() {
    return getOnlineStoreData().then((r) => {
      return r.data.resultList[0].locationInfo[0];
    });
  },
  getStoreConf(name) {
    return getOnlineStoreData(PROFILE.IBM_Store_Conf).then((r) => {
      return r.data.resultList[0].userData[name];
    });
  },
};

const PROFILE = {
  IBM_All: "IBM_All",
  IBM_Details: "IBM_Details",
  IBM_Summary: "IBM_Summary",
  IBM_Admin_Summary: "IBM_Admin_Summary",
  IBM_Admin_Details: "IBM_Admin_Details",
  IBM_Admin_All: "IBM_Admin_All",
  IBM_Store_All: "IBM_Store_All",
  IBM_Store_Conf: "IBM_Store_Conf",
};

const getOnlineStoreData = (profileName = PROFILE.IBM_Store_All): AxiosPromise<any> => {
  const storeId = getSite()?.storeID;
  return axios.get(`${site.transactionContext}/store/${storeId}/online_store?profileName=${profileName}`);
};
