/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React from "react";
//UI
import {
  StyledBox,
  StyledGrid,
  StyledLink,
  StyledMenuTypography,
  StyledPaper,
} from "@hcl-commerce-store-sdk/react-component";
import { SUB_CATEGORIES_LIMIT } from "configs/catalog";
import { SolteqShowMoreCategoriesLink } from "solteq/src/components/navigation/SolteqShowMoreCategoriesLink";

interface AllCategoriesExpandedMenuProps {
  pages?: any;
}

/**
 * AllCategoriesExpandedMenu component
 * displays all categories in the expanded menu on desktop/tablet
 * @param props
 */
const AllCategoriesExpandedMenu: React.FC<AllCategoriesExpandedMenuProps> = (props: any) => {
  const pages = props.pages ? props.pages : [];

  return (
    <>
      <StyledPaper className="expanded-menu-paper">
        <StyledBox m={3} mb={5}>
          <StyledGrid container direction="row" justifyContent="flex-start" alignContent="flex-start" spacing={1}>
            {pages &&
              pages.map((page: any, index: number) => (
                <StyledGrid key={page.id} item xs={2}>
                  <StyledBox mr={2} mb={4}>
                    <StyledLink
                      testId={`header-${page.id}`}
                      key={"Link_" + index}
                      to={page.seo?.href}
                      state={{
                        breadCrumbTrailEntryView: [{ label: page.name, value: page.id, seo: page.seo }],
                      }}>
                      <StyledMenuTypography variant="body1" className="expanded-menu-bold">
                        {page.name}
                      </StyledMenuTypography>
                    </StyledLink>
                    <ul>
                      {page.children &&
                        page.children.slice(0, SUB_CATEGORIES_LIMIT).map((page2: any, i: number) => (
                          <StyledLink
                            testId={`header-${page2.id}`}
                            to={page2.seo?.href}
                            key={page2.id}
                            state={{
                              breadCrumbTrailEntryView: [
                                {
                                  label: page.name,
                                  value: page.id,
                                  seo: page.seo,
                                },
                                {
                                  label: page2.name,
                                  value: page2.id,
                                  seo: page2.seo,
                                },
                              ],
                            }}>
                            <StyledMenuTypography variant="body2" className="expanded-menu-sub-links">
                              {page2.name}
                            </StyledMenuTypography>
                          </StyledLink>
                        ))}
                      <SolteqShowMoreCategoriesLink page={page} />
                    </ul>
                  </StyledBox>
                </StyledGrid>
              ))}
          </StyledGrid>
        </StyledBox>
      </StyledPaper>
    </>
  );
};

export default AllCategoriesExpandedMenu;
