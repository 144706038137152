import {
  CreateOrganizationAddressActionType,
  DeleteOrganizationAddressActionType,
  GetOrganizationShippingAddressesActionType,
  UpdateOrganizationAddressActionType,
} from "./../types/organization/organization.types";
import axios, { AxiosPromise } from "axios";
import { site } from "_foundation/constants/site";
import { getSite } from "_foundation/hooks/useSite";
import { PayloadBaseType } from "../types/common.types";

/** Service for organization contacts. */
export const SolteqOrganizationContactService = {
  /** Get organization addresses.
   * 
   * Response structure:
   * {
      "organizationId": "7333333333333345835",
      "resourceId": "https://localhost:443/wcs/resources/store/20101/organization/7333333333333345835/contact",
      "contact": [
        {
            "zipCode": "40100",
            "country": "FI",
            "email1": "FI1716336-7@test.com",
            "city": "Jyuvaskyla",
            "addressType": "ShippingAndBilling",
            "nickName": "EOne Oy 123",
            "state": "-",
            "addressLine": [
                "Vapaudenkatu 11",
                "",
                ""
            ],
            "addressId": "2932031037004",
            "primary": "false",
            "phone1": "48123456789"
        }
     ],
    "resourceName": "organization",
    "addressId": "2932031029504"
   }
   */
  getOrganizationAddresses(params: GetOrganizationShippingAddressesActionType): AxiosPromise<any> {
    const storeId = getSite()?.storeID;
    return axios.get(`${site.transactionContext}/store/${storeId}/organization/${params.organizationId}/contact`, {
      ...params.payloadBase,
    });
  },

  /**
   * Gets details of address by addressId.
   * 
   * Response structure:
   * {
     "country": "FI",
     "zipCode": "40100",
     "resourceId": "https://localhost:443/wcs/resources/store/20101/organization/7333333333333345835/contact/byAddressId/2932031037004",
     "city": "Jyuvaskyla",
     "nickName": "EOne Oy 123",
     "addressType": "ShippingAndBilling",
     "resourceName": "organization",
     "addressLine": [
        "Vapaudenkatu 11",
        "",
        ""
     ],
     "addressId": "2932031037004",
     "phone1": "48123456789",
     "email1": "FI1716336-7@test.com",
     "state": "-",
     "primary": "false"
    }
   */
  getAddressDetailsByAddressId(addressId: string, organizationId: string, payload: PayloadBaseType): AxiosPromise<any> {
    const storeId = getSite()?.storeID;
    return axios.get(
      `${site.transactionContext}/store/${storeId}/organization/${organizationId}/contact/byAddressId/${addressId}`,
      { ...payload }
    );
  },

  /** Creates a new address for the organization.
   *
   * https://help.hcltechsw.com/commerce/9.1.0/restapi/code91/addresses.html
   *
   * Payload fields can be used similar to above.
   */
  addOrganizationAddress(params: CreateOrganizationAddressActionType): AxiosPromise<any> {
    const storeId = getSite()?.storeID;
    return axios.post(
      `${site.transactionContext}/store/${storeId}/organization/${params.organizationId}/contact`,
      { ...params.payloadBody },
      { ...params.payloadBase }
    );
  },

  /** Updates the address with nickname
   *
   * https://help.hcltechsw.com/commerce/9.1.0/restapi/code91/addresses.html
   *
   * Payload fields can be used similar to above.
   */
  updateOrganizationAddress(params: UpdateOrganizationAddressActionType) {
    const storeId = getSite()?.storeID;
    return axios.put(
      `${site.transactionContext}/store/${storeId}/organization/${params.organizationId}/contact/${params.nickName}`,
      { ...params.payloadBody },
      { ...params.payloadBase }
    );
  },

  /** Deletes the address with nickname */
  deleteOrganizationAddress(params: DeleteOrganizationAddressActionType) {
    const storeId = getSite()?.storeID;
    return axios.delete(
      `${site.transactionContext}/store/${storeId}/organization/${params.organizationId}/contact/${params.nickName}`,
      { ...params.payloadBase }
    );
  },
};
