import { UpdateItemPriceActionType } from "./../types/order/order.types";
import axios, { AxiosPromise, AxiosResponse } from "axios";
import { site } from "_foundation/constants/site";
import { getSite } from "_foundation/hooks/useSite";

const QUERYNAME = "byCatalogEntryIdsAndPriceRuleName";
const STSELLERPRICE = "STSellerPriceRule";

export interface PurchasePriceResult {
  unitPrice: [
    {
      quantity: {
        uom: string;
        value: number;
      };
      price: {
        currency: string;
        value: number;
      };
    }
  ];
  catalogEntryId: string;
  partNumber: string;
  priceRuleName: string;
  priceRuleId: string;
}

/** See: wcs/resources/api/resource/display_price */
export interface GetDisplayPriceResult {
  resourceId: string;
  resourceName: string;
  resultList: PurchasePriceResult[];
}

export const STRPricesService = {
  getPurchasePriceForProducts(catentryIds: string[], params?: any): Promise<AxiosResponse<GetDisplayPriceResult>> {
    const storeId = getSite()?.storeID;

    const catentryParams = catentryIds.map((id) => `catalogEntryId=${id}`).join("&");
    return axios.get(
      `${site.transactionContext}/store/${storeId}/display_price?q=${QUERYNAME}&priceRuleName=${STSELLERPRICE}&${catentryParams}`,
      {
        ...params,
      }
    );
  },
  updateItemsPrices(param: UpdateItemPriceActionType): AxiosPromise<any> {
    const storeId = getSite()?.storeID;
    return axios.post(
      `${site.transactionContext}/store/${storeId}/csrprice`,
      { ...param.payloadBodyBase },
      { ...param.payloadBase }
    );
  },
};
