import { SelectionDialogProps } from "../components/common/SelectionDialog";
import React, { useMemo, useState } from "react";

interface ISelectionDialogContext {
  props: SelectionDialogProps | null;
  onPropsChange: (props: SelectionDialogProps | null) => void;
}

/** A selection dialog context - no need to wrap
 * SelectionDialog anywhere else, just use this
 */
export const SelectionDialogContext = React.createContext<ISelectionDialogContext>({
  props: null,
  onPropsChange: () => null,
});

export const SelectionDialogContextProvider = ({ children }: { children: React.ReactFragment }) => {
  const [props, setProps] = useState<SelectionDialogProps | null>(null);

  const onPropsChange = (p: SelectionDialogProps | null) => {
    setProps(p);
  };

  const contextValue: ISelectionDialogContext = useMemo(
    () => ({
      props,
      onPropsChange,
    }),
    [props]
  );

  return <SelectionDialogContext.Provider value={contextValue}>{children}</SelectionDialogContext.Provider>;
};
