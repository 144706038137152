/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { createAction } from "@reduxjs/toolkit";
//Redux
import * as ACTIONTYPES from "../action-types/search";

const KEYWORDS_UPDATED_ACTION = createAction<any, string>(ACTIONTYPES.KEYWORDS_UPDATED);

const KEYWORDS_RESET_ACTION = createAction<any, string>(ACTIONTYPES.KEYWORDS_RESET);

const HISTORY_UPDATED_ACTION = createAction<any, string>(ACTIONTYPES.HISTORY_UPDATED);

const HISTORY_RESET_ACTION = createAction<any, string>(ACTIONTYPES.HISTORY_RESET);

export { KEYWORDS_UPDATED_ACTION, KEYWORDS_RESET_ACTION, HISTORY_UPDATED_ACTION, HISTORY_RESET_ACTION };
