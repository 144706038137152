//Standard libraries
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
// UI
import {
  StyledButton,
  StyledDialog,
  StyledDialogActions,
  StyledDialogContent,
  StyledIconButton,
  StyledTypography,
} from "@hcl-commerce-store-sdk/react-component";
import DialogTitle from "@mui/material/DialogTitle";
import { ClassNameMap, createStyles, Theme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import withStyles from "@mui/styles/withStyles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface SelectionDialogProps {
  render: (value, setValue: React.Dispatch<React.SetStateAction<any>>) => JSX.Element;
  onClose: (value?) => void;
  title: string;
  subtitle?: string;
  defaultValue?: any;
  maxWidth?: "lg" | "md" | "sm" | "xl" | "xs" | false;
  acceptDisabledCondition?: (value) => boolean;
}

interface DialogTitleProps {
  onClose: (value?: any) => void;
  children: React.ReactNode;
  classes: ClassNameMap;
}

/** A custom dialog title that allows closing by clicking a close icon */
const CustomDialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle className={classes.root} {...other}>
      {children}
      {onClose ? (
        <StyledIconButton
          id="dialogCloseIcon"
          data-testid="dialogCloseIcon"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      ) : null}
    </DialogTitle>
  );
});

const CustomDialogActions = withStyles(() => ({
  root: {
    marginLeft: "auto",
    padding: "1em!important",
  },
}))(StyledDialogActions);

/**
 * A selection dialog that takes any type of selector with value. Returns the selected value on confirm.
 * @param props
 * @returns
 */
function SelectionDialog(props: Partial<SelectionDialogProps>) {
  const { onClose, title, subtitle, render, defaultValue, maxWidth, acceptDisabledCondition } = props;
  const { t } = useTranslation();
  const [value, setValue] = React.useState<any>(defaultValue);
  const acceptDisabled = acceptDisabledCondition
    ? acceptDisabledCondition(value)
    : value === null || value === undefined;

  const handleCancel = () => {
    if (onClose) {
      onClose();
    }
    setValue(defaultValue);
  };

  const handleOk = () => {
    if (onClose) {
      onClose(value);
    }
  };

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <>
      <StyledDialog
        fullWidth
        maxWidth={maxWidth ?? "sm"}
        disableBackdropClick
        disableEnforceFocus
        disablePortal
        transitionDuration={{ appear: 100, enter: 100, exit: 30 }}
        open={!!title}
        onClose={handleCancel}>
        <CustomDialogTitle onClose={handleCancel}>
          <>
            <StyledTypography variant="h5">{title}</StyledTypography>
            {subtitle && <StyledTypography variant="subtitle1">{subtitle}</StyledTypography>}
          </>
        </CustomDialogTitle>
        <StyledDialogContent>{render ? render(value, setValue) : ""}</StyledDialogContent>

        <Divider />
        <CustomDialogActions>
          <StyledButton color="secondary" id="cancelButton" onClick={handleCancel}>
            {t("Confirmation.CancelButton")}
          </StyledButton>
          <StyledButton color="primary" id="okButton" disabled={acceptDisabled} onClick={handleOk}>
            {t("Confirmation.SubmitButton")}
          </StyledButton>
        </CustomDialogActions>
      </StyledDialog>
    </>
  );
}

export default SelectionDialog;
