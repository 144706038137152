/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 * @interface Seller
 */
export interface Seller {
    /**
     * The unique numeric ID for identifying the organization.
     * @type {string}
     * @memberof Seller
     */
    id?: string;
    /**
     * The display name for specified language.
     * @type {string}
     * @memberof Seller
     */
    name?: string;
    /**
     * The short description for specified language.
     * @type {string}
     * @memberof Seller
     */
    description?: string;
    /**
     * Status of the marketplace seller organization. Available values: <br> * 1 - The organization is in Active state.<br> * 2 - The organization is in Deactivated state.<br> * 3 - The organization is in Suspended state.<br> * 4 - The organization is in Terminated state.
     * @type {number}
     * @memberof Seller
     */
    status?: SellerStatusEnum;
    /**
     * Online Status of the marketplace seller organization. Available values: <br> * 1 - The organization is in Online state.<br> * 0 - The organization is in Offline state.
     * @type {number}
     * @memberof Seller
     */
    onlineStatus?: SellerOnlineStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum SellerStatusEnum {
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4
}
/**
    * @export
    * @enum {string}
    */
export enum SellerOnlineStatusEnum {
    NUMBER_0 = 0,
    NUMBER_1 = 1
}



