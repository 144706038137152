/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceApprovalBeansApprovalStatusDataBeanIBMStoreSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceApprovalBeansOrderApprovalStatusListDataBeanIBMStoreSummaryResultList } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ApprovalStatusApi - axios parameter creator
 * @export
 */
export const ApprovalStatusApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets a list of approval records details that the current authenticated user can approve.
         * @summary Finds all approval status records available to the current user.
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitTime] Query by approval request start time.
         * @param {string} [endSubmitTime] Query by approval request end time.
         * @param {number} [status] Query by approval request status. 0 for pending, 1 for approved, 2 for rejected.
         * @param {number} [approvalStatusId] Query by approval request approval status id.
         * @param {number} [entityId] Query by approval request entity id, such as order id.
         * @param {number} [approverId] Query by approver id. Only returns approval requests that can be approved by the current user.
         * @param {string} [orderBy] Order by.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&#39;pageSize\&#39; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&#39;pageNumber\&#39; must be specified for paging to work.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalStatusFindByQuery: async (storeId: string, q: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitTime?: string, endSubmitTime?: string, status?: number, approvalStatusId?: number, entityId?: number, approverId?: number, orderBy?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('approvalStatusFindByQuery', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('approvalStatusFindByQuery', 'q', q)
            const localVarPath = `/store/{storeId}/approval_status`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (submitterFirstName !== undefined) {
                localVarQueryParameter['submitterFirstName'] = submitterFirstName;
            }

            if (submitterMiddleName !== undefined) {
                localVarQueryParameter['submitterMiddleName'] = submitterMiddleName;
            }

            if (submitterLastName !== undefined) {
                localVarQueryParameter['submitterLastName'] = submitterLastName;
            }

            if (startSubmitTime !== undefined) {
                localVarQueryParameter['startSubmitTime'] = (startSubmitTime as any instanceof Date) ?
                    (startSubmitTime as any).toISOString().substr(0,10) :
                    startSubmitTime;
            }

            if (endSubmitTime !== undefined) {
                localVarQueryParameter['endSubmitTime'] = (endSubmitTime as any instanceof Date) ?
                    (endSubmitTime as any).toISOString().substr(0,10) :
                    endSubmitTime;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (approvalStatusId !== undefined) {
                localVarQueryParameter['approvalStatusId'] = approvalStatusId;
            }

            if (entityId !== undefined) {
                localVarQueryParameter['entityId'] = entityId;
            }

            if (approverId !== undefined) {
                localVarQueryParameter['approverId'] = approverId;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve or reject an approval record.
         * @summary Approve or reject an approval record.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription} body Request body.
         * @param {string} [action] The action name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalStatusUpdateApprovalStatus: async (storeId: string, approvalStatusId: string, body: ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription, action?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('approvalStatusUpdateApprovalStatus', 'storeId', storeId)
            // verify required parameter 'approvalStatusId' is not null or undefined
            assertParamExists('approvalStatusUpdateApprovalStatus', 'approvalStatusId', approvalStatusId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('approvalStatusUpdateApprovalStatus', 'body', body)
            const localVarPath = `/store/{storeId}/approval_status/{approvalStatusId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"approvalStatusId"}}`, String(approvalStatusId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Find an approval status record by its id.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdApprovalStatusApprovalStatusIdGet: async (storeId: string, approvalStatusId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdApprovalStatusApprovalStatusIdGet', 'storeId', storeId)
            // verify required parameter 'approvalStatusId' is not null or undefined
            assertParamExists('storeStoreIdApprovalStatusApprovalStatusIdGet', 'approvalStatusId', approvalStatusId)
            const localVarPath = `/store/{storeId}/approval_status/{approvalStatusId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"approvalStatusId"}}`, String(approvalStatusId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApprovalStatusApi - functional programming interface
 * @export
 */
export const ApprovalStatusApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApprovalStatusApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets a list of approval records details that the current authenticated user can approve.
         * @summary Finds all approval status records available to the current user.
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitTime] Query by approval request start time.
         * @param {string} [endSubmitTime] Query by approval request end time.
         * @param {number} [status] Query by approval request status. 0 for pending, 1 for approved, 2 for rejected.
         * @param {number} [approvalStatusId] Query by approval request approval status id.
         * @param {number} [entityId] Query by approval request entity id, such as order id.
         * @param {number} [approverId] Query by approver id. Only returns approval requests that can be approved by the current user.
         * @param {string} [orderBy] Order by.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&#39;pageSize\&#39; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&#39;pageNumber\&#39; must be specified for paging to work.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvalStatusFindByQuery(storeId: string, q: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitTime?: string, endSubmitTime?: string, status?: number, approvalStatusId?: number, entityId?: number, approverId?: number, orderBy?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceApprovalBeansOrderApprovalStatusListDataBeanIBMStoreSummaryResultList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvalStatusFindByQuery(storeId, q, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitTime, endSubmitTime, status, approvalStatusId, entityId, approverId, orderBy, pageNumber, pageSize, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Approve or reject an approval record.
         * @summary Approve or reject an approval record.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription} body Request body.
         * @param {string} [action] The action name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approvalStatusUpdateApprovalStatus(storeId: string, approvalStatusId: string, body: ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription, action?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approvalStatusUpdateApprovalStatus(storeId, approvalStatusId, body, action, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Find an approval status record by its id.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdApprovalStatusApprovalStatusIdGet(storeId: string, approvalStatusId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceApprovalBeansApprovalStatusDataBeanIBMStoreSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdApprovalStatusApprovalStatusIdGet(storeId, approvalStatusId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApprovalStatusApi - factory interface
 * @export
 */
export const ApprovalStatusApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApprovalStatusApiFp(configuration)
    return {
        /**
         * Gets a list of approval records details that the current authenticated user can approve.
         * @summary Finds all approval status records available to the current user.
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
         * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
         * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
         * @param {string} [startSubmitTime] Query by approval request start time.
         * @param {string} [endSubmitTime] Query by approval request end time.
         * @param {number} [status] Query by approval request status. 0 for pending, 1 for approved, 2 for rejected.
         * @param {number} [approvalStatusId] Query by approval request approval status id.
         * @param {number} [entityId] Query by approval request entity id, such as order id.
         * @param {number} [approverId] Query by approver id. Only returns approval requests that can be approved by the current user.
         * @param {string} [orderBy] Order by.
         * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&#39;pageSize\&#39; must be specified for paging to work.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&#39;pageNumber\&#39; must be specified for paging to work.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalStatusFindByQuery(storeId: string, q: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitTime?: string, endSubmitTime?: string, status?: number, approvalStatusId?: number, entityId?: number, approverId?: number, orderBy?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceApprovalBeansOrderApprovalStatusListDataBeanIBMStoreSummaryResultList> {
            return localVarFp.approvalStatusFindByQuery(storeId, q, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitTime, endSubmitTime, status, approvalStatusId, entityId, approverId, orderBy, pageNumber, pageSize, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Approve or reject an approval record.
         * @summary Approve or reject an approval record.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription} body Request body.
         * @param {string} [action] The action name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approvalStatusUpdateApprovalStatus(storeId: string, approvalStatusId: string, body: ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription, action?: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusResponse> {
            return localVarFp.approvalStatusUpdateApprovalStatus(storeId, approvalStatusId, body, action, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Find an approval status record by its id.
         * @param {string} storeId The store identifier.
         * @param {string} approvalStatusId The approval status ID.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdApprovalStatusApprovalStatusIdGet(storeId: string, approvalStatusId: string, options?: any): AxiosPromise<ComIbmCommerceApprovalBeansApprovalStatusDataBeanIBMStoreSummary> {
            return localVarFp.storeStoreIdApprovalStatusApprovalStatusIdGet(storeId, approvalStatusId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApprovalStatusApi - object-oriented interface
 * @export
 * @class ApprovalStatusApi
 * @extends {BaseAPI}
 */
export class ApprovalStatusApi extends BaseAPI {
    /**
     * Gets a list of approval records details that the current authenticated user can approve.
     * @summary Finds all approval status records available to the current user.
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} [submitterFirstName] Query by approval request submitter\&#39;s first name.
     * @param {string} [submitterMiddleName] Query by approval request submitter\&#39;s middle name.
     * @param {string} [submitterLastName] Query by approval request submitter\&#39;s last name.
     * @param {string} [startSubmitTime] Query by approval request start time.
     * @param {string} [endSubmitTime] Query by approval request end time.
     * @param {number} [status] Query by approval request status. 0 for pending, 1 for approved, 2 for rejected.
     * @param {number} [approvalStatusId] Query by approval request approval status id.
     * @param {number} [entityId] Query by approval request entity id, such as order id.
     * @param {number} [approverId] Query by approver id. Only returns approval requests that can be approved by the current user.
     * @param {string} [orderBy] Order by.
     * @param {number} [pageNumber] Page number, starting at 1. Valid values include positive integers of 1 and above. The \&#39;pageSize\&#39; must be specified for paging to work.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values include positive integers of 1 and above. The \&#39;pageNumber\&#39; must be specified for paging to work.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public approvalStatusFindByQuery(storeId: string, q: string, submitterFirstName?: string, submitterMiddleName?: string, submitterLastName?: string, startSubmitTime?: string, endSubmitTime?: string, status?: number, approvalStatusId?: number, entityId?: number, approverId?: number, orderBy?: string, pageNumber?: number, pageSize?: number, responseFormat?: string, options?: any) {
        return ApprovalStatusApiFp(this.configuration).approvalStatusFindByQuery(storeId, q, submitterFirstName, submitterMiddleName, submitterLastName, startSubmitTime, endSubmitTime, status, approvalStatusId, entityId, approverId, orderBy, pageNumber, pageSize, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Approve or reject an approval record.
     * @summary Approve or reject an approval record.
     * @param {string} storeId The store identifier.
     * @param {string} approvalStatusId The approval status ID.
     * @param {ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription} body Request body.
     * @param {string} [action] The action name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public approvalStatusUpdateApprovalStatus(storeId: string, approvalStatusId: string, body: ComIbmCommerceRestApprovalstatusHandlerApprovalStatusHandlerUpdateApprovalStatusParameterDescription, action?: string, responseFormat?: string, options?: any) {
        return ApprovalStatusApiFp(this.configuration).approvalStatusUpdateApprovalStatus(storeId, approvalStatusId, body, action, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Find an approval status record by its id.
     * @param {string} storeId The store identifier.
     * @param {string} approvalStatusId The approval status ID.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApprovalStatusApi
     */
    public storeStoreIdApprovalStatusApprovalStatusIdGet(storeId: string, approvalStatusId: string, options?: any) {
        return ApprovalStatusApiFp(this.configuration).storeStoreIdApprovalStatusApprovalStatusIdGet(storeId, approvalStatusId, options).then((request) => request(this.axios, this.basePath));
    }
}
