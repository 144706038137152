/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { BuyerContractAddParticipantRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractAddParticipantResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractCancelResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractContractList } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractCopyRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractCopyResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractCreateResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDeleteParticipantRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDeleteParticipantResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDeleteResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDeleteTermConditionRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDeleteTermConditionResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractDetails } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractNewVersionRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractNewVersionResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractResumeResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractSaveTermConditionRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractSaveTermConditionResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractSubmitResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractSuspendResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { BuyerContractUpdateResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * BuyerContractApi - axios parameter creator
 * @export
 */
export const BuyerContractApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a participant to a buyer contract.
         * @summary Adds a participant to a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractAddParticipantRequest} buyerContractAddParticipantRequest The request body for adding a participant to a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractAddParticipant: async (storeId: string, contractId: string, buyerContractAddParticipantRequest: BuyerContractAddParticipantRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractAddParticipant', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractAddParticipant', 'contractId', contractId)
            // verify required parameter 'buyerContractAddParticipantRequest' is not null or undefined
            assertParamExists('buyerContractAddParticipant', 'buyerContractAddParticipantRequest', buyerContractAddParticipantRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/addParticipant`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractAddParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cancels the specified buyer contract by changing its contract state to Cancelled.<br>It does not remove the contract from the database. A contract can be cancelled any time except the contract is in Draft, DeploymentInProgress, or Closed state.
         * @summary Cancels the specified buyer contract by changing its contract state to Cancelled
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be cancelled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractCancel: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractCancel', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractCancel', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/cancel`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Copies a buyer contract. <br> If the command completes successfully, a new contract will be create. The contract will be in Draft state with majorVersion set to 1 and minorVersion set to 0.
         * @summary Copies a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractCopyRequest} buyerContractCopyRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractCopy: async (storeId: string, contractId: string, buyerContractCopyRequest: BuyerContractCopyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractCopy', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractCopy', 'contractId', contractId)
            // verify required parameter 'buyerContractCopyRequest' is not null or undefined
            assertParamExists('buyerContractCopy', 'buyerContractCopyRequest', buyerContractCopyRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/copy`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractCopyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the specified contracts by setting the contracts to mark for delete.<br>It does not remove the contract from the database immediately. A contract  has to be in one of the following state to run this command:  Draft, Cancelled, Closed.
         * @summary Deletes the specified contracts by setting the contracts to mark for delete
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteBuyerContract: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractDeleteBuyerContract', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractDeleteBuyerContract', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a participant relationship from a buyer contract.
         * @summary Deletes a participant relationship.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteParticipantRequest} buyerContractDeleteParticipantRequest The request body for deleting participant from a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteParticipant: async (storeId: string, contractId: string, buyerContractDeleteParticipantRequest: BuyerContractDeleteParticipantRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractDeleteParticipant', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractDeleteParticipant', 'contractId', contractId)
            // verify required parameter 'buyerContractDeleteParticipantRequest' is not null or undefined
            assertParamExists('buyerContractDeleteParticipant', 'buyerContractDeleteParticipantRequest', buyerContractDeleteParticipantRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/deleteParticipant`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractDeleteParticipantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes terms and conditions from a buyer contract. The contract has to be in Draft state.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteTermConditionRequest} buyerContractDeleteTermConditionRequest The request body for adding or deleting terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteTermCondition: async (storeId: string, contractId: string, buyerContractDeleteTermConditionRequest: BuyerContractDeleteTermConditionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractDeleteTermCondition', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractDeleteTermCondition', 'contractId', contractId)
            // verify required parameter 'buyerContractDeleteTermConditionRequest' is not null or undefined
            assertParamExists('buyerContractDeleteTermCondition', 'buyerContractDeleteTermConditionRequest', buyerContractDeleteTermConditionRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/deleteTermCondition`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractDeleteTermConditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"Creates a new version of a specified buyer contract. <br>The state of the specified contract has to be in one of the following states: Approved, Active, Suspended, DeploymentInProgress, and DeploymentFailed. <br>The new contract will have the same name as the specified one. <br>If both majorVersion and minorVersion are provided, the command respects the versions. Otherwise, the new contract will have the same major version number with the old contract. The minor version will be one plus the largest minor version with the same contract name,  origin, owner, majorVerion. <br>This new contract will be in Draft version.\"
         * @summary Creates a new version of a specified buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractNewVersionRequest} buyerContractNewVersionRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractNewVersion: async (storeId: string, contractId: string, buyerContractNewVersionRequest: BuyerContractNewVersionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractNewVersion', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractNewVersion', 'contractId', contractId)
            // verify required parameter 'buyerContractNewVersionRequest' is not null or undefined
            assertParamExists('buyerContractNewVersion', 'buyerContractNewVersionRequest', buyerContractNewVersionRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/newVersion`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractNewVersionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resumes the specified buyer contract to Active state. <br>The specified contract has to be in Suspended state.
         * @summary Resumes the specified buyer contract to Active state
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be resumed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractResume: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractResume', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractResume', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/resume`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Adds or updates terms and conditions for a buyer contract.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractSaveTermConditionRequest} buyerContractSaveTermConditionRequest The request body for adding or updating terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSaveTermCondition: async (storeId: string, contractId: string, buyerContractSaveTermConditionRequest: BuyerContractSaveTermConditionRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractSaveTermCondition', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractSaveTermCondition', 'contractId', contractId)
            // verify required parameter 'buyerContractSaveTermConditionRequest' is not null or undefined
            assertParamExists('buyerContractSaveTermCondition', 'buyerContractSaveTermConditionRequest', buyerContractSaveTermConditionRequest)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/saveTermCondition`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractSaveTermConditionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches contract in a business account. <br> It accepts \'name\' and \'shortDescription\' query parameters to search contract with name and short descriptions matching the query parameters (case-insensitive). <br>\'nameSearchMode\' and \'descriptionSearchMode\' query parameters are used to specify search behavior. <br>\'state\' query parameters are used for specifying contracts with which state should be returned. <br>If more than one query parameter are specified, only contracts which satisfies all specified query parameters are returned. <br>If no query parameter is specified, all contracts in this account are returned.
         * @summary Searches contracts in a business account.
         * @param {string} storeId The store identifier.
         * @param {string} accountId The ID of the business account which contains the contract.
         * @param {string} [name] The text used for searching contracts by name.
         * @param {string} [nameSearchMode] Defines the contract name is searched. \&#39;EXACT\&#39; means returning names which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning names which contains the search input (case-insensitive).
         * @param {string} [shortDescription] The text used for searching contracts by short description.
         * @param {string} [descriptionSearchMode] Defines the contract description is searched. \&#39;EXACT\&#39; means returning description which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning description which contains the search input (case-insensitive).
         * @param {string} [state] Specifies contracts with which state should be returned. \&#39;AllList\&#39; returns contracts in all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSearchContracts: async (storeId: string, accountId: string, name?: string, nameSearchMode?: string, shortDescription?: string, descriptionSearchMode?: string, state?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractSearchContracts', 'storeId', storeId)
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('buyerContractSearchContracts', 'accountId', accountId)
            const localVarPath = `/store/{storeId}/buyer_contract/byAccountId/{accountId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"accountId"}}`, String(accountId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (nameSearchMode !== undefined) {
                localVarQueryParameter['nameSearchMode'] = nameSearchMode;
            }

            if (shortDescription !== undefined) {
                localVarQueryParameter['shortDescription'] = shortDescription;
            }

            if (descriptionSearchMode !== undefined) {
                localVarQueryParameter['descriptionSearchMode'] = descriptionSearchMode;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submits a buyer contract for approval. <br> If the designated approvers for the contract decides a contract is complete and acceptable, they can move the contract into the approved state. WebSphere Commerce will automatically attempt to deploy an approved contract.<br>If the designated approver for a store decides a contract is not complete or is unacceptable, they can move the contract into the rejected state. A contract in the rejected state can be changed and re-submitted for approval, canceled, or marked for deletion.
         * @summary Submits a buyer contract for approval
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSubmit: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractSubmit', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractSubmit', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/submit`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Suspends an active buyer contract and refresh the contract cache.
         * @summary Suspends an active buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be suspended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSuspend: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractSuspend', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractSuspend', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}/suspend`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing buyer contract.
         * @summary Updates an existing buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDetails} buyerContractDetails The request body for updating a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractUpdateBuyerContract: async (storeId: string, contractId: string, buyerContractDetails: BuyerContractDetails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('buyerContractUpdateBuyerContract', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('buyerContractUpdateBuyerContract', 'contractId', contractId)
            // verify required parameter 'buyerContractDetails' is not null or undefined
            assertParamExists('buyerContractUpdateBuyerContract', 'buyerContractDetails', buyerContractDetails)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a buyer contract by its ID.
         * @summary Gets a buyer contract by ID
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFindByContractId: async (storeId: string, contractId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contractFindByContractId', 'storeId', storeId)
            // verify required parameter 'contractId' is not null or undefined
            assertParamExists('contractFindByContractId', 'contractId', contractId)
            const localVarPath = `/store/{storeId}/buyer_contract/{contractId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"contractId"}}`, String(contractId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new buyer contract.
         * @summary Creates a new buyer contract
         * @param {string} storeId The store identifier.
         * @param {BuyerContractDetails} buyerContractDetails The request body for creating a new buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipModeCreateShipMode: async (storeId: string, buyerContractDetails: BuyerContractDetails, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('shipModeCreateShipMode', 'storeId', storeId)
            // verify required parameter 'buyerContractDetails' is not null or undefined
            assertParamExists('shipModeCreateShipMode', 'buyerContractDetails', buyerContractDetails)
            const localVarPath = `/store/{storeId}/buyer_contract`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(buyerContractDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BuyerContractApi - functional programming interface
 * @export
 */
export const BuyerContractApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BuyerContractApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a participant to a buyer contract.
         * @summary Adds a participant to a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractAddParticipantRequest} buyerContractAddParticipantRequest The request body for adding a participant to a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractAddParticipant(storeId: string, contractId: string, buyerContractAddParticipantRequest: BuyerContractAddParticipantRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractAddParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractAddParticipant(storeId, contractId, buyerContractAddParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Cancels the specified buyer contract by changing its contract state to Cancelled.<br>It does not remove the contract from the database. A contract can be cancelled any time except the contract is in Draft, DeploymentInProgress, or Closed state.
         * @summary Cancels the specified buyer contract by changing its contract state to Cancelled
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be cancelled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractCancel(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractCancelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractCancel(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Copies a buyer contract. <br> If the command completes successfully, a new contract will be create. The contract will be in Draft state with majorVersion set to 1 and minorVersion set to 0.
         * @summary Copies a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractCopyRequest} buyerContractCopyRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractCopy(storeId: string, contractId: string, buyerContractCopyRequest: BuyerContractCopyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractCopyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractCopy(storeId, contractId, buyerContractCopyRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the specified contracts by setting the contracts to mark for delete.<br>It does not remove the contract from the database immediately. A contract  has to be in one of the following state to run this command:  Draft, Cancelled, Closed.
         * @summary Deletes the specified contracts by setting the contracts to mark for delete
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractDeleteBuyerContract(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractDeleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractDeleteBuyerContract(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a participant relationship from a buyer contract.
         * @summary Deletes a participant relationship.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteParticipantRequest} buyerContractDeleteParticipantRequest The request body for deleting participant from a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractDeleteParticipant(storeId: string, contractId: string, buyerContractDeleteParticipantRequest: BuyerContractDeleteParticipantRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractDeleteParticipantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractDeleteParticipant(storeId, contractId, buyerContractDeleteParticipantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes terms and conditions from a buyer contract. The contract has to be in Draft state.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteTermConditionRequest} buyerContractDeleteTermConditionRequest The request body for adding or deleting terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractDeleteTermCondition(storeId: string, contractId: string, buyerContractDeleteTermConditionRequest: BuyerContractDeleteTermConditionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractDeleteTermConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractDeleteTermCondition(storeId, contractId, buyerContractDeleteTermConditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \"Creates a new version of a specified buyer contract. <br>The state of the specified contract has to be in one of the following states: Approved, Active, Suspended, DeploymentInProgress, and DeploymentFailed. <br>The new contract will have the same name as the specified one. <br>If both majorVersion and minorVersion are provided, the command respects the versions. Otherwise, the new contract will have the same major version number with the old contract. The minor version will be one plus the largest minor version with the same contract name,  origin, owner, majorVerion. <br>This new contract will be in Draft version.\"
         * @summary Creates a new version of a specified buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractNewVersionRequest} buyerContractNewVersionRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractNewVersion(storeId: string, contractId: string, buyerContractNewVersionRequest: BuyerContractNewVersionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractNewVersionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractNewVersion(storeId, contractId, buyerContractNewVersionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resumes the specified buyer contract to Active state. <br>The specified contract has to be in Suspended state.
         * @summary Resumes the specified buyer contract to Active state
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be resumed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractResume(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractResumeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractResume(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Adds or updates terms and conditions for a buyer contract.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractSaveTermConditionRequest} buyerContractSaveTermConditionRequest The request body for adding or updating terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractSaveTermCondition(storeId: string, contractId: string, buyerContractSaveTermConditionRequest: BuyerContractSaveTermConditionRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractSaveTermConditionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractSaveTermCondition(storeId, contractId, buyerContractSaveTermConditionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches contract in a business account. <br> It accepts \'name\' and \'shortDescription\' query parameters to search contract with name and short descriptions matching the query parameters (case-insensitive). <br>\'nameSearchMode\' and \'descriptionSearchMode\' query parameters are used to specify search behavior. <br>\'state\' query parameters are used for specifying contracts with which state should be returned. <br>If more than one query parameter are specified, only contracts which satisfies all specified query parameters are returned. <br>If no query parameter is specified, all contracts in this account are returned.
         * @summary Searches contracts in a business account.
         * @param {string} storeId The store identifier.
         * @param {string} accountId The ID of the business account which contains the contract.
         * @param {string} [name] The text used for searching contracts by name.
         * @param {string} [nameSearchMode] Defines the contract name is searched. \&#39;EXACT\&#39; means returning names which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning names which contains the search input (case-insensitive).
         * @param {string} [shortDescription] The text used for searching contracts by short description.
         * @param {string} [descriptionSearchMode] Defines the contract description is searched. \&#39;EXACT\&#39; means returning description which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning description which contains the search input (case-insensitive).
         * @param {string} [state] Specifies contracts with which state should be returned. \&#39;AllList\&#39; returns contracts in all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractSearchContracts(storeId: string, accountId: string, name?: string, nameSearchMode?: string, shortDescription?: string, descriptionSearchMode?: string, state?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractContractList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractSearchContracts(storeId, accountId, name, nameSearchMode, shortDescription, descriptionSearchMode, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submits a buyer contract for approval. <br> If the designated approvers for the contract decides a contract is complete and acceptable, they can move the contract into the approved state. WebSphere Commerce will automatically attempt to deploy an approved contract.<br>If the designated approver for a store decides a contract is not complete or is unacceptable, they can move the contract into the rejected state. A contract in the rejected state can be changed and re-submitted for approval, canceled, or marked for deletion.
         * @summary Submits a buyer contract for approval
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractSubmit(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractSubmitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractSubmit(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Suspends an active buyer contract and refresh the contract cache.
         * @summary Suspends an active buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be suspended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractSuspend(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractSuspendResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractSuspend(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing buyer contract.
         * @summary Updates an existing buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDetails} buyerContractDetails The request body for updating a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buyerContractUpdateBuyerContract(storeId: string, contractId: string, buyerContractDetails: BuyerContractDetails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buyerContractUpdateBuyerContract(storeId, contractId, buyerContractDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a buyer contract by its ID.
         * @summary Gets a buyer contract by ID
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contractFindByContractId(storeId: string, contractId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contractFindByContractId(storeId, contractId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new buyer contract.
         * @summary Creates a new buyer contract
         * @param {string} storeId The store identifier.
         * @param {BuyerContractDetails} buyerContractDetails The request body for creating a new buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shipModeCreateShipMode(storeId: string, buyerContractDetails: BuyerContractDetails, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BuyerContractCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shipModeCreateShipMode(storeId, buyerContractDetails, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BuyerContractApi - factory interface
 * @export
 */
export const BuyerContractApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BuyerContractApiFp(configuration)
    return {
        /**
         * Adds a participant to a buyer contract.
         * @summary Adds a participant to a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractAddParticipantRequest} buyerContractAddParticipantRequest The request body for adding a participant to a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractAddParticipant(storeId: string, contractId: string, buyerContractAddParticipantRequest: BuyerContractAddParticipantRequest, options?: any): AxiosPromise<BuyerContractAddParticipantResponse> {
            return localVarFp.buyerContractAddParticipant(storeId, contractId, buyerContractAddParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Cancels the specified buyer contract by changing its contract state to Cancelled.<br>It does not remove the contract from the database. A contract can be cancelled any time except the contract is in Draft, DeploymentInProgress, or Closed state.
         * @summary Cancels the specified buyer contract by changing its contract state to Cancelled
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be cancelled.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractCancel(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractCancelResponse> {
            return localVarFp.buyerContractCancel(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Copies a buyer contract. <br> If the command completes successfully, a new contract will be create. The contract will be in Draft state with majorVersion set to 1 and minorVersion set to 0.
         * @summary Copies a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractCopyRequest} buyerContractCopyRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractCopy(storeId: string, contractId: string, buyerContractCopyRequest: BuyerContractCopyRequest, options?: any): AxiosPromise<BuyerContractCopyResponse> {
            return localVarFp.buyerContractCopy(storeId, contractId, buyerContractCopyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the specified contracts by setting the contracts to mark for delete.<br>It does not remove the contract from the database immediately. A contract  has to be in one of the following state to run this command:  Draft, Cancelled, Closed.
         * @summary Deletes the specified contracts by setting the contracts to mark for delete
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteBuyerContract(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractDeleteResponse> {
            return localVarFp.buyerContractDeleteBuyerContract(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a participant relationship from a buyer contract.
         * @summary Deletes a participant relationship.
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteParticipantRequest} buyerContractDeleteParticipantRequest The request body for deleting participant from a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteParticipant(storeId: string, contractId: string, buyerContractDeleteParticipantRequest: BuyerContractDeleteParticipantRequest, options?: any): AxiosPromise<BuyerContractDeleteParticipantResponse> {
            return localVarFp.buyerContractDeleteParticipant(storeId, contractId, buyerContractDeleteParticipantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes terms and conditions from a buyer contract. The contract has to be in Draft state.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDeleteTermConditionRequest} buyerContractDeleteTermConditionRequest The request body for adding or deleting terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractDeleteTermCondition(storeId: string, contractId: string, buyerContractDeleteTermConditionRequest: BuyerContractDeleteTermConditionRequest, options?: any): AxiosPromise<BuyerContractDeleteTermConditionResponse> {
            return localVarFp.buyerContractDeleteTermCondition(storeId, contractId, buyerContractDeleteTermConditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * \"Creates a new version of a specified buyer contract. <br>The state of the specified contract has to be in one of the following states: Approved, Active, Suspended, DeploymentInProgress, and DeploymentFailed. <br>The new contract will have the same name as the specified one. <br>If both majorVersion and minorVersion are provided, the command respects the versions. Otherwise, the new contract will have the same major version number with the old contract. The minor version will be one plus the largest minor version with the same contract name,  origin, owner, majorVerion. <br>This new contract will be in Draft version.\"
         * @summary Creates a new version of a specified buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be copied.
         * @param {BuyerContractNewVersionRequest} buyerContractNewVersionRequest The request body for copying a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractNewVersion(storeId: string, contractId: string, buyerContractNewVersionRequest: BuyerContractNewVersionRequest, options?: any): AxiosPromise<BuyerContractNewVersionResponse> {
            return localVarFp.buyerContractNewVersion(storeId, contractId, buyerContractNewVersionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Resumes the specified buyer contract to Active state. <br>The specified contract has to be in Suspended state.
         * @summary Resumes the specified buyer contract to Active state
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be resumed.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractResume(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractResumeResponse> {
            return localVarFp.buyerContractResume(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Adds or updates terms and conditions for a buyer contract.
         * @summary Adds or updates terms and conditions for a buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractSaveTermConditionRequest} buyerContractSaveTermConditionRequest The request body for adding or updating terms and conditions for a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSaveTermCondition(storeId: string, contractId: string, buyerContractSaveTermConditionRequest: BuyerContractSaveTermConditionRequest, options?: any): AxiosPromise<BuyerContractSaveTermConditionResponse> {
            return localVarFp.buyerContractSaveTermCondition(storeId, contractId, buyerContractSaveTermConditionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches contract in a business account. <br> It accepts \'name\' and \'shortDescription\' query parameters to search contract with name and short descriptions matching the query parameters (case-insensitive). <br>\'nameSearchMode\' and \'descriptionSearchMode\' query parameters are used to specify search behavior. <br>\'state\' query parameters are used for specifying contracts with which state should be returned. <br>If more than one query parameter are specified, only contracts which satisfies all specified query parameters are returned. <br>If no query parameter is specified, all contracts in this account are returned.
         * @summary Searches contracts in a business account.
         * @param {string} storeId The store identifier.
         * @param {string} accountId The ID of the business account which contains the contract.
         * @param {string} [name] The text used for searching contracts by name.
         * @param {string} [nameSearchMode] Defines the contract name is searched. \&#39;EXACT\&#39; means returning names which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning names which contains the search input (case-insensitive).
         * @param {string} [shortDescription] The text used for searching contracts by short description.
         * @param {string} [descriptionSearchMode] Defines the contract description is searched. \&#39;EXACT\&#39; means returning description which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning description which contains the search input (case-insensitive).
         * @param {string} [state] Specifies contracts with which state should be returned. \&#39;AllList\&#39; returns contracts in all states.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSearchContracts(storeId: string, accountId: string, name?: string, nameSearchMode?: string, shortDescription?: string, descriptionSearchMode?: string, state?: string, options?: any): AxiosPromise<BuyerContractContractList> {
            return localVarFp.buyerContractSearchContracts(storeId, accountId, name, nameSearchMode, shortDescription, descriptionSearchMode, state, options).then((request) => request(axios, basePath));
        },
        /**
         * Submits a buyer contract for approval. <br> If the designated approvers for the contract decides a contract is complete and acceptable, they can move the contract into the approved state. WebSphere Commerce will automatically attempt to deploy an approved contract.<br>If the designated approver for a store decides a contract is not complete or is unacceptable, they can move the contract into the rejected state. A contract in the rejected state can be changed and re-submitted for approval, canceled, or marked for deletion.
         * @summary Submits a buyer contract for approval
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSubmit(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractSubmitResponse> {
            return localVarFp.buyerContractSubmit(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Suspends an active buyer contract and refresh the contract cache.
         * @summary Suspends an active buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract to be suspended.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractSuspend(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractSuspendResponse> {
            return localVarFp.buyerContractSuspend(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing buyer contract.
         * @summary Updates an existing buyer contract
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {BuyerContractDetails} buyerContractDetails The request body for updating a buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buyerContractUpdateBuyerContract(storeId: string, contractId: string, buyerContractDetails: BuyerContractDetails, options?: any): AxiosPromise<BuyerContractUpdateResponse> {
            return localVarFp.buyerContractUpdateBuyerContract(storeId, contractId, buyerContractDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a buyer contract by its ID.
         * @summary Gets a buyer contract by ID
         * @param {string} storeId The store identifier.
         * @param {string} contractId The ID of the buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contractFindByContractId(storeId: string, contractId: string, options?: any): AxiosPromise<BuyerContractDetails> {
            return localVarFp.contractFindByContractId(storeId, contractId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new buyer contract.
         * @summary Creates a new buyer contract
         * @param {string} storeId The store identifier.
         * @param {BuyerContractDetails} buyerContractDetails The request body for creating a new buyer contract.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shipModeCreateShipMode(storeId: string, buyerContractDetails: BuyerContractDetails, options?: any): AxiosPromise<BuyerContractCreateResponse> {
            return localVarFp.shipModeCreateShipMode(storeId, buyerContractDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BuyerContractApi - object-oriented interface
 * @export
 * @class BuyerContractApi
 * @extends {BaseAPI}
 */
export class BuyerContractApi extends BaseAPI {
    /**
     * Adds a participant to a buyer contract.
     * @summary Adds a participant to a buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {BuyerContractAddParticipantRequest} buyerContractAddParticipantRequest The request body for adding a participant to a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractAddParticipant(storeId: string, contractId: string, buyerContractAddParticipantRequest: BuyerContractAddParticipantRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractAddParticipant(storeId, contractId, buyerContractAddParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cancels the specified buyer contract by changing its contract state to Cancelled.<br>It does not remove the contract from the database. A contract can be cancelled any time except the contract is in Draft, DeploymentInProgress, or Closed state.
     * @summary Cancels the specified buyer contract by changing its contract state to Cancelled
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract to be cancelled.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractCancel(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractCancel(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Copies a buyer contract. <br> If the command completes successfully, a new contract will be create. The contract will be in Draft state with majorVersion set to 1 and minorVersion set to 0.
     * @summary Copies a buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract to be copied.
     * @param {BuyerContractCopyRequest} buyerContractCopyRequest The request body for copying a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractCopy(storeId: string, contractId: string, buyerContractCopyRequest: BuyerContractCopyRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractCopy(storeId, contractId, buyerContractCopyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the specified contracts by setting the contracts to mark for delete.<br>It does not remove the contract from the database immediately. A contract  has to be in one of the following state to run this command:  Draft, Cancelled, Closed.
     * @summary Deletes the specified contracts by setting the contracts to mark for delete
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractDeleteBuyerContract(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractDeleteBuyerContract(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a participant relationship from a buyer contract.
     * @summary Deletes a participant relationship.
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {BuyerContractDeleteParticipantRequest} buyerContractDeleteParticipantRequest The request body for deleting participant from a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractDeleteParticipant(storeId: string, contractId: string, buyerContractDeleteParticipantRequest: BuyerContractDeleteParticipantRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractDeleteParticipant(storeId, contractId, buyerContractDeleteParticipantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes terms and conditions from a buyer contract. The contract has to be in Draft state.
     * @summary Adds or updates terms and conditions for a buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {BuyerContractDeleteTermConditionRequest} buyerContractDeleteTermConditionRequest The request body for adding or deleting terms and conditions for a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractDeleteTermCondition(storeId: string, contractId: string, buyerContractDeleteTermConditionRequest: BuyerContractDeleteTermConditionRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractDeleteTermCondition(storeId, contractId, buyerContractDeleteTermConditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \"Creates a new version of a specified buyer contract. <br>The state of the specified contract has to be in one of the following states: Approved, Active, Suspended, DeploymentInProgress, and DeploymentFailed. <br>The new contract will have the same name as the specified one. <br>If both majorVersion and minorVersion are provided, the command respects the versions. Otherwise, the new contract will have the same major version number with the old contract. The minor version will be one plus the largest minor version with the same contract name,  origin, owner, majorVerion. <br>This new contract will be in Draft version.\"
     * @summary Creates a new version of a specified buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract to be copied.
     * @param {BuyerContractNewVersionRequest} buyerContractNewVersionRequest The request body for copying a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractNewVersion(storeId: string, contractId: string, buyerContractNewVersionRequest: BuyerContractNewVersionRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractNewVersion(storeId, contractId, buyerContractNewVersionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resumes the specified buyer contract to Active state. <br>The specified contract has to be in Suspended state.
     * @summary Resumes the specified buyer contract to Active state
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract to be resumed.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractResume(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractResume(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Adds or updates terms and conditions for a buyer contract.
     * @summary Adds or updates terms and conditions for a buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {BuyerContractSaveTermConditionRequest} buyerContractSaveTermConditionRequest The request body for adding or updating terms and conditions for a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractSaveTermCondition(storeId: string, contractId: string, buyerContractSaveTermConditionRequest: BuyerContractSaveTermConditionRequest, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractSaveTermCondition(storeId, contractId, buyerContractSaveTermConditionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches contract in a business account. <br> It accepts \'name\' and \'shortDescription\' query parameters to search contract with name and short descriptions matching the query parameters (case-insensitive). <br>\'nameSearchMode\' and \'descriptionSearchMode\' query parameters are used to specify search behavior. <br>\'state\' query parameters are used for specifying contracts with which state should be returned. <br>If more than one query parameter are specified, only contracts which satisfies all specified query parameters are returned. <br>If no query parameter is specified, all contracts in this account are returned.
     * @summary Searches contracts in a business account.
     * @param {string} storeId The store identifier.
     * @param {string} accountId The ID of the business account which contains the contract.
     * @param {string} [name] The text used for searching contracts by name.
     * @param {string} [nameSearchMode] Defines the contract name is searched. \&#39;EXACT\&#39; means returning names which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning names which contains the search input (case-insensitive).
     * @param {string} [shortDescription] The text used for searching contracts by short description.
     * @param {string} [descriptionSearchMode] Defines the contract description is searched. \&#39;EXACT\&#39; means returning description which exactly match(case-insensitive) the input.  \&#39;LIKE\&#39; means returning description which contains the search input (case-insensitive).
     * @param {string} [state] Specifies contracts with which state should be returned. \&#39;AllList\&#39; returns contracts in all states.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractSearchContracts(storeId: string, accountId: string, name?: string, nameSearchMode?: string, shortDescription?: string, descriptionSearchMode?: string, state?: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractSearchContracts(storeId, accountId, name, nameSearchMode, shortDescription, descriptionSearchMode, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submits a buyer contract for approval. <br> If the designated approvers for the contract decides a contract is complete and acceptable, they can move the contract into the approved state. WebSphere Commerce will automatically attempt to deploy an approved contract.<br>If the designated approver for a store decides a contract is not complete or is unacceptable, they can move the contract into the rejected state. A contract in the rejected state can be changed and re-submitted for approval, canceled, or marked for deletion.
     * @summary Submits a buyer contract for approval
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractSubmit(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractSubmit(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Suspends an active buyer contract and refresh the contract cache.
     * @summary Suspends an active buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract to be suspended.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractSuspend(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractSuspend(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing buyer contract.
     * @summary Updates an existing buyer contract
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {BuyerContractDetails} buyerContractDetails The request body for updating a buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public buyerContractUpdateBuyerContract(storeId: string, contractId: string, buyerContractDetails: BuyerContractDetails, options?: any) {
        return BuyerContractApiFp(this.configuration).buyerContractUpdateBuyerContract(storeId, contractId, buyerContractDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a buyer contract by its ID.
     * @summary Gets a buyer contract by ID
     * @param {string} storeId The store identifier.
     * @param {string} contractId The ID of the buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public contractFindByContractId(storeId: string, contractId: string, options?: any) {
        return BuyerContractApiFp(this.configuration).contractFindByContractId(storeId, contractId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new buyer contract.
     * @summary Creates a new buyer contract
     * @param {string} storeId The store identifier.
     * @param {BuyerContractDetails} buyerContractDetails The request body for creating a new buyer contract.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BuyerContractApi
     */
    public shipModeCreateShipMode(storeId: string, buyerContractDetails: BuyerContractDetails, options?: any) {
        return BuyerContractApiFp(this.configuration).shipModeCreateShipMode(storeId, buyerContractDetails, options).then((request) => request(this.axios, this.basePath));
    }
}
