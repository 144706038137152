/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * PointofinterestApi - axios parameter creator
 * @export
 */
export const PointofinterestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {string} [langId] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestByProximityGet: async (storeId: string, responseFormat?: string, langId?: string, latitude?: string, longitude?: string, poiType?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestByProximityGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointOfInterest/byProximity`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (poiType !== undefined) {
                localVarQueryParameter['poiType'] = poiType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdDelete: async (pointOfInterestId: string, storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointOfInterestId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdDelete', 'pointOfInterestId', pointOfInterestId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdDelete', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointOfInterest/{pointOfInterestId}`
                .replace(`{${"pointOfInterestId"}}`, String(pointOfInterestId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by the point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [langId] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdGet: async (pointOfInterestId: string, storeId: string, langId?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointOfInterestId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdGet', 'pointOfInterestId', pointOfInterestId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointOfInterest/{pointOfInterestId}`
                .replace(`{${"pointOfInterestId"}}`, String(pointOfInterestId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (langId !== undefined) {
                localVarQueryParameter['langId'] = langId;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdPut: async (pointOfInterestId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'pointOfInterestId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdPut', 'pointOfInterestId', pointOfInterestId)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPointOfInterestIdPut', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointOfInterest/{pointOfInterestId}`
                .replace(`{${"pointOfInterestId"}}`, String(pointOfInterestId))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }

            if (operation !== undefined) {
                localVarQueryParameter['operation'] = operation;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes a requisition list.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPost: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointOfInterestPost', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointOfInterest`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by cell Id.
         * @param {string} storeId 
         * @param {string} cellId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByCellIdCellIdGet: async (storeId: string, cellId: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByCellIdCellIdGet', 'storeId', storeId)
            // verify required parameter 'cellId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByCellIdCellIdGet', 'cellId', cellId)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byCellId/{cellId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"cellId"}}`, String(cellId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by POI type.
         * @param {string} storeId 
         * @param {string} poiType 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByPOITypePoiTypeGet: async (storeId: string, poiType: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByPOITypePoiTypeGet', 'storeId', storeId)
            // verify required parameter 'poiType' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByPOITypePoiTypeGet', 'poiType', poiType)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byPOIType/{poiType}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"poiType"}}`, String(poiType));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {string} [radius] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByProximityGet: async (storeId: string, latitude?: string, longitude?: string, poiType?: string, radius?: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByProximityGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byProximity`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }

            if (poiType !== undefined) {
                localVarQueryParameter['poiType'] = poiType;
            }

            if (radius !== undefined) {
                localVarQueryParameter['radius'] = radius;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by region Id.
         * @param {string} storeId 
         * @param {string} regionId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByRegionIdRegionIdGet: async (storeId: string, regionId: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByRegionIdRegionIdGet', 'storeId', storeId)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByRegionIdRegionIdGet', 'regionId', regionId)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byRegionId/{regionId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"regionId"}}`, String(regionId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} status 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByStatusStatusGet: async (storeId: string, status: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByStatusStatusGet', 'storeId', storeId)
            // verify required parameter 'status' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByStatusStatusGet', 'status', status)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byStatus/{status}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"status"}}`, String(status));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by tags. It will return the POIs whose tag contains the specified string.
         * @param {string} storeId 
         * @param {string} tag 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByTagTagGet: async (storeId: string, tag: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByTagTagGet', 'storeId', storeId)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByTagTagGet', 'tag', tag)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byTag/{tag}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"tag"}}`, String(tag));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} [xPath] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByXPathGet: async (storeId: string, xPath?: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByXPathGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byXPath`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xPath !== undefined) {
                localVarQueryParameter['xPath'] = xPath;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by zone Id.
         * @param {string} storeId 
         * @param {string} zoneId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByZoneIdZoneIdGet: async (storeId: string, zoneId: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByZoneIdZoneIdGet', 'storeId', storeId)
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestByZoneIdZoneIdGet', 'zoneId', zoneId)
            const localVarPath = `/store/{storeId}/pointsOfInterest/byZoneId/{zoneId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"zoneId"}}`, String(zoneId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieve all points of interest.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestGet: async (storeId: string, responseFormat?: string, langId?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdPointsOfInterestGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/pointsOfInterest`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (langId) {
                localVarQueryParameter['langId'] = langId.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PointofinterestApi - functional programming interface
 * @export
 */
export const PointofinterestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PointofinterestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {string} [langId] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointOfInterestByProximityGet(storeId: string, responseFormat?: string, langId?: string, latitude?: string, longitude?: string, poiType?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointOfInterestByProximityGet(storeId, responseFormat, langId, latitude, longitude, poiType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId: string, storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId, storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by the point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [langId] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId: string, storeId: string, langId?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId, storeId, langId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId, storeId, path, operation, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes a requisition list.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointOfInterestPost(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointOfInterestPost(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by cell Id.
         * @param {string} storeId 
         * @param {string} cellId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId: string, cellId: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId, cellId, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by POI type.
         * @param {string} storeId 
         * @param {string} poiType 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId: string, poiType: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId, poiType, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {string} [radius] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByProximityGet(storeId: string, latitude?: string, longitude?: string, poiType?: string, radius?: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByProximityGet(storeId, latitude, longitude, poiType, radius, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by region Id.
         * @param {string} storeId 
         * @param {string} regionId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId: string, regionId: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId, regionId, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} status 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByStatusStatusGet(storeId: string, status: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByStatusStatusGet(storeId, status, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by tags. It will return the POIs whose tag contains the specified string.
         * @param {string} storeId 
         * @param {string} tag 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByTagTagGet(storeId: string, tag: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByTagTagGet(storeId, tag, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} [xPath] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByXPathGet(storeId: string, xPath?: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByXPathGet(storeId, xPath, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by zone Id.
         * @param {string} storeId 
         * @param {string} zoneId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId: string, zoneId: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId, zoneId, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieve all points of interest.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdPointsOfInterestGet(storeId: string, responseFormat?: string, langId?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdPointsOfInterestGet(storeId, responseFormat, langId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PointofinterestApi - factory interface
 * @export
 */
export const PointofinterestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PointofinterestApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {string} [langId] 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestByProximityGet(storeId: string, responseFormat?: string, langId?: string, latitude?: string, longitude?: string, poiType?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointOfInterestByProximityGet(storeId, responseFormat, langId, latitude, longitude, poiType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId: string, storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId, storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by the point of interest Id.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [langId] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId: string, storeId: string, langId?: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId, storeId, langId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} pointOfInterestId 
         * @param {string} storeId 
         * @param {string} [path] 
         * @param {string} [operation] 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId, storeId, path, operation, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes a requisition list.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointOfInterestPost(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointOfInterestPost(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by cell Id.
         * @param {string} storeId 
         * @param {string} cellId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId: string, cellId: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId, cellId, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by POI type.
         * @param {string} storeId 
         * @param {string} poiType 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId: string, poiType: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId, poiType, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by proximity based on specified geolocation, POI type, and radius.
         * @param {string} storeId 
         * @param {string} [latitude] 
         * @param {string} [longitude] 
         * @param {string} [poiType] 
         * @param {string} [radius] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByProximityGet(storeId: string, latitude?: string, longitude?: string, poiType?: string, radius?: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByProximityGet(storeId, latitude, longitude, poiType, radius, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by region Id.
         * @param {string} storeId 
         * @param {string} regionId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId: string, regionId: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId, regionId, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} status 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByStatusStatusGet(storeId: string, status: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByStatusStatusGet(storeId, status, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by tags. It will return the POIs whose tag contains the specified string.
         * @param {string} storeId 
         * @param {string} tag 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByTagTagGet(storeId: string, tag: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByTagTagGet(storeId, tag, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} storeId 
         * @param {string} [xPath] 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByXPathGet(storeId: string, xPath?: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByXPathGet(storeId, xPath, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve points of interest (POI) by zone Id.
         * @param {string} storeId 
         * @param {string} zoneId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId: string, zoneId: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId, zoneId, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieve all points of interest.
         * @param {string} storeId 
         * @param {string} [responseFormat] 
         * @param {Array<string>} [langId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdPointsOfInterestGet(storeId: string, responseFormat?: string, langId?: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdPointsOfInterestGet(storeId, responseFormat, langId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PointofinterestApi - object-oriented interface
 * @export
 * @class PointofinterestApi
 * @extends {BaseAPI}
 */
export class PointofinterestApi extends BaseAPI {
    /**
     * 
     * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {string} [langId] 
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {string} [poiType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointOfInterestByProximityGet(storeId: string, responseFormat?: string, langId?: string, latitude?: string, longitude?: string, poiType?: string, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointOfInterestByProximityGet(storeId, responseFormat, langId, latitude, longitude, poiType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} pointOfInterestId 
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId: string, storeId: string, responseFormat?: string, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointOfInterestPointOfInterestIdDelete(pointOfInterestId, storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the point of interest (POI) by the point of interest Id.
     * @param {string} pointOfInterestId 
     * @param {string} storeId 
     * @param {string} [langId] 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId: string, storeId: string, langId?: string, responseFormat?: string, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointOfInterestPointOfInterestIdGet(pointOfInterestId, storeId, langId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve the point of interest (POI) by proximity based on specified geolocation, POI type, and radius.
     * @param {string} pointOfInterestId 
     * @param {string} storeId 
     * @param {string} [path] 
     * @param {string} [operation] 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId: string, storeId: string, path?: string, operation?: string, responseFormat?: string, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointOfInterestPointOfInterestIdPut(pointOfInterestId, storeId, path, operation, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes a requisition list.
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointOfInterestPost(storeId: string, responseFormat?: string, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointOfInterestPost(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by cell Id.
     * @param {string} storeId 
     * @param {string} cellId 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId: string, cellId: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByCellIdCellIdGet(storeId, cellId, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by POI type.
     * @param {string} storeId 
     * @param {string} poiType 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId: string, poiType: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByPOITypePoiTypeGet(storeId, poiType, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by proximity based on specified geolocation, POI type, and radius.
     * @param {string} storeId 
     * @param {string} [latitude] 
     * @param {string} [longitude] 
     * @param {string} [poiType] 
     * @param {string} [radius] 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByProximityGet(storeId: string, latitude?: string, longitude?: string, poiType?: string, radius?: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByProximityGet(storeId, latitude, longitude, poiType, radius, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by region Id.
     * @param {string} storeId 
     * @param {string} regionId 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId: string, regionId: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByRegionIdRegionIdGet(storeId, regionId, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} storeId 
     * @param {string} status 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByStatusStatusGet(storeId: string, status: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByStatusStatusGet(storeId, status, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by tags. It will return the POIs whose tag contains the specified string.
     * @param {string} storeId 
     * @param {string} tag 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByTagTagGet(storeId: string, tag: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByTagTagGet(storeId, tag, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} storeId 
     * @param {string} [xPath] 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByXPathGet(storeId: string, xPath?: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByXPathGet(storeId, xPath, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve points of interest (POI) by zone Id.
     * @param {string} storeId 
     * @param {string} zoneId 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId: string, zoneId: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestByZoneIdZoneIdGet(storeId, zoneId, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieve all points of interest.
     * @param {string} storeId 
     * @param {string} [responseFormat] 
     * @param {Array<string>} [langId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PointofinterestApi
     */
    public storeStoreIdPointsOfInterestGet(storeId: string, responseFormat?: string, langId?: Array<string>, options?: any) {
        return PointofinterestApiFp(this.configuration).storeStoreIdPointsOfInterestGet(storeId, responseFormat, langId, options).then((request) => request(this.axios, this.basePath));
    }
}
