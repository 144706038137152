/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * The purchase order blanket term and condition. <br>Used for an unlimited number of orders. Blanket purchase order numbers that will be accepted when placing an order must be specified in the business account before buyers attempt to specify a blanket purchase order number.
 * @export
 * @interface POTCBlanketAllOf
 */
export interface POTCBlanketAllOf {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof POTCBlanketAllOf
     */
    _class?: POTCBlanketAllOfClassEnum;
    /**
     * The purchase order number.
     * @type {string}
     * @memberof POTCBlanketAllOf
     */
    ponumber?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum POTCBlanketAllOfClassEnum {
    ComIbmCommerceTradingEntitiesPotcBlanket = 'com.ibm.commerce.trading.entities.POTCBlanket'
}



