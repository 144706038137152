/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityBuyerIdentifier } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityIdentity } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroupsResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceUserBeansOrgEntityDataBeanIBMParentAssignedRolesDetails } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceUserBeansOrganizationSearchDataBeanIBMOrganizationListDetails } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ErrorMessageResponseContainer } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Allows administrators to find organization information by organization identifier.
         * @summary Allows administrators to find organization information by organization identifier.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. Default profile name &#x3D; IBM_Organization_Summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName: async (storeId: string, organizationId: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName', 'organizationId', organizationId)
            const localVarPath = `/store/{storeId}/organization/{organizationId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @summary This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @param {string} storeId The storeId.
         * @param {string} q The query name. &lt;ul&gt;&lt;li&gt;organizationHierarchy: Retrieves the organization hierarchy.&lt;/li&gt;&lt;li&gt;organizationsICanAdmin: This allows an administrator to find organizations that he/she can administer.&lt;/li&gt;&lt;li&gt;rolesICanAssignInOrg: This allows an administrator to find roles that he/she can assign in organizations.&lt;/li&gt;&lt;/ul&gt;
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [accountId] The trading account ID.
         * @param {string} [orgId] The organization identifier.
         * @param {string} [orgName] The organization name.
         * @param {string} [parentOrgName] The parent organization name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] The maximum number of results to be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationFindByQuery: async (storeId: string, q: string, profileName?: string, accountId?: string, orgId?: string, orgName?: string, parentOrgName?: string, startIndex?: string, maxResults?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationFindByQuery', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('organizationFindByQuery', 'q', q)
            const localVarPath = `/store/{storeId}/organization`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (orgName !== undefined) {
                localVarQueryParameter['orgName'] = orgName;
            }

            if (parentOrgName !== undefined) {
                localVarQueryParameter['parentOrgName'] = parentOrgName;
            }

            if (startIndex !== undefined) {
                localVarQueryParameter['startIndex'] = startIndex;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetEntitledOrganizations: async (storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationGetEntitledOrganizations', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/organization/@self/entitled_orgs`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (accountCheck !== undefined) {
                localVarQueryParameter['accountCheck'] = accountCheck;
            }

            if (explicitEntitlement !== undefined) {
                localVarQueryParameter['explicitEntitlement'] = explicitEntitlement;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @summary Register a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest} body Request body.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationRegisterBuyerOrganization: async (storeId: string, body: ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationRegisterBuyerOrganization', 'storeId', storeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('organizationRegisterBuyerOrganization', 'body', body)
            const localVarPath = `/store/{storeId}/organization/buyer`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows an administrator to register an organization.
         * @summary Allows an administrator to register an organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationRegisterOrganization: async (storeId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationRegisterOrganization', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/organization`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs an action on an organization. See each action for details on input and output. Actions include: updating an approval group, and assigning or unassigning one or more roles from an organization
         * @summary Performs an action on an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} action The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdateApprovalGroups: async (storeId: string, organizationId: string, action: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationUpdateApprovalGroups', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationUpdateApprovalGroups', 'organizationId', organizationId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('organizationUpdateApprovalGroups', 'action', action)
            const localVarPath = `/store/{storeId}/organization/{organizationId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This allows an administrator to update information of an organization.
         * @summary This allows an administrator to update information of an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdateOrganization: async (storeId: string, organizationId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('organizationUpdateOrganization', 'storeId', storeId)
            // verify required parameter 'organizationId' is not null or undefined
            assertParamExists('organizationUpdateOrganization', 'organizationId', organizationId)
            const localVarPath = `/store/{storeId}/organization/{organizationId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"organizationId"}}`, String(organizationId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Allows administrators to find organization information by organization identifier.
         * @summary Allows administrators to find organization information by organization identifier.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. Default profile name &#x3D; IBM_Organization_Summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId: string, organizationId: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceUserBeansOrgEntityDataBeanIBMParentAssignedRolesDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId, organizationId, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @summary This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @param {string} storeId The storeId.
         * @param {string} q The query name. &lt;ul&gt;&lt;li&gt;organizationHierarchy: Retrieves the organization hierarchy.&lt;/li&gt;&lt;li&gt;organizationsICanAdmin: This allows an administrator to find organizations that he/she can administer.&lt;/li&gt;&lt;li&gt;rolesICanAssignInOrg: This allows an administrator to find roles that he/she can assign in organizations.&lt;/li&gt;&lt;/ul&gt;
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [accountId] The trading account ID.
         * @param {string} [orgId] The organization identifier.
         * @param {string} [orgName] The organization name.
         * @param {string} [parentOrgName] The parent organization name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] The maximum number of results to be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationFindByQuery(storeId: string, q: string, profileName?: string, accountId?: string, orgId?: string, orgName?: string, parentOrgName?: string, startIndex?: string, maxResults?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceUserBeansOrganizationSearchDataBeanIBMOrganizationListDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationFindByQuery(storeId, q, profileName, accountId, orgId, orgName, parentOrgName, startIndex, maxResults, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @summary Register a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest} body Request body.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationRegisterBuyerOrganization(storeId: string, body: ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityBuyerIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationRegisterBuyerOrganization(storeId, body, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows an administrator to register an organization.
         * @summary Allows an administrator to register an organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationRegisterOrganization(storeId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationRegisterOrganization(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs an action on an organization. See each action for details on input and output. Actions include: updating an approval group, and assigning or unassigning one or more roles from an organization
         * @summary Performs an action on an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} action The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationUpdateApprovalGroups(storeId: string, organizationId: string, action: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationUpdateApprovalGroups(storeId, organizationId, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This allows an administrator to update information of an organization.
         * @summary This allows an administrator to update information of an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async organizationUpdateOrganization(storeId: string, organizationId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityIdentity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.organizationUpdateOrganization(storeId, organizationId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Allows administrators to find organization information by organization identifier.
         * @summary Allows administrators to find organization information by organization identifier.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. Default profile name &#x3D; IBM_Organization_Summary.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId: string, organizationId: string, profileName?: string, options?: any): AxiosPromise<ComIbmCommerceUserBeansOrgEntityDataBeanIBMParentAssignedRolesDetails> {
            return localVarFp.organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId, organizationId, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @summary This allows an administrator to find organizations based on query name. See each query for details on input and output.
         * @param {string} storeId The storeId.
         * @param {string} q The query name. &lt;ul&gt;&lt;li&gt;organizationHierarchy: Retrieves the organization hierarchy.&lt;/li&gt;&lt;li&gt;organizationsICanAdmin: This allows an administrator to find organizations that he/she can administer.&lt;/li&gt;&lt;li&gt;rolesICanAssignInOrg: This allows an administrator to find roles that he/she can assign in organizations.&lt;/li&gt;&lt;/ul&gt;
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [accountId] The trading account ID.
         * @param {string} [orgId] The organization identifier.
         * @param {string} [orgName] The organization name.
         * @param {string} [parentOrgName] The parent organization name.
         * @param {string} [startIndex] The starting index of the result.
         * @param {string} [maxResults] The maximum number of results to be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationFindByQuery(storeId: string, q: string, profileName?: string, accountId?: string, orgId?: string, orgName?: string, parentOrgName?: string, startIndex?: string, maxResults?: string, options?: any): AxiosPromise<ComIbmCommerceUserBeansOrganizationSearchDataBeanIBMOrganizationListDetails> {
            return localVarFp.organizationFindByQuery(storeId, q, profileName, accountId, orgId, orgName, parentOrgName, startIndex, maxResults, options).then((request) => request(axios, basePath));
        },
        /**
         * Get user\'s entitled organizations.
         * @summary Get user\'s entitled organizations.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [accountCheck] 
         * @param {string} [explicitEntitlement] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any): AxiosPromise<ComIbmCommerceMemberBeansOrganizationListDataBeanIBMOrganizationSummary> {
            return localVarFp.organizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @summary Register a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest} body Request body.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationRegisterBuyerOrganization(storeId: string, body: ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityBuyerIdentifier> {
            return localVarFp.organizationRegisterBuyerOrganization(storeId, body, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows an administrator to register an organization.
         * @summary Allows an administrator to register an organization.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationRegisterOrganization(storeId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityIdentity> {
            return localVarFp.organizationRegisterOrganization(storeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs an action on an organization. See each action for details on input and output. Actions include: updating an approval group, and assigning or unassigning one or more roles from an organization
         * @summary Performs an action on an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {string} action The action of the rest service.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdateApprovalGroups(storeId: string, organizationId: string, action: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroupsResponse> {
            return localVarFp.organizationUpdateApprovalGroups(storeId, organizationId, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * This allows an administrator to update information of an organization.
         * @summary This allows an administrator to update information of an organization.
         * @param {string} storeId The store identifier.
         * @param {string} organizationId The organization identifier.
         * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        organizationUpdateOrganization(storeId: string, organizationId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityIdentity> {
            return localVarFp.organizationUpdateOrganization(storeId, organizationId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * Allows administrators to find organization information by organization identifier.
     * @summary Allows administrators to find organization information by organization identifier.
     * @param {string} storeId The store identifier.
     * @param {string} organizationId The organization identifier.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query. Default profile name &#x3D; IBM_Organization_Summary.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId: string, organizationId: string, profileName?: string, options?: any) {
        return OrganizationApiFp(this.configuration).organizationFindByOrganizationIdWParentAssignedRolesDetailsProfileName(storeId, organizationId, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This allows an administrator to find organizations based on query name. See each query for details on input and output.
     * @summary This allows an administrator to find organizations based on query name. See each query for details on input and output.
     * @param {string} storeId The storeId.
     * @param {string} q The query name. &lt;ul&gt;&lt;li&gt;organizationHierarchy: Retrieves the organization hierarchy.&lt;/li&gt;&lt;li&gt;organizationsICanAdmin: This allows an administrator to find organizations that he/she can administer.&lt;/li&gt;&lt;li&gt;rolesICanAssignInOrg: This allows an administrator to find roles that he/she can assign in organizations.&lt;/li&gt;&lt;/ul&gt;
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [accountId] The trading account ID.
     * @param {string} [orgId] The organization identifier.
     * @param {string} [orgName] The organization name.
     * @param {string} [parentOrgName] The parent organization name.
     * @param {string} [startIndex] The starting index of the result.
     * @param {string} [maxResults] The maximum number of results to be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationFindByQuery(storeId: string, q: string, profileName?: string, accountId?: string, orgId?: string, orgName?: string, parentOrgName?: string, startIndex?: string, maxResults?: string, options?: any) {
        return OrganizationApiFp(this.configuration).organizationFindByQuery(storeId, q, profileName, accountId, orgId, orgName, parentOrgName, startIndex, maxResults, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get user\'s entitled organizations.
     * @summary Get user\'s entitled organizations.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [accountCheck] 
     * @param {string} [explicitEntitlement] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationGetEntitledOrganizations(storeId: string, responseFormat?: string, accountCheck?: string, explicitEntitlement?: string, options?: any) {
        return OrganizationApiFp(this.configuration).organizationGetEntitledOrganizations(storeId, responseFormat, accountCheck, explicitEntitlement, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
     * @summary Register a new Buyer Organization as well as the initial Buyer Administrator for the new organization.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest} body Request body.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationRegisterBuyerOrganization(storeId: string, body: ComIbmCommerceRestMemberHandlerOrganizationHandlerBuyerRegistrationAddRequest, responseFormat?: string, options?: any) {
        return OrganizationApiFp(this.configuration).organizationRegisterBuyerOrganization(storeId, body, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows an administrator to register an organization.
     * @summary Allows an administrator to register an organization.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationRegisterOrganization(storeId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityAddRequest, options?: any) {
        return OrganizationApiFp(this.configuration).organizationRegisterOrganization(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs an action on an organization. See each action for details on input and output. Actions include: updating an approval group, and assigning or unassigning one or more roles from an organization
     * @summary Performs an action on an organization.
     * @param {string} storeId The store identifier.
     * @param {string} organizationId The organization identifier.
     * @param {string} action The action of the rest service.
     * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationUpdateApprovalGroups(storeId: string, organizationId: string, action: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerUpdateApprovalGroups, options?: any) {
        return OrganizationApiFp(this.configuration).organizationUpdateApprovalGroups(storeId, organizationId, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This allows an administrator to update information of an organization.
     * @summary This allows an administrator to update information of an organization.
     * @param {string} storeId The store identifier.
     * @param {string} organizationId The organization identifier.
     * @param {ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public organizationUpdateOrganization(storeId: string, organizationId: string, body?: ComIbmCommerceRestMemberHandlerOrganizationHandlerOrgEntityUpdateRequest, options?: any) {
        return OrganizationApiFp(this.configuration).organizationUpdateOrganization(storeId, organizationId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
