/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Participant } from './participant';
import { Property } from './property';
import { ShippingTC } from './shipping-tc';
import { TermConditionDescription } from './term-condition-description';

/**
 * The shipping to address term and condition. <br>This term contains specifies shipping discounts for the contract. These discounts specify an adjustment type (percent off or fixed cost), applicable shipping method, the adjustment amount, and the adjustment description.
 * @export
 * @interface ShippingTCShippingChargeAdjustment
 */
export interface ShippingTCShippingChargeAdjustment {
    /**
     * The full entity class name of the term and condition.
     * @type {string}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    _class?: ShippingTCShippingChargeAdjustmentClassEnum;
    /**
     * The additional properties of the term and condtion.
     * @type {Array<Property>}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    properties?: Array<Property>;
    /**
     * 
     * @type {TermConditionDescription}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    termConditionDescription?: TermConditionDescription;
    /**
     * The participants of the term and condtion.
     * @type {Array<Participant>}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    participants?: Array<Participant>;
    /**
     * The unique ID of the term and condition.
     * @type {number}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    referenceNumber?: number;
    /**
     * The action to the term condition.
     * @type {string}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    action?: ShippingTCShippingChargeAdjustmentActionEnum;
    /**
     * This column is used to indicate if the trading agreement participant must respond to this term during negotiation.
     * @type {boolean}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    mandatory?: boolean;
    /**
     * This column indicates if the trading agreement participant can change the term.
     * @type {boolean}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    changeable?: boolean;
    /**
     * a number indicating the sequence of the term within a trading agreement. This number must be unique within a trading agreement.
     * @type {number}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    sequenceNumber?: number;
    /**
     * Time that this term and condition was created.
     * @type {string}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    createTime?: string;
    /**
     * Time that this term and condition was last updated.
     * @type {string}
     * @memberof ShippingTCShippingChargeAdjustment
     */
    updateTime?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ShippingTCShippingChargeAdjustmentClassEnum {
    ComIbmCommerceTradingEntitiesShippingTcShippingChargeAdjustment = 'com.ibm.commerce.trading.entities.ShippingTCShippingChargeAdjustment'
}
/**
    * @export
    * @enum {string}
    */
export enum ShippingTCShippingChargeAdjustmentActionEnum {
    New = 'new',
    Update = 'update',
    Delete = 'delete',
    Noaction = 'noaction',
    Ignore = 'ignore'
}



