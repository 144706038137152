export const ORDER_ITEM = {
  EXT_ATTRIBUTE: {
    PRE_SALE_SHIPPING: {
      NAME: "PreSaleShipping",
      VALUE: {
        PRE_SALE_ONLY: "PreSaleOnly",
        ASAP: "Asap",
      },
    },
  },
};
