import { PRODUCT_ATTRIBUTES } from "solteq/src/constants/common";

export const ProductUtils = {
  isPreSaleProduct: (productAttributes?: Array<any>): boolean =>
    isAttributeActive(PRODUCT_ATTRIBUTES.PRE_SALE, productAttributes),

  isNewProduct: (productAttributes?: Array<any>): boolean =>
    isAttributeActive(PRODUCT_ATTRIBUTES.NEW_PRODUCT, productAttributes),

  getProfitMargin: (purchasePrice: number, unitPrice: number): number => {
    if (purchasePrice && purchasePrice > 0 && unitPrice && unitPrice > 0) {
      return 100 - (purchasePrice / unitPrice) * 100;
    }
    return 0;
  },
};

const isAttributeActive = (attrIdentifier: string, productAttributes?: Array<any>): boolean => {
  return !!productAttributes?.find((attr) => {
    return attr.identifier === attrIdentifier && isActive(attr);
  });
};

const isActive = (attribute: any) => {
  return attribute.values.find((attributeValue) => attributeIsActive(attributeValue.identifier));
};

export const attributeIsActive = (value: string) => value === "1" || value === "2";
