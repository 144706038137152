import { SEARCH_FIND_WITH_PRICE_PROFILE } from "./../../../constants/common";
/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import { call, put } from "redux-saga/effects";
//Foundation libraries
import productsService from "../../../_foundation/apis/search/products.service";
//Redux
import * as ACTIONS from "../../action-types/catalog";

/**
 * Saga worker to invoke get product list API
 */
export function* fetchProductList(action: any) {
  try {
    const payload = action.payload;
    let response = yield call(productsService.findProductsUsingGET, payload.parameters);

    // If using search and yielding no results, try again with a price profile
    if (response.data?.total === 0 && payload.parameters.searchTerm?.length > 0) {
      response = yield call(productsService.findProductsUsingGET, {
        ...payload.parameters,
        query: { profileName: SEARCH_FIND_WITH_PRICE_PROFILE },
      });
    }

    yield put({
      type: ACTIONS.PRODUCT_LIST_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_GET_ERROR, error });
  }
}

/**
 * Saga worker to invoke get product list API for PDP
 */
export function* fetchProductListForPDP(action: any) {
  try {
    const payload = action.payload;
    const response = yield call(productsService.findProductsUsingGET, payload.parameters);
    yield put({
      type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_SUCCESS,
      response: response.data,
      payload: payload,
    });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_FOR_PDP_GET_ERROR, error });
  }
}

export function* fetchProductListDetails(action: any) {
  try {
    const payload = action.payload;
    const params = payload.parameters;
    const r = yield call(productsService.findProductsUsingGET, params);
    const response = r.data;
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_S, response, payload });
  } catch (error) {
    yield put({ type: ACTIONS.PRODUCT_LIST_DEETS_F, error });
  }
}
