/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PersonSingleContact } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new contact in a person\'s address book.
         * @summary Create contact in address book
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactAddPersonContact: async (storeId: string, responseFormat?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactAddPersonContact', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/contact`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the contact in the address book identified by nickname.
         * @summary Delete contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactDeletePersonContact: async (storeId: string, nickName: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactDeletePersonContact', 'storeId', storeId)
            // verify required parameter 'nickName' is not null or undefined
            assertParamExists('contactDeletePersonContact', 'nickName', nickName)
            const localVarPath = `/store/{storeId}/person/@self/contact/{nickName}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"nickName"}}`, String(nickName));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a person contact by address ID.
         * @summary Get contact by address ID
         * @param {string} storeId The store identifier.
         * @param {string} addressId The address identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindPersonContactById: async (storeId: string, addressId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactFindPersonContactById', 'storeId', storeId)
            // verify required parameter 'addressId' is not null or undefined
            assertParamExists('contactFindPersonContactById', 'addressId', addressId)
            const localVarPath = `/store/{storeId}/person/@self/contact/byAddressId/{addressId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"addressId"}}`, String(addressId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a person contact by nickname.
         * @summary Get contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindPersonContactByNickName: async (storeId: string, nickName: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactFindPersonContactByNickName', 'storeId', storeId)
            // verify required parameter 'nickName' is not null or undefined
            assertParamExists('contactFindPersonContactByNickName', 'nickName', nickName)
            const localVarPath = `/store/{storeId}/person/@self/contact/{nickName}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"nickName"}}`, String(nickName));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the contacts in a person\'s address book. If the addressType optional query parameter is passed in, the returned contacts are filtered by addressType.
         * @summary Get contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} [addressType] The addressType to filter contacts by. All contacts will be returned if a valid value is not supplied for this parameter.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGetAllPersonContact: async (storeId: string, addressType?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactGetAllPersonContact', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/contact`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addressType !== undefined) {
                localVarQueryParameter['addressType'] = addressType;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the contact in the address book identified by nickname.
         * @summary Update contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUpdatePersonContact: async (storeId: string, nickName: string, responseFormat?: string, body?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('contactUpdatePersonContact', 'storeId', storeId)
            // verify required parameter 'nickName' is not null or undefined
            assertParamExists('contactUpdatePersonContact', 'nickName', nickName)
            const localVarPath = `/store/{storeId}/person/@self/contact/{nickName}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"nickName"}}`, String(nickName));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new contact in a person\'s address book.
         * @summary Create contact in address book
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactAddPersonContact(storeId: string, responseFormat?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactAddPersonContact(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the contact in the address book identified by nickname.
         * @summary Delete contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactDeletePersonContact(storeId: string, nickName: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactDeletePersonContact(storeId, nickName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a person contact by address ID.
         * @summary Get contact by address ID
         * @param {string} storeId The store identifier.
         * @param {string} addressId The address identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactFindPersonContactById(storeId: string, addressId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSingleContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactFindPersonContactById(storeId, addressId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a person contact by nickname.
         * @summary Get contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactFindPersonContactByNickName(storeId: string, nickName: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSingleContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactFindPersonContactByNickName(storeId, nickName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the contacts in a person\'s address book. If the addressType optional query parameter is passed in, the returned contacts are filtered by addressType.
         * @summary Get contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} [addressType] The addressType to filter contacts by. All contacts will be returned if a valid value is not supplied for this parameter.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactGetAllPersonContact(storeId: string, addressType?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactGetAllPersonContact(storeId, addressType, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the contact in the address book identified by nickname.
         * @summary Update contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contactUpdatePersonContact(storeId: string, nickName: string, responseFormat?: string, body?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonSingleContact>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contactUpdatePersonContact(storeId, nickName, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactApiFp(configuration)
    return {
        /**
         * Creates a new contact in a person\'s address book.
         * @summary Create contact in address book
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactAddPersonContact(storeId: string, responseFormat?: string, body?: object, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonContactHandlerUserIdentifier> {
            return localVarFp.contactAddPersonContact(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the contact in the address book identified by nickname.
         * @summary Delete contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactDeletePersonContact(storeId: string, nickName: string, responseFormat?: string, options?: any): AxiosPromise<object> {
            return localVarFp.contactDeletePersonContact(storeId, nickName, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a person contact by address ID.
         * @summary Get contact by address ID
         * @param {string} storeId The store identifier.
         * @param {string} addressId The address identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindPersonContactById(storeId: string, addressId: string, responseFormat?: string, options?: any): AxiosPromise<PersonSingleContact> {
            return localVarFp.contactFindPersonContactById(storeId, addressId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a person contact by nickname.
         * @summary Get contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactFindPersonContactByNickName(storeId: string, nickName: string, responseFormat?: string, options?: any): AxiosPromise<PersonSingleContact> {
            return localVarFp.contactFindPersonContactByNickName(storeId, nickName, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the contacts in a person\'s address book. If the addressType optional query parameter is passed in, the returned contacts are filtered by addressType.
         * @summary Get contact from address book
         * @param {string} storeId The store identifier.
         * @param {string} [addressType] The addressType to filter contacts by. All contacts will be returned if a valid value is not supplied for this parameter.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGetAllPersonContact(storeId: string, addressType?: string, responseFormat?: string, options?: any): AxiosPromise<object> {
            return localVarFp.contactGetAllPersonContact(storeId, addressType, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the contact in the address book identified by nickname.
         * @summary Update contact by nickname
         * @param {string} storeId The store identifier.
         * @param {string} nickName The contact name saved in addressBook by registered shopper.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {object} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactUpdatePersonContact(storeId: string, nickName: string, responseFormat?: string, body?: object, options?: any): AxiosPromise<PersonSingleContact> {
            return localVarFp.contactUpdatePersonContact(storeId, nickName, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * Creates a new contact in a person\'s address book.
     * @summary Create contact in address book
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {object} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactAddPersonContact(storeId: string, responseFormat?: string, body?: object, options?: any) {
        return ContactApiFp(this.configuration).contactAddPersonContact(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the contact in the address book identified by nickname.
     * @summary Delete contact from address book
     * @param {string} storeId The store identifier.
     * @param {string} nickName The contact name saved in addressBook by registered shopper.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactDeletePersonContact(storeId: string, nickName: string, responseFormat?: string, options?: any) {
        return ContactApiFp(this.configuration).contactDeletePersonContact(storeId, nickName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a person contact by address ID.
     * @summary Get contact by address ID
     * @param {string} storeId The store identifier.
     * @param {string} addressId The address identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactFindPersonContactById(storeId: string, addressId: string, responseFormat?: string, options?: any) {
        return ContactApiFp(this.configuration).contactFindPersonContactById(storeId, addressId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a person contact by nickname.
     * @summary Get contact by nickname
     * @param {string} storeId The store identifier.
     * @param {string} nickName The contact name saved in addressBook by registered shopper.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactFindPersonContactByNickName(storeId: string, nickName: string, responseFormat?: string, options?: any) {
        return ContactApiFp(this.configuration).contactFindPersonContactByNickName(storeId, nickName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the contacts in a person\'s address book. If the addressType optional query parameter is passed in, the returned contacts are filtered by addressType.
     * @summary Get contact from address book
     * @param {string} storeId The store identifier.
     * @param {string} [addressType] The addressType to filter contacts by. All contacts will be returned if a valid value is not supplied for this parameter.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactGetAllPersonContact(storeId: string, addressType?: string, responseFormat?: string, options?: any) {
        return ContactApiFp(this.configuration).contactGetAllPersonContact(storeId, addressType, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the contact in the address book identified by nickname.
     * @summary Update contact by nickname
     * @param {string} storeId The store identifier.
     * @param {string} nickName The contact name saved in addressBook by registered shopper.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {object} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public contactUpdatePersonContact(storeId: string, nickName: string, responseFormat?: string, body?: object, options?: any) {
        return ContactApiFp(this.configuration).contactUpdatePersonContact(storeId, nickName, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
