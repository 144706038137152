/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
/**
 * IntegrationConfigurationApi - axios parameter creator
 * @export
 */
export const IntegrationConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets integration configurations. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConfigurationGetConfiguration: async (responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration_configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets integration session key configuration. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get integration session key configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConfigurationGetEncryptedSessionKey: async (responseFormat?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/integration_configuration/session_key`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationConfigurationApi - functional programming interface
 * @export
 */
export const IntegrationConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets integration configurations. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationConfigurationGetConfiguration(responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationConfigurationGetConfiguration(responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets integration session key configuration. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get integration session key configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async integrationConfigurationGetEncryptedSessionKey(responseFormat?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.integrationConfigurationGetEncryptedSessionKey(responseFormat, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationConfigurationApi - factory interface
 * @export
 */
export const IntegrationConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationConfigurationApiFp(configuration)
    return {
        /**
         * Gets integration configurations. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConfigurationGetConfiguration(responseFormat?: string, profileName?: string, options?: any): AxiosPromise<object> {
            return localVarFp.integrationConfigurationGetConfiguration(responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets integration session key configuration. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get integration session key configuration
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        integrationConfigurationGetEncryptedSessionKey(responseFormat?: string, profileName?: string, options?: any): AxiosPromise<object> {
            return localVarFp.integrationConfigurationGetEncryptedSessionKey(responseFormat, profileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationConfigurationApi - object-oriented interface
 * @export
 * @class IntegrationConfigurationApi
 * @extends {BaseAPI}
 */
export class IntegrationConfigurationApi extends BaseAPI {
    /**
     * Gets integration configurations. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
     * @summary Get configuration
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationConfigurationApi
     */
    public integrationConfigurationGetConfiguration(responseFormat?: string, profileName?: string, options?: any) {
        return IntegrationConfigurationApiFp(this.configuration).integrationConfigurationGetConfiguration(responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets integration session key configuration. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
     * @summary Get integration session key configuration
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationConfigurationApi
     */
    public integrationConfigurationGetEncryptedSessionKey(responseFormat?: string, profileName?: string, options?: any) {
        return IntegrationConfigurationApiFp(this.configuration).integrationConfigurationGetEncryptedSessionKey(responseFormat, profileName, options).then((request) => request(this.axios, this.basePath));
    }
}
