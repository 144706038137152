/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMarketingHandlerESpotDataHandlerSearchTermsContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { EspotEspot } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { SpotSpot } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * SpotApi - axios parameter creator
 * @export
 */
export const SpotApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets all search terms that have search rules.
         * @summary Get search terms with search rules
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindAllSearchTerms: async (storeId: string, q: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eSpotFindAllSearchTerms', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('eSpotFindAllSearchTerms', 'q', q)
            const localVarPath = `/store/{storeId}/espot`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets an e-Marketing Spot by name.
         * @summary Get e-Marketing Spot by name
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindByName: async (name: string, storeId: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('eSpotFindByName', 'name', name)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eSpotFindByName', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/espot/{name}`
                .replace(`{${"name"}}`, String(name))
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (name2 !== undefined) {
                localVarQueryParameter['name'] = name2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot for a specific product. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?productId=&DM_ReqCmd=ProductDisplay.\"
         * @summary Get e-Marketing Sport for product
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} productId The product identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindByNameWithProduct: async (name: string, storeId: string, productId: string, responseFormat?: string, catalogId?: string, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('eSpotFindByNameWithProduct', 'name', name)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eSpotFindByNameWithProduct', 'storeId', storeId)
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('eSpotFindByNameWithProduct', 'productId', productId)
            const localVarPath = `/store/{storeId}/espot/{name}/product/{productId}`
                .replace(`{${"name"}}`, String(name))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"productId"}}`, String(productId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets e-Marketing Spot data.
         * @summary Get e-Marketing Sport data
         * @param {string} storeId The store identifier.
         * @param {string} name E-Spot name.
         * @param {string} type E-Marketing Spot type for example, SEARCH.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindESpotData: async (storeId: string, name: string, type: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eSpotFindESpotData', 'storeId', storeId)
            // verify required parameter 'name' is not null or undefined
            assertParamExists('eSpotFindESpotData', 'name', name)
            // verify required parameter 'type' is not null or undefined
            assertParamExists('eSpotFindESpotData', 'type', type)
            const localVarPath = `/store/{storeId}/espot/{name}/type/{type}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"name"}}`, String(name))
                .replace(`{${"type"}}`, String(type));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (name2 !== undefined) {
                localVarQueryParameter['name'] = name2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets an e-Marketing Spot in a store using its unique identifier.
         * @summary Get e-Marketing Spots by unique identifier
         * @param {string} storeId The store identifier.
         * @param {number} spotId E-Spot identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindSpotByID: async (storeId: string, spotId: number, responseFormat?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('spotFindSpotByID', 'storeId', storeId)
            // verify required parameter 'spotId' is not null or undefined
            assertParamExists('spotFindSpotByID', 'spotId', spotId)
            const localVarPath = `/store/{storeId}/spot/{spotId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"spotId"}}`, String(spotId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets e-Marketing Spots in a store.
         * @summary Get e-Marketing Spots
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [qType] Type of the e-spot. For instance : qType&#x3D;MARKETING. Required for search by type.
         * @param {string} [qName] Name of the e-spot used to find all spots with at least a partial match in the name or description. Required for search by name.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindSpotsByQuery: async (storeId: string, q: string, responseFormat?: string, qType?: string, qName?: string, catalogId?: string, pageSize?: string, pageNumber?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('spotFindSpotsByQuery', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('spotFindSpotsByQuery', 'q', q)
            const localVarPath = `/store/{storeId}/spot`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (qType !== undefined) {
                localVarQueryParameter['qType'] = qType;
            }

            if (qName !== undefined) {
                localVarQueryParameter['qName'] = qName;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot at a specific category level. For example, Furniture. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?categoryId=&DM_ReqCmd=CategoryDisplay.\"
         * @summary Get e-Marketing Spot by category
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} categoryId The category identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog shall be used.
         * @param {string} [currency] The currency code to use. Example usage : currency&#x3D;USD. If no currency code is specified, the store default currency shall be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdEspotNameCategoryCategoryIdGet: async (name: string, storeId: string, categoryId: string, responseFormat?: string, catalogId?: string, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('storeStoreIdEspotNameCategoryCategoryIdGet', 'name', name)
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdEspotNameCategoryCategoryIdGet', 'storeId', storeId)
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('storeStoreIdEspotNameCategoryCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/store/{storeId}/espot/{name}/category/{categoryId}`
                .replace(`{${"name"}}`, String(name))
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"categoryId"}}`, String(categoryId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpotApi - functional programming interface
 * @export
 */
export const SpotApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpotApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets all search terms that have search rules.
         * @summary Get search terms with search rules
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eSpotFindAllSearchTerms(storeId: string, q: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerSearchTermsContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eSpotFindAllSearchTerms(storeId, q, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets an e-Marketing Spot by name.
         * @summary Get e-Marketing Spot by name
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eSpotFindByName(name: string, storeId: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eSpotFindByName(name, storeId, responseFormat, catalogId, currency, name2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot for a specific product. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?productId=&DM_ReqCmd=ProductDisplay.\"
         * @summary Get e-Marketing Sport for product
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} productId The product identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eSpotFindByNameWithProduct(name: string, storeId: string, productId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eSpotFindByNameWithProduct(name, storeId, productId, responseFormat, catalogId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets e-Marketing Spot data.
         * @summary Get e-Marketing Sport data
         * @param {string} storeId The store identifier.
         * @param {string} name E-Spot name.
         * @param {string} type E-Marketing Spot type for example, SEARCH.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eSpotFindESpotData(storeId: string, name: string, type: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EspotEspot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eSpotFindESpotData(storeId, name, type, responseFormat, catalogId, currency, name2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets an e-Marketing Spot in a store using its unique identifier.
         * @summary Get e-Marketing Spots by unique identifier
         * @param {string} storeId The store identifier.
         * @param {number} spotId E-Spot identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotFindSpotByID(storeId: string, spotId: number, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotSpot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotFindSpotByID(storeId, spotId, responseFormat, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets e-Marketing Spots in a store.
         * @summary Get e-Marketing Spots
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [qType] Type of the e-spot. For instance : qType&#x3D;MARKETING. Required for search by type.
         * @param {string} [qName] Name of the e-spot used to find all spots with at least a partial match in the name or description. Required for search by name.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async spotFindSpotsByQuery(storeId: string, q: string, responseFormat?: string, qType?: string, qName?: string, catalogId?: string, pageSize?: string, pageNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SpotSpot>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.spotFindSpotsByQuery(storeId, q, responseFormat, qType, qName, catalogId, pageSize, pageNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot at a specific category level. For example, Furniture. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?categoryId=&DM_ReqCmd=CategoryDisplay.\"
         * @summary Get e-Marketing Spot by category
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} categoryId The category identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog shall be used.
         * @param {string} [currency] The currency code to use. Example usage : currency&#x3D;USD. If no currency code is specified, the store default currency shall be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdEspotNameCategoryCategoryIdGet(name: string, storeId: string, categoryId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdEspotNameCategoryCategoryIdGet(name, storeId, categoryId, responseFormat, catalogId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpotApi - factory interface
 * @export
 */
export const SpotApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpotApiFp(configuration)
    return {
        /**
         * Gets all search terms that have search rules.
         * @summary Get search terms with search rules
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindAllSearchTerms(storeId: string, q: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerSearchTermsContainer> {
            return localVarFp.eSpotFindAllSearchTerms(storeId, q, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets an e-Marketing Spot by name.
         * @summary Get e-Marketing Spot by name
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindByName(name: string, storeId: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any): AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer> {
            return localVarFp.eSpotFindByName(name, storeId, responseFormat, catalogId, currency, name2, options).then((request) => request(axios, basePath));
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot for a specific product. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?productId=&DM_ReqCmd=ProductDisplay.\"
         * @summary Get e-Marketing Sport for product
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} productId The product identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindByNameWithProduct(name: string, storeId: string, productId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any): AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer> {
            return localVarFp.eSpotFindByNameWithProduct(name, storeId, productId, responseFormat, catalogId, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets e-Marketing Spot data.
         * @summary Get e-Marketing Sport data
         * @param {string} storeId The store identifier.
         * @param {string} name E-Spot name.
         * @param {string} type E-Marketing Spot type for example, SEARCH.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {string} [name2] E-Spot name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eSpotFindESpotData(storeId: string, name: string, type: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any): AxiosPromise<EspotEspot> {
            return localVarFp.eSpotFindESpotData(storeId, name, type, responseFormat, catalogId, currency, name2, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets an e-Marketing Spot in a store using its unique identifier.
         * @summary Get e-Marketing Spots by unique identifier
         * @param {string} storeId The store identifier.
         * @param {number} spotId E-Spot identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindSpotByID(storeId: string, spotId: number, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<SpotSpot> {
            return localVarFp.spotFindSpotByID(storeId, spotId, responseFormat, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets e-Marketing Spots in a store.
         * @summary Get e-Marketing Spots
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [qType] Type of the e-spot. For instance : qType&#x3D;MARKETING. Required for search by type.
         * @param {string} [qName] Name of the e-spot used to find all spots with at least a partial match in the name or description. Required for search by name.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        spotFindSpotsByQuery(storeId: string, q: string, responseFormat?: string, qType?: string, qName?: string, catalogId?: string, pageSize?: string, pageNumber?: string, options?: any): AxiosPromise<SpotSpot> {
            return localVarFp.spotFindSpotsByQuery(storeId, q, responseFormat, qType, qName, catalogId, pageSize, pageNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * \"Deprecated: Gets an e-Marketing Spot at a specific category level. For example, Furniture. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?categoryId=&DM_ReqCmd=CategoryDisplay.\"
         * @summary Get e-Marketing Spot by category
         * @param {string} name E-Spot name.
         * @param {string} storeId The store identifier.
         * @param {string} categoryId The category identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog shall be used.
         * @param {string} [currency] The currency code to use. Example usage : currency&#x3D;USD. If no currency code is specified, the store default currency shall be used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdEspotNameCategoryCategoryIdGet(name: string, storeId: string, categoryId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any): AxiosPromise<ComIbmCommerceRestMarketingHandlerESpotDataHandlerESpotContainer> {
            return localVarFp.storeStoreIdEspotNameCategoryCategoryIdGet(name, storeId, categoryId, responseFormat, catalogId, currency, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SpotApi - object-oriented interface
 * @export
 * @class SpotApi
 * @extends {BaseAPI}
 */
export class SpotApi extends BaseAPI {
    /**
     * Gets all search terms that have search rules.
     * @summary Get search terms with search rules
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public eSpotFindAllSearchTerms(storeId: string, q: string, responseFormat?: string, options?: any) {
        return SpotApiFp(this.configuration).eSpotFindAllSearchTerms(storeId, q, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets an e-Marketing Spot by name.
     * @summary Get e-Marketing Spot by name
     * @param {string} name E-Spot name.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {string} [name2] E-Spot name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public eSpotFindByName(name: string, storeId: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any) {
        return SpotApiFp(this.configuration).eSpotFindByName(name, storeId, responseFormat, catalogId, currency, name2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \"Deprecated: Gets an e-Marketing Spot for a specific product. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?productId=&DM_ReqCmd=ProductDisplay.\"
     * @summary Get e-Marketing Sport for product
     * @param {string} name E-Spot name.
     * @param {string} storeId The store identifier.
     * @param {string} productId The product identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public eSpotFindByNameWithProduct(name: string, storeId: string, productId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any) {
        return SpotApiFp(this.configuration).eSpotFindByNameWithProduct(name, storeId, productId, responseFormat, catalogId, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets e-Marketing Spot data.
     * @summary Get e-Marketing Sport data
     * @param {string} storeId The store identifier.
     * @param {string} name E-Spot name.
     * @param {string} type E-Marketing Spot type for example, SEARCH.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {string} [name2] E-Spot name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public eSpotFindESpotData(storeId: string, name: string, type: string, responseFormat?: string, catalogId?: string, currency?: string, name2?: string, options?: any) {
        return SpotApiFp(this.configuration).eSpotFindESpotData(storeId, name, type, responseFormat, catalogId, currency, name2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets an e-Marketing Spot in a store using its unique identifier.
     * @summary Get e-Marketing Spots by unique identifier
     * @param {string} storeId The store identifier.
     * @param {number} spotId E-Spot identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public spotFindSpotByID(storeId: string, spotId: number, responseFormat?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return SpotApiFp(this.configuration).spotFindSpotByID(storeId, spotId, responseFormat, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets e-Marketing Spots in a store.
     * @summary Get e-Marketing Spots
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [qType] Type of the e-spot. For instance : qType&#x3D;MARKETING. Required for search by type.
     * @param {string} [qName] Name of the e-spot used to find all spots with at least a partial match in the name or description. Required for search by name.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
     * @param {string} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public spotFindSpotsByQuery(storeId: string, q: string, responseFormat?: string, qType?: string, qName?: string, catalogId?: string, pageSize?: string, pageNumber?: string, options?: any) {
        return SpotApiFp(this.configuration).spotFindSpotsByQuery(storeId, q, responseFormat, qType, qName, catalogId, pageSize, pageNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * \"Deprecated: Gets an e-Marketing Spot at a specific category level. For example, Furniture. Alternatively, this can be implemented using the findByName method with the following URL: store/{storeId}/espot/{name}?categoryId=&DM_ReqCmd=CategoryDisplay.\"
     * @summary Get e-Marketing Spot by category
     * @param {string} name E-Spot name.
     * @param {string} storeId The store identifier.
     * @param {string} categoryId The category identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog shall be used.
     * @param {string} [currency] The currency code to use. Example usage : currency&#x3D;USD. If no currency code is specified, the store default currency shall be used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpotApi
     */
    public storeStoreIdEspotNameCategoryCategoryIdGet(name: string, storeId: string, categoryId: string, responseFormat?: string, catalogId?: string, currency?: string, options?: any) {
        return SpotApiFp(this.configuration).storeStoreIdEspotNameCategoryCategoryIdGet(name, storeId, categoryId, responseFormat, catalogId, currency, options).then((request) => request(this.axios, this.basePath));
    }
}
