/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CouponCoupon } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CouponApi - axios parameter creator
 * @export
 */
export const CouponApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes coupons from coupon wallet by coupon ID.
         * @summary Delete coupons by ID
         * @param {string} storeId The store identifier.
         * @param {string} couponId Coupon identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponDeleteCouponById: async (storeId: string, couponId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('couponDeleteCouponById', 'storeId', storeId)
            // verify required parameter 'couponId' is not null or undefined
            assertParamExists('couponDeleteCouponById', 'couponId', couponId)
            const localVarPath = `/store/{storeId}/coupon/{couponId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"couponId"}}`, String(couponId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets available coupons for the current shopper.
         * @summary Get coupons for shopper
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponGetCoupon: async (storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('couponGetCoupon', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/coupon/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Allows the shopper to get their own coupons.
         * @summary Get coupons
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [body] Issue coupon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponIssueCoupon: async (storeId: string, responseFormat?: string, body?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('couponIssueCoupon', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/coupon/@self`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CouponApi - functional programming interface
 * @export
 */
export const CouponApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CouponApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes coupons from coupon wallet by coupon ID.
         * @summary Delete coupons by ID
         * @param {string} storeId The store identifier.
         * @param {string} couponId Coupon identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponDeleteCouponById(storeId: string, couponId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponDeleteCouponById(storeId, couponId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets available coupons for the current shopper.
         * @summary Get coupons for shopper
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponGetCoupon(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CouponCoupon>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponGetCoupon(storeId, responseFormat, pageNumber, pageSize, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Allows the shopper to get their own coupons.
         * @summary Get coupons
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [body] Issue coupon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async couponIssueCoupon(storeId: string, responseFormat?: string, body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.couponIssueCoupon(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CouponApi - factory interface
 * @export
 */
export const CouponApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CouponApiFp(configuration)
    return {
        /**
         * Deletes coupons from coupon wallet by coupon ID.
         * @summary Delete coupons by ID
         * @param {string} storeId The store identifier.
         * @param {string} couponId Coupon identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponDeleteCouponById(storeId: string, couponId: string, responseFormat?: string, options?: any): AxiosPromise<object> {
            return localVarFp.couponDeleteCouponById(storeId, couponId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets available coupons for the current shopper.
         * @summary Get coupons for shopper
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponGetCoupon(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any): AxiosPromise<CouponCoupon> {
            return localVarFp.couponGetCoupon(storeId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Allows the shopper to get their own coupons.
         * @summary Get coupons
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [body] Issue coupon.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        couponIssueCoupon(storeId: string, responseFormat?: string, body?: string, options?: any): AxiosPromise<object> {
            return localVarFp.couponIssueCoupon(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CouponApi - object-oriented interface
 * @export
 * @class CouponApi
 * @extends {BaseAPI}
 */
export class CouponApi extends BaseAPI {
    /**
     * Deletes coupons from coupon wallet by coupon ID.
     * @summary Delete coupons by ID
     * @param {string} storeId The store identifier.
     * @param {string} couponId Coupon identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponDeleteCouponById(storeId: string, couponId: string, responseFormat?: string, options?: any) {
        return CouponApiFp(this.configuration).couponDeleteCouponById(storeId, couponId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets available coupons for the current shopper.
     * @summary Get coupons for shopper
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponGetCoupon(storeId: string, responseFormat?: string, pageNumber?: number, pageSize?: number, currency?: string, options?: any) {
        return CouponApiFp(this.configuration).couponGetCoupon(storeId, responseFormat, pageNumber, pageSize, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Allows the shopper to get their own coupons.
     * @summary Get coupons
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [body] Issue coupon.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CouponApi
     */
    public couponIssueCoupon(storeId: string, responseFormat?: string, body?: string, options?: any) {
        return CouponApiFp(this.configuration).couponIssueCoupon(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
