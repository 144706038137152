/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
export const KEYWORDS_UPDATED = "KEYWORDS_UPDATED";

export const KEYWORDS_RESET = "KEYWORDS_RESET";

export const HISTORY_UPDATED = "HISTORY_UPDATED";
export const HISTORY_RESET = "HISTORY_RESET";
