/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OK } from "http-status-codes";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import getDisplayName from "react-display-name";
//Foundation libraries
import { useSite } from "../../../_foundation/hooks/useSite";
import siteContentService from "../../../_foundation/apis/search/siteContent.service";
import searchDisplayService from "../../../_foundation/apis/transaction/searchDisplay.service";
//Custom libraries
import { CommerceEnvironment, KEY_CODES, SEARCHTERM } from "../../../constants/common";
import { SEARCH } from "../../../constants/routes";
import { KEYWORD_LIMIT } from "../../../configs/catalog";
//Redux
import { currentContractIdSelector } from "../../../redux/selectors/contract";
import * as searchActions from "../../../redux/actions/search";
import { resetProductListAction } from "../../../redux/actions/catalog";
//UI
import {
  StyledTextField,
  StyledIconButton,
  StyledMenuItem,
  StyledSearchBar,
  StyledMenuTypography,
  StyledLink,
} from "@hcl-commerce-store-sdk/react-component";
import { InputAdornment, ClickAwayListener, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { KeywordSuggestion } from "solteq/hcl-interfaces/storefront/models/KeywordSuggestion";
import { ProductSuggestion } from "solteq/hcl-interfaces/storefront/models/ProductSuggestion";
import useDebounce from "solteq/src/hooks/useDebounce";
import { historySelector } from "redux/selectors/search";
import { AxiosResponse, Canceler } from "axios";
import Axios from "axios";

type SuggestionSkeleton = typeof CommerceEnvironment.suggestionSkeleton;

const SearchBar: React.FC<SearchBarProps> = ({ showSearchBar, openSearchBar, closeSearchBar }) => {
  const widgetName = getDisplayName(SearchBar);
  const contractId = useSelector(currentContractIdSelector);
  const [keywordSuggestions, setKeywordSuggestions] = React.useState<Array<Object>>([]);
  const [categorySuggestions, setCategorySuggestions] = React.useState<Array<Object>>([]);
  const [brandSuggestions, setBrandSuggestions] = React.useState<Array<Object>>([]);
  const [productSuggestions, setProductSuggestions] = React.useState<Array<SuggestionSkeleton>>([]);
  const [historySuggestions, setHistorySuggestions] = React.useState<Array<string>>([]);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const location: any = useLocation();

  const searchHistory = useSelector(historySelector);

  const searchField = t("SearchBar.SearchField");
  const keywordTitle = t("SearchBar.KeywordTitle");
  const categoryTitle = t("SearchBar.CategoryTitle");
  const brandTitle = t("SearchBar.BrandTitle");
  const productTitle = t("SearchBar.ProductField");

  const [input, setInput] = React.useState("");
  const [searchInput, setSearchInput] = React.useState("");
  const [nameList, setNameList] = React.useState<Array<string>>([]);
  const [index, setIndex] = React.useState(0);
  let nameListIndex = 1;
  const { mySite } = useSite();
  const dispatch = useDispatch();

  const [showKeywords, setShowKeywords] = React.useState(false);
  const [showCategories, setShowCategories] = React.useState(false);
  const [showBrands, setShowBrands] = React.useState(false);
  const [showProducts, setShowProducts] = React.useState(false);

  const debouncedInput = useDebounce(searchInput, 350);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [categories, setCategories] = React.useState<Array<string>>([]);
  const [brands, setBrands] = React.useState<Array<string>>([]);
  const [categoriesUrl, setCategoriesUrl] = React.useState<Map<any, any>>(() => new Map());

  const [inputDisabled, setinputDisabled] = React.useState(true);

  const clearSuggestions = () => {
    setIndex(0);
    setKeywordSuggestions([]);
    setCategorySuggestions([]);
    setProductSuggestions([]);
    setBrandSuggestions([]);
    setHistorySuggestions([]);
    setShowKeywords(false);
    setShowCategories(false);
    setShowBrands(false);
    setShowProducts(false);
  };

  const clearSuggestionsAndUpdateInputField = (str: string) => {
    clearSuggestions();
    str = callRegex(str);
    setInput(str);
    setSearchInput("");
    setShowSearchBar(!showSearchBar);
    dispatch(resetProductListAction());
  };

  const clearSuggestionsAndInputField = () => {
    clearKeywords();
    clearSuggestions();
    setInput("");
    setSearchInput("");
  };

  const clearKeywords = () => {
    dispatch(searchActions.KEYWORDS_RESET_ACTION(""));
  };

  const setKeywordsToLocalStorage = (list: string[]) => {
    dispatch(searchActions.KEYWORDS_UPDATED_ACTION(list));
  };

  const setToHistory = useCallback(
    (searchTerm: string) => {
      const items = [searchTerm];

      if (searchHistory?.length > 0) {
        /* Add search term first in the results, limit the amount and filter out any doubles */
        items.push(
          ...searchHistory.slice(0, 100).filter((s) => s.toLocaleLowerCase() !== searchTerm.toLocaleLowerCase())
        );
      }

      dispatch(searchActions.HISTORY_UPDATED_ACTION(items));
    },
    [dispatch, searchHistory]
  );

  const CancelToken = Axios.CancelToken;
  const cancels: Canceler[] = [];

  const payloadBase: any = {
    widget: widgetName,
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c);
    }),
  };

  // Perform search with debounce
  useEffect(() => {
    if (debouncedInput) {
      retrieveSuggestions(debouncedInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedInput]);

  useEffect(() => {
    if (mySite && contractId) {
      const catalogId = mySite?.catalogID;
      const parameters: any = {
        responseFormat: "application/json",
        suggestType: ["Category", "Brand"],

        contractId: contractId,
        catalogId: catalogId,
        ...payloadBase,
      };
      siteContentService
        .findSuggestionsUsingGET(parameters)
        .then((res) => {
          if (res.status === OK) {
            const categoriesResponse = res?.data.suggestionView[0].entry;
            const brandsResponse = res?.data.suggestionView[1].entry;
            generateCategoriesList(categoriesResponse);
            generateCategoriesURL(categoriesResponse);
            generateBrandsList(brandsResponse);
            setinputDisabled(false);
          }
        })
        .catch((e) => {
          console.warn("fail to get category and brand suggestions.");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySite, t, contractId]);

  useEffect(() => {
    const queryString = location.search;
    const params = new URLSearchParams(queryString);
    const searchTermValue = params.get(SEARCHTERM);
    if (searchTermValue === null) {
      if (input.length > 0) {
        setInput("");
      }
      if (searchInput.length > 0) {
        setSearchInput("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    return () => {
      cancels.forEach((cancel) => cancel());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCategoriesList = (categoriesResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < categoriesResponse.length; i++) {
      lists.push(categoriesResponse[i].fullPath);
    }
    setCategories(lists);
  };
  const generateBrandsList = (brandsResponse: any[]) => {
    const lists: string[] = [];
    for (let i = 0; i < brandsResponse.length; i++) {
      lists.push(brandsResponse[i].name);
    }
    setBrands(lists);
  };

  const generateCategoriesURL = (categoriesResponse: any[]) => {
    const categoriesUrl = new Map();
    for (let i = 0; i < categoriesResponse.length; i++) {
      const url = categoriesResponse[i].seo ? categoriesResponse[i].seo.href : "";
      categoriesUrl.set(categoriesResponse[i].fullPath, url);
    }
    setCategoriesUrl(categoriesUrl);
  };

  const handleLookAheadSearch = (event: ChangeEvent, type: string) => {
    event.persist();

    const element = event.currentTarget as HTMLInputElement;
    setInput(element.value);
    setSearchInput(element.value);
  };

  const retrieveSuggestions = (searchTerm: any) => {
    searchTerm = searchTerm.trim();
    if (searchTerm.length > 1) {
      setTimeout(function () {
        const storeID = mySite.storeID;
        const catalogId = mySite.catalogID;

        const parameters: any = {
          responseFormat: "application/json",
          storeId: storeID,
          term: searchTerm,
          suggestType: ["Keyword", "Product"],
          limit: KEYWORD_LIMIT,
          contractId: contractId,
          catalogId: catalogId,
          ...payloadBase,
        };

        Promise.all([
          siteContentService.findKeywordSuggestionsByTermUsingGET(parameters),
          siteContentService.findProductSuggestionsByTermUsingGET({
            ...parameters,
            profileName: "X_findNavigationSuggestion_Products", // To get seo urls
          }),
        ]).then((res: [AxiosResponse<KeywordSuggestion>, AxiosResponse<ProductSuggestion>]) => {
          let list: string[] = [];

          const keywordResults = res[0];
          const productResults = res[1];

          // Handle keyword suggestions first (will be shown first in suggestions)
          if (keywordResults.status === OK) {
            if (keywordResults.data.suggestionView && keywordResults.data.suggestionView.length > 0) {
              const keywordSuggestions = keywordResults.data.suggestionView.find((x) => x.identifier === "Keyword");
              if (keywordSuggestions && keywordSuggestions.entry) {
                list.push(...generateSuggestionList(keywordSuggestions.entry, searchTerm));
              }
            }
          }

          // Handle product suggestions next.
          if (
            productResults.status === OK &&
            productResults.data.recordSetTotal &&
            productResults.data.recordSetTotal > 0
          ) {
            if (productResults.data.suggestionView && productResults.data.suggestionView.length > 0) {
              const productSuggestions = productResults.data.suggestionView.find((x) => x.identifier === "Product");
              if (productSuggestions && productSuggestions.entry) {
                // Let's limit the suggestions this way, since we can't pass pageLimit parameter.
                list.push(...generateProductSuggestionList(productSuggestions.entry.slice(0, KEYWORD_LIMIT)));
              }
            }
          } else {
            setProductSuggestions([]);
            setShowProducts(true);
          }

          // Then, handle category and brand suggestions
          list.push(...generateCatgoriesAndBrandsSuggestions(searchTerm));

          // Finally, handle suggestions from search history
          list.push(...generateHistorySuggestionList(searchTerm));

          setNameList(list);
        });
      }, 300);
    }
    clearKeywords();
    clearSuggestions();
  };

  const parseSuggestionsSkeleton = (name: string, url: string): SuggestionSkeleton => {
    return {
      ...CommerceEnvironment.suggestionSkeleton,
      arrIndex: `${nameListIndex}`,
      id: "",
      name,
      url,
    };
  };

  const generateCatgoriesAndBrandsSuggestions = (userInput: string): string[] => {
    const regex = new RegExp(userInput, "ig");
    const matchedCategories = categories?.filter((e) => e.match(regex));
    let lists: object[] = [];
    const namesList: string[] = [];

    if (matchedCategories) {
      for (const suggestion of matchedCategories) {
        if (lists.length === 4) {
          break;
        }
        lists.push(parseSuggestionsSkeleton(suggestion, categoriesUrl.get(suggestion)));
        namesList.push(suggestion);
        nameListIndex++;
      }
    }
    setCategorySuggestions(lists);
    setShowCategories(true);
    const matchedBrands = brands?.filter((e) => e.match(regex));
    const lists2: object[] = [];
    if (matchedBrands) {
      for (const suggestion of matchedBrands) {
        if (lists2.length === 4) {
          break;
        }

        lists2.push(parseSuggestionsSkeleton(suggestion, SEARCH + "?" + SEARCHTERM + "=" + suggestion));

        namesList.push(suggestion);
        nameListIndex++;
      }
    }
    setBrandSuggestions(lists2);
    setShowBrands(true);

    return namesList;
  };

  const generateSuggestionList = (keywordSuggestions: any[], userInput: string): string[] => {
    const lists: SuggestionSkeleton[] = [];
    const namesList: string[] = [];

    namesList.push(userInput);
    const listTemp2: string[] = [];

    for (const suggestion of keywordSuggestions) {
      if (keywordSuggestions) {
        namesList.push(suggestion.term);
        lists.push(parseSuggestionsSkeleton(suggestion.term, SEARCH + "?" + SEARCHTERM + "=" + suggestion.term));
        listTemp2.push(suggestion.term);
        nameListIndex++;
      }
    }
    setKeywordSuggestions(lists);
    setKeywordsToLocalStorage(listTemp2);
    setShowKeywords(true);

    return namesList;
  };

  const generateProductSuggestionList = (products: any[]): string[] => {
    const lists: SuggestionSkeleton[] = [];
    const namesList: string[] = [];

    for (let suggestion of products) {
      if (suggestion) {
        const name = suggestion.name;
        const url = (suggestion.seo && suggestion.seo?.href) ?? SEARCH + "?" + SEARCHTERM + "=" + name;

        namesList.push(name);
        lists.push(parseSuggestionsSkeleton(name, url));
        nameListIndex++;
      }
    }
    setProductSuggestions(lists);
    setShowProducts(true);

    return namesList;
  };

  const generateHistorySuggestionList = (userInput: string) => {
    if (searchHistory?.length > 0) {
      const items = [
        ...searchHistory
          .filter(
            (s) =>
              userInput.toLocaleLowerCase().includes(s.toLocaleLowerCase()) ||
              s.toLocaleLowerCase().includes(userInput.toLocaleLowerCase())
          )
          .slice(0, 5), // Limit the shown suggestions
      ];

      setHistorySuggestions(items);
      return items;
    }

    return [];
  };

  const callRegex = (str: string) => {
    const regex2 = new RegExp(">", "ig");
    let arr: string[];
    if (str.match(regex2)) {
      arr = str.split(">");
      str = arr[arr.length - 1].trim();
    }
    return str;
  };
  const onKeyDown = (e) => {
    const len = nameList ? nameList.length : 0;
    let str = "";
    if (e.keyCode === KEY_CODES.UP) {
      e.preventDefault();

      if (index === 0) {
        return;
      }
      setIndex(index - 1);
      if (nameList) {
        str = callRegex(nameList[index - 1]);
        setInput(str);
      }
    } else if (e.keyCode === KEY_CODES.DOWN) {
      e.preventDefault();

      if (index === len - 1) {
        setIndex(0);
        if (nameList) {
          str = callRegex(nameList[0]);
          setInput(str);
        }
        return;
      }
      setIndex(index + 1);
      if (nameList) {
        str = callRegex(nameList[index + 1]);
        setInput(str);
      }
    }
  };

  const submitSearch = (props: any) => {
    props.preventDefault();
    clearSuggestions();

    if (input && input.trim() !== "") {
      let url = "";
      const storeID = mySite.storeID;
      const searchTerm = input.trim();

      // Set the input to search history
      setToHistory(searchTerm);

      const parameters: any = {
        storeId: storeID,
        searchTerm: searchTerm,
        ...payloadBase,
      };
      searchDisplayService
        .getSearchDisplayView(parameters)
        .then((res) => {
          if (res.status === OK) {
            url = res?.data.redirecturl;

            if (url === undefined) {
              url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
            }
            redirectTo(url);
          }
        })
        .catch((e) => {
          url = SEARCH + "?" + SEARCHTERM + "=" + searchTerm;
          redirectTo(url);
        });
    }
  };

  const redirectTo = (url: string) => {
    clearSuggestions();
    setShowSearchBar(false);
    //redirect
    if (url.startsWith("http")) {
      window.location.href = url;
    } else {
      navigate(url);
    }
  };

  const clickAway = (prev) => {
    setShowSearchBar(!prev);
  };

  const setShowSearchBar = (boolean) => {
    if (boolean) {
      openSearchBar();
    } else {
      closeSearchBar();
    }
  };

  const toggleSearchBar = () => setShowSearchBar(!showSearchBar);

  return (
    <ClickAwayListener onClickAway={clickAway}>
      <StyledSearchBar>
        <form onSubmit={submitSearch} noValidate>
          <StyledTextField
            margin="normal"
            size="small"
            autoFocus
            autoComplete="off"
            type="text"
            disabled={inputDisabled}
            placeholder={searchField}
            value={input}
            style={{ width: isMobile ? "100%" : "18vw" }}
            name="searchTerm"
            onChange={(e) => handleLookAheadSearch(e, "searchTerm")}
            onKeyDown={onKeyDown}
            InputProps={{
              endAdornment: (
                <>
                  {showKeywords || showProducts || showCategories || showBrands || historySuggestions.length > 0 ? (
                    <InputAdornment position="end">
                      <StyledIconButton onClick={clearSuggestionsAndInputField}>
                        <CloseIcon titleAccess={t("SearchBar.Clear")} />
                      </StyledIconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="start">
                      <SearchIcon onClick={toggleSearchBar} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        </form>

        {(showKeywords || showProducts || showCategories || showBrands || historySuggestions.length > 0) && (
          <ClickAwayListener
            onClickAway={() => {
              clearSuggestionsAndInputField();
            }}>
            <ul className="searchBar-results">
              {showKeywords && (
                <>
                  <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                    {keywordTitle}
                  </StyledMenuTypography>
                  {keywordSuggestions?.map((e: any, i: number) => (
                    <StyledLink
                      key={`brand-${i}`}
                      to={e.url}
                      onClick={() => clearSuggestionsAndUpdateInputField(e.name)}>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={e.arrIndex === index ? "active" : ""}
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </StyledLink>
                  ))}
                </>
              )}

              {showProducts && (
                <>
                  <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                    {productTitle}
                  </StyledMenuTypography>
                  {productSuggestions?.map((e: SuggestionSkeleton, i: number) => (
                    <StyledLink
                      key={`product-${i}`}
                      to={e.url}
                      onClick={() => clearSuggestionsAndUpdateInputField(e.name)}>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={Number(e.arrIndex) === index ? "active" : ""}
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </StyledLink>
                  ))}
                </>
              )}

              {showCategories && (
                <>
                  <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                    {categoryTitle}
                  </StyledMenuTypography>
                  {categorySuggestions?.map((e: any, i: number) => (
                    <StyledLink
                      key={`category-${i}`}
                      to={e.url}
                      onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={e.arrIndex === index ? "active" : ""}
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </StyledLink>
                  ))}
                </>
              )}

              {showBrands && (
                <>
                  <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                    {brandTitle}
                  </StyledMenuTypography>
                  {brandSuggestions?.map((e: any, i: number) => (
                    <StyledLink
                      key={`brand-${i}`}
                      to={e.url}
                      onClick={(evt) => clearSuggestionsAndUpdateInputField(e.name)}>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          className={e.arrIndex === index ? "active" : ""}
                          key={e.arrIndex}
                          id={`megamenu_department_${e.id}`}
                          title={e.name}>
                          {e.name}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </StyledLink>
                  ))}
                </>
              )}
              {historySuggestions.length > 0 && (
                <>
                  <StyledMenuTypography variant="body2" className="searchBar-resultsCategory">
                    {t("SearchBar.HistoryTitle")}
                  </StyledMenuTypography>
                  {historySuggestions.map((e: string, i: number) => (
                    <StyledLink
                      key={`searchHistory-${i}`}
                      to={`/search?searchTerm=${e}`}
                      onClick={() => clearSuggestionsAndUpdateInputField(e)}>
                      <StyledMenuItem>
                        <StyledMenuTypography
                          variant="body1"
                          key={`searchHistory_suggestion_${i}`}
                          id={`searchHistory_suggestion_${i}`}
                          title={e}>
                          {e}
                        </StyledMenuTypography>
                      </StyledMenuItem>
                    </StyledLink>
                  ))}
                </>
              )}
            </ul>
          </ClickAwayListener>
        )}
      </StyledSearchBar>
    </ClickAwayListener>
  );
};

interface SearchBarProps {
  showSearchBar: boolean;
  openSearchBar: any;
  closeSearchBar: any;
}

export { SearchBar };
