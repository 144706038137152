/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * How the contract is created.
 * @export
 * @enum {string}
 */
export enum OriginType {
    Rfq = 'RFQ',
    Manual = 'Manual',
    Imported = 'Imported',
    _2Party = '2-Party',
    Auction = 'Auction',
    Exchange = 'Exchange',
    Deployment = 'Deployment'
}



