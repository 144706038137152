/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { CartAssignedPromotionCode } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeResponse } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerRemovePromotionCodeResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * AssignedPromotionCodeApi - axios parameter creator
 * @export
 */
export const AssignedPromotionCodeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Gets assigned promotion codes for the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedPromotioncodeInfo: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('getAssignedPromotioncodeInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/cart/@self/assigned_promotion_code`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Applies promotion codes to the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody} comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody The request body for applying promotion codes to the shopping cart.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfAssignedPromotionCodePost: async (storeId: string, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody: ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdCartSelfAssignedPromotionCodePost', 'storeId', storeId)
            // verify required parameter 'comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody' is not null or undefined
            assertParamExists('storeStoreIdCartSelfAssignedPromotionCodePost', 'comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody', comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody)
            const localVarPath = `/store/{storeId}/cart/@self/assigned_promotion_code`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Removes promotion codes from the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} promoCode The promotion code.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete: async (storeId: string, promoCode: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete', 'storeId', storeId)
            // verify required parameter 'promoCode' is not null or undefined
            assertParamExists('storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete', 'promoCode', promoCode)
            const localVarPath = `/store/{storeId}/cart/@self/assigned_promotion_code/{promoCode}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"promoCode"}}`, String(promoCode));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssignedPromotionCodeApi - functional programming interface
 * @export
 */
export const AssignedPromotionCodeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssignedPromotionCodeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Gets assigned promotion codes for the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAssignedPromotioncodeInfo(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CartAssignedPromotionCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAssignedPromotioncodeInfo(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Applies promotion codes to the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody} comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody The request body for applying promotion codes to the shopping cart.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdCartSelfAssignedPromotionCodePost(storeId: string, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody: ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdCartSelfAssignedPromotionCodePost(storeId, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Removes promotion codes from the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} promoCode The promotion code.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId: string, promoCode: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerRemovePromotionCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId, promoCode, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AssignedPromotionCodeApi - factory interface
 * @export
 */
export const AssignedPromotionCodeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssignedPromotionCodeApiFp(configuration)
    return {
        /**
         * 
         * @summary Gets assigned promotion codes for the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssignedPromotioncodeInfo(storeId: string, responseFormat?: string, options?: any): AxiosPromise<CartAssignedPromotionCode> {
            return localVarFp.getAssignedPromotioncodeInfo(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Applies promotion codes to the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody} comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody The request body for applying promotion codes to the shopping cart.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfAssignedPromotionCodePost(storeId: string, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody: ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeResponse> {
            return localVarFp.storeStoreIdCartSelfAssignedPromotionCodePost(storeId, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Removes promotion codes from the shopping cart.
         * @param {string} storeId The store identifier.
         * @param {string} promoCode The promotion code.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId: string, promoCode: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerRemovePromotionCodeResponse> {
            return localVarFp.storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId, promoCode, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssignedPromotionCodeApi - object-oriented interface
 * @export
 * @class AssignedPromotionCodeApi
 * @extends {BaseAPI}
 */
export class AssignedPromotionCodeApi extends BaseAPI {
    /**
     * 
     * @summary Gets assigned promotion codes for the shopping cart.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedPromotionCodeApi
     */
    public getAssignedPromotioncodeInfo(storeId: string, responseFormat?: string, options?: any) {
        return AssignedPromotionCodeApiFp(this.configuration).getAssignedPromotioncodeInfo(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Applies promotion codes to the shopping cart.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody} comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody The request body for applying promotion codes to the shopping cart.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include \&quot;json\&quot; and \&quot;xml\&quot; without the quotes. If the responseFormat isn\&#39;t specified, the \&quot;accept\&quot; HTTP header shall be used to determine the format of the response. If the \&quot;accept\&quot; HTTP header isn\&#39;t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedPromotionCodeApi
     */
    public storeStoreIdCartSelfAssignedPromotionCodePost(storeId: string, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody: ComIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat?: string, options?: any) {
        return AssignedPromotionCodeApiFp(this.configuration).storeStoreIdCartSelfAssignedPromotionCodePost(storeId, comIbmCommerceRestOrderHandlerAssignedPromotionCodeHandlerApplyPromotionCodeBody, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Removes promotion codes from the shopping cart.
     * @param {string} storeId The store identifier.
     * @param {string} promoCode The promotion code.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssignedPromotionCodeApi
     */
    public storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId: string, promoCode: string, responseFormat?: string, options?: any) {
        return AssignedPromotionCodeApiFp(this.configuration).storeStoreIdCartSelfAssignedPromotionCodePromoCodeDelete(storeId, promoCode, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
