/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
//Standard libraries
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
//UI
import { StyledBox, StyledContainer } from "@hcl-commerce-store-sdk/react-component";
//custom
import MenubarMenuItem from "./MenubarMenuItem";
import { useSite } from "_foundation/hooks/useSite";
import { useSelector } from "react-redux";
import { loginStatusSelector } from "redux/selectors/user";
import { CATEGORY_IDENTIFIERS } from "solteq/src/constants/common";

interface ExpandedMenuProps {
  pages?: any[];
}

/**
 * ExpandedMenu component
 * expanded menu for desktop/tablet view
 * @param props
 */
const ExpandedMenu: React.FC<ExpandedMenuProps> = (props: ExpandedMenuProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { userData } = useSite();
  const loggedIn = useSelector(loginStatusSelector);
  const navigate = useNavigate();

  const [selectedMenuItem, setSelectedMenuItem] = useState<any | null>(null);

  const pages = useMemo(() => {
    if (props.pages) {
      // Don't show presale category when not logged in
      if (!loggedIn) {
        return props.pages.filter((p) => p.identifier !== CATEGORY_IDENTIFIERS.PRESALE);
      }

      return props.pages;
    }

    return [];
  }, [props.pages, loggedIn]);

  const allCategories = useMemo(() => {
    return {
      id: "allCategories",
      children: pages.filter(
        (p) => !(p.identifier === CATEGORY_IDENTIFIERS.SALES_BRANDS || p.identifier === CATEGORY_IDENTIFIERS.PRESALE)
      ),
      name: t("AllCategoriesExpandedMenu.AllCategoriesLabel"),
    };
  }, [pages, t]);

  const selectMenuItem = (cid: string) => {
    setSelectedMenuItem(categories.filter((c) => c.id === cid)[0] || null);
  };

  const brandCategories = useMemo(() => {
    const brandCategory = pages.find((p) => p.identifier === CATEGORY_IDENTIFIERS.SALES_BRANDS);

    if (brandCategory) {
      return {
        id: CATEGORY_IDENTIFIERS.SALES_BRANDS,
        children: brandCategory.children,
        name: brandCategory.name,
        onMenuClick: () => {
          if (brandCategory.seo?.href) {
            navigate(brandCategory.seo.href);
          }
        },
      };
    }

    return {};
  }, [pages, navigate]);

  const categories = useMemo(() => {
    // SQSTRCOM-447
    if (userData && userData.categoryBarTopCategories) {
      const categories = (userData.categoryBarTopCategories as string).split(",");

      return [allCategories, ...pages.filter((c) => categories.some((x) => x === c.identifier)), brandCategories];
    } else {
      return [allCategories, ...pages];
    }
  }, [allCategories, pages, userData, brandCategories]);

  React.useEffect(() => {
    setSelectedMenuItem(null);
  }, [location]);

  return (
    <StyledBox className="expanded-menu-container">
      <StyledContainer overflow="hidden">
        <StyledBox
          display="flex"
          direction="row"
          flexWrap="nowrap"
          justifyContent="flex-start"
          alignContent="flex-start">
          {categories?.map((page: any) => (
            <MenubarMenuItem
              key={page.id}
              selectedMenuItem={selectedMenuItem}
              selectMenuItem={selectMenuItem}
              onMenuClick={page.onMenuClick}
              page={page}></MenubarMenuItem>
          ))}
        </StyledBox>
      </StyledContainer>
    </StyledBox>
  );
};

export default ExpandedMenu;
