import { StyledMenuTypography } from "@hcl-commerce-store-sdk/react-component";
import { SUB_CATEGORIES_LIMIT } from "configs/catalog";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

/** A component that shows a link to the main category landing page if the category has more children than can be shown */
export const SolteqShowMoreCategoriesLink = ({ page }) => {
  const { t } = useTranslation();

  return (
    <>
      {page.children?.length > SUB_CATEGORIES_LIMIT && (
        <Link
          key={`ShowMoreLink_` + page.id}
          to={page.seo?.href}
          state={{
            breadCrumbTrailEntryView: [
              {
                label: page.name,
                value: page.id,
                seo: page.seo,
              },
            ],
          }}>
          <StyledMenuTypography variant="body2" className="expanded-menu-sub-links show-more">
            {t("Header.Navigation.ShowMore")}
          </StyledMenuTypography>
        </Link>
      )}
    </>
  );
};
