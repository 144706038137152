/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020, 2021
 *
 *==================================================
 */
import { emeraldTheme as Emerald, sapphireTheme as Sapphire } from "@hcl-commerce-store-sdk/react-component";
import { createTheme } from "@mui/material/styles";
import createPalette from "@mui/material/styles/createPalette";
import createTypography from "@mui/material/styles/createTypography";

export const TITLEFONT: string = "Yanone Kaffeesatz";
export const BODYFONT: string = "Rubik";
const MAINCOLOR: string = "#2D9FCF";
const SECONDARYCOLOR: string = "#26348B";

const themes = { Emerald, Sapphire };
let theme = themes[process.env.REACT_APP_STORENAME || "Sapphire"] || Sapphire;

const CurrentTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: MAINCOLOR,
    },
    secondary: {
      main: SECONDARYCOLOR,
    },
  },
  typography: createTypography(createPalette({}), {
    ...theme.typography,
    body1: {
      ...theme.typography.body1,
      fontFamily: BODYFONT,
    },
    body2: {
      ...theme.typography.body2,
      fontFamily: BODYFONT,
    },
    caption: {
      ...theme.typography.caption,
      fontFamily: BODYFONT,
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      fontFamily: TITLEFONT,
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      fontFamily: BODYFONT,
    },
    h1: {
      ...theme.typography.h1,
      fontFamily: TITLEFONT,
    },
    h2: {
      ...theme.typography.h2,
      fontFamily: TITLEFONT,
    },
    h3: {
      ...theme.typography.h3,
      fontFamily: TITLEFONT,
    },
    h4: {
      ...theme.typography.h4,
      fontFamily: TITLEFONT,
    },
    h5: {
      ...theme.typography.h5,
      fontFamily: TITLEFONT,
    },
    h6: {
      ...theme.typography.h6,
      fontFamily: TITLEFONT,
    },
  }),
});

export { CurrentTheme };
