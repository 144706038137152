/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Services - Account and Contract
 * These services provide APIs to manage accounts and contracts.  a contract is an agreement that represents the terms and conditions that apply to a transaction. An account is a relationship between the merchant and the financial institution that processes transactions for that merchant.
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerCheckoutProfileIdContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerUserIdContainer } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PersonCheckoutProfile } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PersonCheckoutProfileUpdate } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * CheckoutProfileApi - axios parameter creator
 * @export
 */
export const CheckoutProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Create a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [personCheckoutProfileUpdate] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileCreateCheckoutProfile: async (storeId: string, responseFormat?: string, personCheckoutProfileUpdate?: PersonCheckoutProfileUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileCreateCheckoutProfile', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/checkoutProfile`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(personCheckoutProfileUpdate, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Delete a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileDeleteCheckoutProfile: async (storeId: string, checkoutProfileId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileDeleteCheckoutProfile', 'storeId', storeId)
            // verify required parameter 'checkoutProfileId' is not null or undefined
            assertParamExists('checkoutProfileDeleteCheckoutProfile', 'checkoutProfileId', checkoutProfileId)
            const localVarPath = `/store/{storeId}/person/checkoutProfile/{checkoutProfileId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"checkoutProfileId"}}`, String(checkoutProfileId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileGetByIdCheckoutProfile: async (storeId: string, checkoutProfileId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileGetByIdCheckoutProfile', 'storeId', storeId)
            // verify required parameter 'checkoutProfileId' is not null or undefined
            assertParamExists('checkoutProfileGetByIdCheckoutProfile', 'checkoutProfileId', checkoutProfileId)
            const localVarPath = `/store/{storeId}/person/checkoutProfile/{checkoutProfileId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"checkoutProfileId"}}`, String(checkoutProfileId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the Check Out Profiles for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get check out profiles
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileGetCheckoutProfile: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileGetCheckoutProfile', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/checkoutProfile`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfile} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileUpdateCheckoutProfile: async (storeId: string, responseFormat?: string, body?: PersonCheckoutProfile, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileUpdateCheckoutProfile', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/person/@self/checkoutProfile`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileUpdateCheckoutProfileById: async (storeId: string, checkoutProfileId: string, responseFormat?: string, body?: PersonCheckoutProfileUpdate, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('checkoutProfileUpdateCheckoutProfileById', 'storeId', storeId)
            // verify required parameter 'checkoutProfileId' is not null or undefined
            assertParamExists('checkoutProfileUpdateCheckoutProfileById', 'checkoutProfileId', checkoutProfileId)
            const localVarPath = `/store/{storeId}/person/checkoutProfile/{checkoutProfileId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"checkoutProfileId"}}`, String(checkoutProfileId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CheckoutProfileApi - functional programming interface
 * @export
 */
export const CheckoutProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CheckoutProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Create a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [personCheckoutProfileUpdate] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileCreateCheckoutProfile(storeId: string, responseFormat?: string, personCheckoutProfileUpdate?: PersonCheckoutProfileUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerCheckoutProfileIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileCreateCheckoutProfile(storeId, responseFormat, personCheckoutProfileUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Delete a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileDeleteCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileDeleteCheckoutProfile(storeId, checkoutProfileId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileGetByIdCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCheckoutProfileUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileGetByIdCheckoutProfile(storeId, checkoutProfileId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the Check Out Profiles for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get check out profiles
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileGetCheckoutProfile(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonCheckoutProfile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileGetCheckoutProfile(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfile} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileUpdateCheckoutProfile(storeId: string, responseFormat?: string, body?: PersonCheckoutProfile, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerUserIdContainer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileUpdateCheckoutProfile(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkoutProfileUpdateCheckoutProfileById(storeId: string, checkoutProfileId: string, responseFormat?: string, body?: PersonCheckoutProfileUpdate, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkoutProfileUpdateCheckoutProfileById(storeId, checkoutProfileId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CheckoutProfileApi - factory interface
 * @export
 */
export const CheckoutProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CheckoutProfileApiFp(configuration)
    return {
        /**
         * Creates Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Create a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [personCheckoutProfileUpdate] The body data required for the REST method.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileCreateCheckoutProfile(storeId: string, responseFormat?: string, personCheckoutProfileUpdate?: PersonCheckoutProfileUpdate, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerCheckoutProfileIdContainer> {
            return localVarFp.checkoutProfileCreateCheckoutProfile(storeId, responseFormat, personCheckoutProfileUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Delete a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileDeleteCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkoutProfileDeleteCheckoutProfile(storeId, checkoutProfileId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileGetByIdCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any): AxiosPromise<PersonCheckoutProfileUpdate> {
            return localVarFp.checkoutProfileGetByIdCheckoutProfile(storeId, checkoutProfileId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the Check Out Profiles for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Get check out profiles
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileGetCheckoutProfile(storeId: string, responseFormat?: string, options?: any): AxiosPromise<PersonCheckoutProfile> {
            return localVarFp.checkoutProfileGetCheckoutProfile(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update check out profile
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfile} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileUpdateCheckoutProfile(storeId: string, responseFormat?: string, body?: PersonCheckoutProfile, options?: any): AxiosPromise<ComIbmCommerceRestMemberHandlerPersonCheckoutProfileHandlerUserIdContainer> {
            return localVarFp.checkoutProfileUpdateCheckoutProfile(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
         * @summary Update a check out profile
         * @param {string} storeId The store identifier.
         * @param {string} checkoutProfileId The checkout profile identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {PersonCheckoutProfileUpdate} [body] Update checkout profile body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkoutProfileUpdateCheckoutProfileById(storeId: string, checkoutProfileId: string, responseFormat?: string, body?: PersonCheckoutProfileUpdate, options?: any): AxiosPromise<void> {
            return localVarFp.checkoutProfileUpdateCheckoutProfileById(storeId, checkoutProfileId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CheckoutProfileApi - object-oriented interface
 * @export
 * @class CheckoutProfileApi
 * @extends {BaseAPI}
 */
export class CheckoutProfileApi extends BaseAPI {
    /**
     * Creates Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Create a check out profile
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {PersonCheckoutProfileUpdate} [personCheckoutProfileUpdate] The body data required for the REST method.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileCreateCheckoutProfile(storeId: string, responseFormat?: string, personCheckoutProfileUpdate?: PersonCheckoutProfileUpdate, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileCreateCheckoutProfile(storeId, responseFormat, personCheckoutProfileUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Delete a check out profile
     * @param {string} storeId The store identifier.
     * @param {string} checkoutProfileId The checkout profile identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileDeleteCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileDeleteCheckoutProfile(storeId, checkoutProfileId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a Check Out Profile for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Get a check out profile
     * @param {string} storeId The store identifier.
     * @param {string} checkoutProfileId The checkout profile identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileGetByIdCheckoutProfile(storeId: string, checkoutProfileId: string, responseFormat?: string, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileGetByIdCheckoutProfile(storeId, checkoutProfileId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the Check Out Profiles for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Get check out profiles
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileGetCheckoutProfile(storeId: string, responseFormat?: string, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileGetCheckoutProfile(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Update check out profile
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {PersonCheckoutProfile} [body] Update checkout profile body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileUpdateCheckoutProfile(storeId: string, responseFormat?: string, body?: PersonCheckoutProfile, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileUpdateCheckoutProfile(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the CheckOutProfile for the logged in user using the Member service. The shopper must log in before invoking this method.
     * @summary Update a check out profile
     * @param {string} storeId The store identifier.
     * @param {string} checkoutProfileId The checkout profile identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {PersonCheckoutProfileUpdate} [body] Update checkout profile body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CheckoutProfileApi
     */
    public checkoutProfileUpdateCheckoutProfileById(storeId: string, checkoutProfileId: string, responseFormat?: string, body?: PersonCheckoutProfileUpdate, options?: any) {
        return CheckoutProfileApiFp(this.configuration).checkoutProfileUpdateCheckoutProfileById(storeId, checkoutProfileId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
