/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ExtendedloggerBehavior } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * ExtendedloggerApi - axios parameter creator
 * @export
 */
export const ExtendedloggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update configuration of extended logger asynchronously.
         * @summary Update configuration of extended logger asynchronously.
         * @param {string} [enableFlag] This enable flag is used to enable/disable the extended logging.
         * @param {string} [customFields] This custom fields query param is used to set the customized extended log for their own specification.
         * @param {string} [registrationFlag] This registration flag is used to register/unregister the custom fields.  When registrationFlag value is false, and customFields doesn\&#39;t exist, it means to unregister all custom fields.
         * @param {string} [containerNames] This container names is used to assign the containers who will be enabled extended logging.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendedloggerAsyncconfigPut: async (enableFlag?: string, customFields?: string, registrationFlag?: string, containerNames?: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/extendedlogger/asyncconfig`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (enableFlag !== undefined) {
                localVarQueryParameter['enableFlag'] = enableFlag;
            }

            if (customFields !== undefined) {
                localVarQueryParameter['customFields'] = customFields;
            }

            if (registrationFlag !== undefined) {
                localVarQueryParameter['registrationFlag'] = registrationFlag;
            }

            if (containerNames !== undefined) {
                localVarQueryParameter['containerNames'] = containerNames;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExtendedloggerApi - functional programming interface
 * @export
 */
export const ExtendedloggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExtendedloggerApiAxiosParamCreator(configuration)
    return {
        /**
         * Update configuration of extended logger asynchronously.
         * @summary Update configuration of extended logger asynchronously.
         * @param {string} [enableFlag] This enable flag is used to enable/disable the extended logging.
         * @param {string} [customFields] This custom fields query param is used to set the customized extended log for their own specification.
         * @param {string} [registrationFlag] This registration flag is used to register/unregister the custom fields.  When registrationFlag value is false, and customFields doesn\&#39;t exist, it means to unregister all custom fields.
         * @param {string} [containerNames] This container names is used to assign the containers who will be enabled extended logging.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async extendedloggerAsyncconfigPut(enableFlag?: string, customFields?: string, registrationFlag?: string, containerNames?: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExtendedloggerBehavior>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.extendedloggerAsyncconfigPut(enableFlag, customFields, registrationFlag, containerNames, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExtendedloggerApi - factory interface
 * @export
 */
export const ExtendedloggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExtendedloggerApiFp(configuration)
    return {
        /**
         * Update configuration of extended logger asynchronously.
         * @summary Update configuration of extended logger asynchronously.
         * @param {string} [enableFlag] This enable flag is used to enable/disable the extended logging.
         * @param {string} [customFields] This custom fields query param is used to set the customized extended log for their own specification.
         * @param {string} [registrationFlag] This registration flag is used to register/unregister the custom fields.  When registrationFlag value is false, and customFields doesn\&#39;t exist, it means to unregister all custom fields.
         * @param {string} [containerNames] This container names is used to assign the containers who will be enabled extended logging.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        extendedloggerAsyncconfigPut(enableFlag?: string, customFields?: string, registrationFlag?: string, containerNames?: string, responseFormat?: string, options?: any): AxiosPromise<ExtendedloggerBehavior> {
            return localVarFp.extendedloggerAsyncconfigPut(enableFlag, customFields, registrationFlag, containerNames, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExtendedloggerApi - object-oriented interface
 * @export
 * @class ExtendedloggerApi
 * @extends {BaseAPI}
 */
export class ExtendedloggerApi extends BaseAPI {
    /**
     * Update configuration of extended logger asynchronously.
     * @summary Update configuration of extended logger asynchronously.
     * @param {string} [enableFlag] This enable flag is used to enable/disable the extended logging.
     * @param {string} [customFields] This custom fields query param is used to set the customized extended log for their own specification.
     * @param {string} [registrationFlag] This registration flag is used to register/unregister the custom fields.  When registrationFlag value is false, and customFields doesn\&#39;t exist, it means to unregister all custom fields.
     * @param {string} [containerNames] This container names is used to assign the containers who will be enabled extended logging.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExtendedloggerApi
     */
    public extendedloggerAsyncconfigPut(enableFlag?: string, customFields?: string, registrationFlag?: string, containerNames?: string, responseFormat?: string, options?: any) {
        return ExtendedloggerApiFp(this.configuration).extendedloggerAsyncconfigPut(enableFlag, customFields, registrationFlag, containerNames, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
