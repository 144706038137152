/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * EventApi - axios parameter creator
 * @export
 */
export const EventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Evaluates potential triggers passed from the store.
         * @summary Evaluate triggers
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEvaluateTriggers: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eventEvaluateTriggers', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/event/evaluate_triggers`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets user behavior rules.
         * @summary Get user behavior rules
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventGetBehaviorRules: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eventGetBehaviorRules', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/event/behavior_rules`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Handles click information.
         * @summary Handle click information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventHandleClickInfo: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eventHandleClickInfo', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/event/click_info`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Triggers marketing based on certain events.
         * @summary Trigger marketing by event
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing event trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTriggerMarketing: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eventTriggerMarketing', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/event`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the marketing tracking consent for a user for the store.
         * @summary Update the marketing tracking consent for a user for the store.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventUpdateMarketingTrackingConsent: async (storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('eventUpdateMarketingTrackingConsent', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/event/update_marketing_tracking_consent`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventApi - functional programming interface
 * @export
 */
export const EventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventApiAxiosParamCreator(configuration)
    return {
        /**
         * Evaluates potential triggers passed from the store.
         * @summary Evaluate triggers
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventEvaluateTriggers(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventEvaluateTriggers(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets user behavior rules.
         * @summary Get user behavior rules
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventGetBehaviorRules(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventGetBehaviorRules(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Handles click information.
         * @summary Handle click information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventHandleClickInfo(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventHandleClickInfo(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Triggers marketing based on certain events.
         * @summary Trigger marketing by event
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing event trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventTriggerMarketing(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventTriggerMarketing(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the marketing tracking consent for a user for the store.
         * @summary Update the marketing tracking consent for a user for the store.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventUpdateMarketingTrackingConsent(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventUpdateMarketingTrackingConsent(storeId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventApi - factory interface
 * @export
 */
export const EventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventApiFp(configuration)
    return {
        /**
         * Evaluates potential triggers passed from the store.
         * @summary Evaluate triggers
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventEvaluateTriggers(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): AxiosPromise<void> {
            return localVarFp.eventEvaluateTriggers(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets user behavior rules.
         * @summary Get user behavior rules
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventGetBehaviorRules(storeId: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.eventGetBehaviorRules(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Handles click information.
         * @summary Handle click information
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventHandleClickInfo(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): AxiosPromise<void> {
            return localVarFp.eventHandleClickInfo(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Triggers marketing based on certain events.
         * @summary Trigger marketing by event
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing event trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventTriggerMarketing(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any): AxiosPromise<void> {
            return localVarFp.eventTriggerMarketing(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Update the marketing tracking consent for a user for the store.
         * @summary Update the marketing tracking consent for a user for the store.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent} [body] Marketing evaluate trigger body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventUpdateMarketingTrackingConsent(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent, options?: any): AxiosPromise<void> {
            return localVarFp.eventUpdateMarketingTrackingConsent(storeId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventApi - object-oriented interface
 * @export
 * @class EventApi
 * @extends {BaseAPI}
 */
export class EventApi extends BaseAPI {
    /**
     * Evaluates potential triggers passed from the store.
     * @summary Evaluate triggers
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventEvaluateTriggers(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any) {
        return EventApiFp(this.configuration).eventEvaluateTriggers(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets user behavior rules.
     * @summary Get user behavior rules
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventGetBehaviorRules(storeId: string, responseFormat?: string, options?: any) {
        return EventApiFp(this.configuration).eventGetBehaviorRules(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Handles click information.
     * @summary Handle click information
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing evaluate trigger body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventHandleClickInfo(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any) {
        return EventApiFp(this.configuration).eventHandleClickInfo(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Triggers marketing based on certain events.
     * @summary Trigger marketing by event
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger} [body] Marketing event trigger body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventTriggerMarketing(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerEventTrigger, options?: any) {
        return EventApiFp(this.configuration).eventTriggerMarketing(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the marketing tracking consent for a user for the store.
     * @summary Update the marketing tracking consent for a user for the store.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent} [body] Marketing evaluate trigger body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventApi
     */
    public eventUpdateMarketingTrackingConsent(storeId: string, responseFormat?: string, body?: ComIbmCommerceRestMarketingHandlerEventHandlerMarketingTrackingConsent, options?: any) {
        return EventApiFp(this.configuration).eventUpdateMarketingTrackingConsent(storeId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
