//Standard libraries
import React from "react";
import { useTranslation } from "react-i18next";

import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";

import { StyledGrid, StyledContainer, StyledTypography } from "@hcl-commerce-store-sdk/react-component";
import { STRStore } from "solteq/src/services/STRStore.service";

export const Contact = () => {
  const [contactInfo, setContactInfo] = React.useState<any>(null);

  const { t } = useTranslation();

  React.useEffect(() => {
    let sub = true; // Cancel the setState after component unmounts this way
    // contact or location ?
    STRStore.getContactInfo().then((response: any) => {
      if (sub) {
        setContactInfo(response);
      }
    });
    return () => {
      sub = false;
    };
  }, []);

  return (
    <StyledContainer className="header-contact">
      <StyledGrid container direction="row" justifyContent="flex-start" wrap="nowrap" alignItems="center" spacing={1}>
        <StyledGrid item>
          <StyledTypography>{t("Header.Contact.CustomerService")} (8-16)</StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <SmartphoneIcon fontSize="small" />
        </StyledGrid>
        <StyledGrid item>
          <StyledTypography>{contactInfo?.telephone1}</StyledTypography>
        </StyledGrid>
        <StyledGrid item>
          <MailOutlineIcon fontSize="small" />
        </StyledGrid>
        <StyledGrid item>
          <StyledTypography>{contactInfo?.emailAddress1}</StyledTypography>
        </StyledGrid>
      </StyledGrid>
    </StyledContainer>
  );
};
