/**
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
// Entitled org

export const ENTITLED_ORG_REQUESTED = "ENTITLED_ORG_REQUESTED";
export const ENTITLED_ORG_REQUEST_SUCCESS = "ENTITLED_ORG_REQUEST_SUCCESS";
export const ENTITLED_ORG_REQUEST_ERROR = "ENTITLED_ORG_REQUEST_ERROR";

export const ORG_SWITCH_REQUESTED = "ORG_SWITCH_REQUESTED";
export const ORG_SWITCH_REQUEST_ERROR = "ORG_SWITCH_REQUEST_ERROR";
export const ORG_SWITCH_SUCCESS = "ORG_SWITCH_SUCCESS";

// Organization Details
export const GET_ORGANIZATION_ADDRESS = "GET_ORGANIZATION_ADDRESS";
export const GET_ORGANIZATION_ADDRESS_SUCCESS = "GET_ORGANIZATION_ADDRESS_SUCCESS";
export const GET_ORGANIZATION_ADDRESS_ERROR = "GET_ORGANIZATION_ADDRESS_ERROR";

// Organization shipping addresses
export const GET_ORGANIZATION_SHIPPING_ADDRESSES = "GET_ORGANIZATION_SHIPPING_ADDRESSES";
export const GET_ORGANIZATION_SHIPPING_ADDRESSES_SUCCESS = "GET_ORGANIZATION_SHIPPING_ADDRESSES_SUCCESS";
export const GET_ORGANIZATION_SHIPPING_ADDRESS = "GET_ORGANIZATION_SHIPPING_ADDRESS";
export const GET_ORGANIZATION_SHIPPING_ADDRESS_SUCCESS = "GET_ORGANIZATION_SHIPPING_ADDRESS_SUCCESS";
export const CREATE_ORGANIZATION_SHIPPING_ADDRESS = "CREATE_ORGANIZATION_SHIPPING_ADDRESS";
export const UPDATE_ORGANIZATION_SHIPPING_ADDRESS = "UPDATE_ORGANIZATION_SHIPPING_ADDRESS";
export const DELETE_ORGANIZATION_SHIPPING_ADDRESS = "DELETE_ORGANIZATION_SHIPPING_ADDRESS";
