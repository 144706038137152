/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { PricePrice } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * PriceApi - axios parameter creator
 * @export
 */
export const PriceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the entitled and range prices for catalog entries by part numbers.
         * @summary Get prices by part numbers
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the catalog entry.
         * @param {string} [contractId] The unique id of the contract.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {string} [date] The date of the price.
         * @param {string} [checkEntitlement] Whether to enable entitlement check when retrieving prices.
         * @param {string} [dynamicKitAsItem] Whether ot treat dynamic kits as items when retrieving prices.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceByPartNumbers: async (storeId: string, q: string, partNumber: Array<string>, contractId?: string, currency?: string, quantity?: string, uom?: string, date?: string, checkEntitlement?: string, dynamicKitAsItem?: string, profileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('priceByPartNumbers', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('priceByPartNumbers', 'q', q)
            // verify required parameter 'partNumber' is not null or undefined
            assertParamExists('priceByPartNumbers', 'partNumber', partNumber)
            const localVarPath = `/store/{storeId}/price`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (partNumber) {
                localVarQueryParameter['partNumber'] = partNumber.join(COLLECTION_FORMATS.csv);
            }

            if (contractId !== undefined) {
                localVarQueryParameter['contractId'] = contractId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (quantity !== undefined) {
                localVarQueryParameter['quantity'] = quantity;
            }

            if (uom !== undefined) {
                localVarQueryParameter['uom'] = uom;
            }

            if (date !== undefined) {
                localVarQueryParameter['date'] = date;
            }

            if (checkEntitlement !== undefined) {
                localVarQueryParameter['checkEntitlement'] = checkEntitlement;
            }

            if (dynamicKitAsItem !== undefined) {
                localVarQueryParameter['dynamicKitAsItem'] = dynamicKitAsItem;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Finds prices in a store using a structure that allows multiple results in a single query.
         * @summary Get prices by query
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest} body The request body for a price query.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used. This value is applied to all the queried products unless overridden at the product level.
         * @param {string} [profile] Setting the \&#39;profile\&#39; to \&#39;default\&#39; or not specifying the \&#39;profile\&#39; will result in normal prices being returned. A \&#39;profile\&#39; value of \&#39;range\&#39; will return the normal prices, and in addition, will return a list of range prices, which includes the minimum and maximum quantities as well as the price for each range.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceFindPricesByQuery: async (storeId: string, body: ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest, currency?: string, profile?: string, responseFormat?: string, catalogId?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('priceFindPricesByQuery', 'storeId', storeId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('priceFindPricesByQuery', 'body', body)
            const localVarPath = `/store/{storeId}/price`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            if (profile !== undefined) {
                localVarQueryParameter['profile'] = profile;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (catalogId !== undefined) {
                localVarQueryParameter['catalogId'] = catalogId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceApi - functional programming interface
 * @export
 */
export const PriceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the entitled and range prices for catalog entries by part numbers.
         * @summary Get prices by part numbers
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the catalog entry.
         * @param {string} [contractId] The unique id of the contract.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {string} [date] The date of the price.
         * @param {string} [checkEntitlement] Whether to enable entitlement check when retrieving prices.
         * @param {string} [dynamicKitAsItem] Whether ot treat dynamic kits as items when retrieving prices.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceByPartNumbers(storeId: string, q: string, partNumber: Array<string>, contractId?: string, currency?: string, quantity?: string, uom?: string, date?: string, checkEntitlement?: string, dynamicKitAsItem?: string, profileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricePrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceByPartNumbers(storeId, q, partNumber, contractId, currency, quantity, uom, date, checkEntitlement, dynamicKitAsItem, profileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Finds prices in a store using a structure that allows multiple results in a single query.
         * @summary Get prices by query
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest} body The request body for a price query.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used. This value is applied to all the queried products unless overridden at the product level.
         * @param {string} [profile] Setting the \&#39;profile\&#39; to \&#39;default\&#39; or not specifying the \&#39;profile\&#39; will result in normal prices being returned. A \&#39;profile\&#39; value of \&#39;range\&#39; will return the normal prices, and in addition, will return a list of range prices, which includes the minimum and maximum quantities as well as the price for each range.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceFindPricesByQuery(storeId: string, body: ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest, currency?: string, profile?: string, responseFormat?: string, catalogId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PricePrice>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceFindPricesByQuery(storeId, body, currency, profile, responseFormat, catalogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceApi - factory interface
 * @export
 */
export const PriceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceApiFp(configuration)
    return {
        /**
         * Gets the entitled and range prices for catalog entries by part numbers.
         * @summary Get prices by part numbers
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {Array<string>} partNumber The part number of the catalog entry.
         * @param {string} [contractId] The unique id of the contract.
         * @param {string} [currency] The currency of the price.
         * @param {string} [quantity] The quantity of the catalog entry.
         * @param {string} [uom] The unit of measurement of the quantity.
         * @param {string} [date] The date of the price.
         * @param {string} [checkEntitlement] Whether to enable entitlement check when retrieving prices.
         * @param {string} [dynamicKitAsItem] Whether ot treat dynamic kits as items when retrieving prices.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceByPartNumbers(storeId: string, q: string, partNumber: Array<string>, contractId?: string, currency?: string, quantity?: string, uom?: string, date?: string, checkEntitlement?: string, dynamicKitAsItem?: string, profileName?: string, options?: any): AxiosPromise<PricePrice> {
            return localVarFp.priceByPartNumbers(storeId, q, partNumber, contractId, currency, quantity, uom, date, checkEntitlement, dynamicKitAsItem, profileName, options).then((request) => request(axios, basePath));
        },
        /**
         * Finds prices in a store using a structure that allows multiple results in a single query.
         * @summary Get prices by query
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest} body The request body for a price query.
         * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used. This value is applied to all the queried products unless overridden at the product level.
         * @param {string} [profile] Setting the \&#39;profile\&#39; to \&#39;default\&#39; or not specifying the \&#39;profile\&#39; will result in normal prices being returned. A \&#39;profile\&#39; value of \&#39;range\&#39; will return the normal prices, and in addition, will return a list of range prices, which includes the minimum and maximum quantities as well as the price for each range.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceFindPricesByQuery(storeId: string, body: ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest, currency?: string, profile?: string, responseFormat?: string, catalogId?: string, options?: any): AxiosPromise<PricePrice> {
            return localVarFp.priceFindPricesByQuery(storeId, body, currency, profile, responseFormat, catalogId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PriceApi - object-oriented interface
 * @export
 * @class PriceApi
 * @extends {BaseAPI}
 */
export class PriceApi extends BaseAPI {
    /**
     * Gets the entitled and range prices for catalog entries by part numbers.
     * @summary Get prices by part numbers
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {Array<string>} partNumber The part number of the catalog entry.
     * @param {string} [contractId] The unique id of the contract.
     * @param {string} [currency] The currency of the price.
     * @param {string} [quantity] The quantity of the catalog entry.
     * @param {string} [uom] The unit of measurement of the quantity.
     * @param {string} [date] The date of the price.
     * @param {string} [checkEntitlement] Whether to enable entitlement check when retrieving prices.
     * @param {string} [dynamicKitAsItem] Whether ot treat dynamic kits as items when retrieving prices.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceByPartNumbers(storeId: string, q: string, partNumber: Array<string>, contractId?: string, currency?: string, quantity?: string, uom?: string, date?: string, checkEntitlement?: string, dynamicKitAsItem?: string, profileName?: string, options?: any) {
        return PriceApiFp(this.configuration).priceByPartNumbers(storeId, q, partNumber, contractId, currency, quantity, uom, date, checkEntitlement, dynamicKitAsItem, profileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Finds prices in a store using a structure that allows multiple results in a single query.
     * @summary Get prices by query
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest} body The request body for a price query.
     * @param {string} [currency] The currency code to use for example, currency&#x3D;USD. If no currency code is specified, the store default currency is used. This value is applied to all the queried products unless overridden at the product level.
     * @param {string} [profile] Setting the \&#39;profile\&#39; to \&#39;default\&#39; or not specifying the \&#39;profile\&#39; will result in normal prices being returned. A \&#39;profile\&#39; value of \&#39;range\&#39; will return the normal prices, and in addition, will return a list of range prices, which includes the minimum and maximum quantities as well as the price for each range.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} [catalogId] The catalog identifier. If none is specified, the store default catalog is used.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceApi
     */
    public priceFindPricesByQuery(storeId: string, body: ComIbmCommerceRestPriceHandlerPriceHandlerPriceRequest, currency?: string, profile?: string, responseFormat?: string, catalogId?: string, options?: any) {
        return PriceApiFp(this.configuration).priceFindPricesByQuery(storeId, body, currency, profile, responseFormat, catalogId, options).then((request) => request(this.axios, this.basePath));
    }
}
