/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestStoreHandlerStoreHandlerStoreInformation } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreFeaturesList } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { StoreStore } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * StoreApi - axios parameter creator
 * @export
 */
export const StoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets top-level resource URLs.
         * @summary Get top-level resource URLs
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFetchStore: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeFetchStore', 'storeId', storeId)
            const localVarPath = `/store/{storeId}`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets online store information by the specified query.
         * @summary Get store information by query
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByQueryOnlineStore: async (storeId: string, q?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeFindByQueryOnlineStore', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/online_store`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets tax information by the specified query.
         * @summary Get tax information by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByQueryTaxInformation: async (storeId: string, q: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeFindByQueryTaxInformation', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('storeFindByQueryTaxInformation', 'q', q)
            const localVarPath = `/store/{storeId}/taxInformation`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets store details by the specified store name and usage.
         * @summary Get store details by name and usage
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} responseFormat The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} storeName The store name.
         * @param {string} usage The usage.
         * @param {string} storeIdentifier The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByStoreNameAndUsage: async (storeId: string, q: string, responseFormat: string, storeName: string, usage: string, storeIdentifier: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'q', q)
            // verify required parameter 'responseFormat' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'responseFormat', responseFormat)
            // verify required parameter 'storeName' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'storeName', storeName)
            // verify required parameter 'usage' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'usage', usage)
            // verify required parameter 'storeIdentifier' is not null or undefined
            assertParamExists('storeFindByStoreNameAndUsage', 'storeIdentifier', storeIdentifier)
            const localVarPath = `/store/{storeId}/adminLookup`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (storeName !== undefined) {
                localVarQueryParameter['storeName'] = storeName;
            }

            if (usage !== undefined) {
                localVarQueryParameter['usage'] = usage;
            }

            if (storeIdentifier !== undefined) {
                localVarQueryParameter['storeIdentifier'] = storeIdentifier;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets information about a specific store ID. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get store information by store ID
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [jspStoreDir] 
         * @param {string} [storeRelationshipTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindDataBean: async (storeId: string, profileName?: string, jspStoreDir?: string, storeRelationshipTypeName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeFindDataBean', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/databean`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (jspStoreDir !== undefined) {
                localVarQueryParameter['jspStoreDir'] = jspStoreDir;
            }

            if (storeRelationshipTypeName !== undefined) {
                localVarQueryParameter['storeRelationshipTypeName'] = storeRelationshipTypeName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of enabled store features.
         * @summary Gets a list of enabled store features.
         * @param {string} storeId The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeGetFeatureList: async (storeId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeGetFeatureList', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/features`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets reomte store configs.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdRemoteConfigGet: async (storeId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdRemoteConfigGet', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/remoteConfig`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary This method returns the store asset at the specified path (relative or full) from the Watson Content Hub.
         * @param {string} storeId The store identifier.
         * @param {string} assetPath The path to the asset on the WCH. Asset path can be relative or full.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdStoreAssetGet: async (storeId: string, assetPath: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('storeStoreIdStoreAssetGet', 'storeId', storeId)
            // verify required parameter 'assetPath' is not null or undefined
            assertParamExists('storeStoreIdStoreAssetGet', 'assetPath', assetPath)
            const localVarPath = `/store/{storeId}/storeAsset`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (assetPath !== undefined) {
                localVarQueryParameter['assetPath'] = assetPath;
            }

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StoreApi - functional programming interface
 * @export
 */
export const StoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StoreApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets top-level resource URLs.
         * @summary Get top-level resource URLs
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeFetchStore(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestStoreHandlerStoreHandlerStoreInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeFetchStore(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets online store information by the specified query.
         * @summary Get store information by query
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeFindByQueryOnlineStore(storeId: string, q?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeFindByQueryOnlineStore(storeId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets tax information by the specified query.
         * @summary Get tax information by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeFindByQueryTaxInformation(storeId: string, q: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeFindByQueryTaxInformation(storeId, q, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets store details by the specified store name and usage.
         * @summary Get store details by name and usage
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} responseFormat The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} storeName The store name.
         * @param {string} usage The usage.
         * @param {string} storeIdentifier The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeFindByStoreNameAndUsage(storeId: string, q: string, responseFormat: string, storeName: string, usage: string, storeIdentifier: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeFindByStoreNameAndUsage(storeId, q, responseFormat, storeName, usage, storeIdentifier, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets information about a specific store ID. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get store information by store ID
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [jspStoreDir] 
         * @param {string} [storeRelationshipTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeFindDataBean(storeId: string, profileName?: string, jspStoreDir?: string, storeRelationshipTypeName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreStore>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeFindDataBean(storeId, profileName, jspStoreDir, storeRelationshipTypeName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of enabled store features.
         * @summary Gets a list of enabled store features.
         * @param {string} storeId The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeGetFeatureList(storeId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StoreFeaturesList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeGetFeatureList(storeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Gets reomte store configs.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdRemoteConfigGet(storeId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestStoreHandlerStoreHandlerStoreInformation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdRemoteConfigGet(storeId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary This method returns the store asset at the specified path (relative or full) from the Watson Content Hub.
         * @param {string} storeId The store identifier.
         * @param {string} assetPath The path to the asset on the WCH. Asset path can be relative or full.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeStoreIdStoreAssetGet(storeId: string, assetPath: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.storeStoreIdStoreAssetGet(storeId, assetPath, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StoreApi - factory interface
 * @export
 */
export const StoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StoreApiFp(configuration)
    return {
        /**
         * Gets top-level resource URLs.
         * @summary Get top-level resource URLs
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFetchStore(storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestStoreHandlerStoreHandlerStoreInformation> {
            return localVarFp.storeFetchStore(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets online store information by the specified query.
         * @summary Get store information by query
         * @param {string} storeId The store identifier.
         * @param {string} [q] The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByQueryOnlineStore(storeId: string, q?: string, options?: any): AxiosPromise<StoreStore> {
            return localVarFp.storeFindByQueryOnlineStore(storeId, q, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets tax information by the specified query.
         * @summary Get tax information by query
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByQueryTaxInformation(storeId: string, q: string, options?: any): AxiosPromise<StoreStore> {
            return localVarFp.storeFindByQueryTaxInformation(storeId, q, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets store details by the specified store name and usage.
         * @summary Get store details by name and usage
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} responseFormat The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {string} storeName The store name.
         * @param {string} usage The usage.
         * @param {string} storeIdentifier The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindByStoreNameAndUsage(storeId: string, q: string, responseFormat: string, storeName: string, usage: string, storeIdentifier: string, options?: any): AxiosPromise<StoreStore> {
            return localVarFp.storeFindByStoreNameAndUsage(storeId, q, responseFormat, storeName, usage, storeIdentifier, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets information about a specific store ID. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
         * @summary Get store information by store ID
         * @param {string} storeId The store identifier.
         * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [jspStoreDir] 
         * @param {string} [storeRelationshipTypeName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeFindDataBean(storeId: string, profileName?: string, jspStoreDir?: string, storeRelationshipTypeName?: string, options?: any): AxiosPromise<StoreStore> {
            return localVarFp.storeFindDataBean(storeId, profileName, jspStoreDir, storeRelationshipTypeName, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of enabled store features.
         * @summary Gets a list of enabled store features.
         * @param {string} storeId The store identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeGetFeatureList(storeId: string, options?: any): AxiosPromise<StoreFeaturesList> {
            return localVarFp.storeGetFeatureList(storeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Gets reomte store configs.
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdRemoteConfigGet(storeId: string, responseFormat?: string, options?: any): AxiosPromise<ComIbmCommerceRestStoreHandlerStoreHandlerStoreInformation> {
            return localVarFp.storeStoreIdRemoteConfigGet(storeId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary This method returns the store asset at the specified path (relative or full) from the Watson Content Hub.
         * @param {string} storeId The store identifier.
         * @param {string} assetPath The path to the asset on the WCH. Asset path can be relative or full.
         * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeStoreIdStoreAssetGet(storeId: string, assetPath: string, responseFormat?: string, options?: any): AxiosPromise<void> {
            return localVarFp.storeStoreIdStoreAssetGet(storeId, assetPath, responseFormat, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StoreApi - object-oriented interface
 * @export
 * @class StoreApi
 * @extends {BaseAPI}
 */
export class StoreApi extends BaseAPI {
    /**
     * Gets top-level resource URLs.
     * @summary Get top-level resource URLs
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeFetchStore(storeId: string, responseFormat?: string, options?: any) {
        return StoreApiFp(this.configuration).storeFetchStore(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets online store information by the specified query.
     * @summary Get store information by query
     * @param {string} storeId The store identifier.
     * @param {string} [q] The query name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeFindByQueryOnlineStore(storeId: string, q?: string, options?: any) {
        return StoreApiFp(this.configuration).storeFindByQueryOnlineStore(storeId, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets tax information by the specified query.
     * @summary Get tax information by query
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeFindByQueryTaxInformation(storeId: string, q: string, options?: any) {
        return StoreApiFp(this.configuration).storeFindByQueryTaxInformation(storeId, q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets store details by the specified store name and usage.
     * @summary Get store details by name and usage
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} responseFormat The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {string} storeName The store name.
     * @param {string} usage The usage.
     * @param {string} storeIdentifier The store identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeFindByStoreNameAndUsage(storeId: string, q: string, responseFormat: string, storeName: string, usage: string, storeIdentifier: string, options?: any) {
        return StoreApiFp(this.configuration).storeFindByStoreNameAndUsage(storeId, q, responseFormat, storeName, usage, storeIdentifier, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets information about a specific store ID. Can only be called after logging in as an integration user. As such, requests must be sent with an integration user cookie or token. An integration user is a user in the RemoteConfigurationReader member group.
     * @summary Get store information by store ID
     * @param {string} storeId The store identifier.
     * @param {string} [profileName] Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [jspStoreDir] 
     * @param {string} [storeRelationshipTypeName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeFindDataBean(storeId: string, profileName?: string, jspStoreDir?: string, storeRelationshipTypeName?: string, options?: any) {
        return StoreApiFp(this.configuration).storeFindDataBean(storeId, profileName, jspStoreDir, storeRelationshipTypeName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of enabled store features.
     * @summary Gets a list of enabled store features.
     * @param {string} storeId The store identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeGetFeatureList(storeId: string, options?: any) {
        return StoreApiFp(this.configuration).storeGetFeatureList(storeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Gets reomte store configs.
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeStoreIdRemoteConfigGet(storeId: string, responseFormat?: string, options?: any) {
        return StoreApiFp(this.configuration).storeStoreIdRemoteConfigGet(storeId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary This method returns the store asset at the specified path (relative or full) from the Watson Content Hub.
     * @param {string} storeId The store identifier.
     * @param {string} assetPath The path to the asset on the WCH. Asset path can be relative or full.
     * @param {string} [responseFormat] The response format. If the request has an input body, that body must also use the format specified in \&quot;responseFormat\&quot;. Valid values include �json� and �xml� without the quotes. If the responseFormat isn�t specified, the �accept� HTTP header shall be used to determine the format of the response. If the �accept� HTTP header isn�t specified as well, the default response format shall be in json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StoreApi
     */
    public storeStoreIdStoreAssetGet(storeId: string, assetPath: string, responseFormat?: string, options?: any) {
        return StoreApiFp(this.configuration).storeStoreIdStoreAssetGet(storeId, assetPath, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }
}
