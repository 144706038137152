/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2021
 *
 *==================================================
 */
export const ASSETS_PATH = ["thumbnail", "fullImage", "image1path", "attachmentAssetPath"];
