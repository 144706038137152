/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest } from '../../../../../com/hcl/commerce/typescript/axios';
// @ts-ignore
import { ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportResponse } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * OrderExportApi - axios parameter creator
 * @export
 */
export const OrderExportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Exports orders for a store.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest} [body] Specify optional parameters in the body in JSON format. For example, you can specify the order status, and the number of days to set back for the time placed of the order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrders: async (storeId: string, body?: ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('exportOrders', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/order_export`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderExportApi - functional programming interface
 * @export
 */
export const OrderExportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderExportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Exports orders for a store.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest} [body] Specify optional parameters in the body in JSON format. For example, you can specify the order status, and the number of days to set back for the time placed of the order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportOrders(storeId: string, body?: ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportOrders(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderExportApi - factory interface
 * @export
 */
export const OrderExportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderExportApiFp(configuration)
    return {
        /**
         * 
         * @summary Exports orders for a store.
         * @param {string} storeId The store identifier.
         * @param {ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest} [body] Specify optional parameters in the body in JSON format. For example, you can specify the order status, and the number of days to set back for the time placed of the order.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrders(storeId: string, body?: ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest, options?: any): AxiosPromise<ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportResponse> {
            return localVarFp.exportOrders(storeId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderExportApi - object-oriented interface
 * @export
 * @class OrderExportApi
 * @extends {BaseAPI}
 */
export class OrderExportApi extends BaseAPI {
    /**
     * 
     * @summary Exports orders for a store.
     * @param {string} storeId The store identifier.
     * @param {ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest} [body] Specify optional parameters in the body in JSON format. For example, you can specify the order status, and the number of days to set back for the time placed of the order.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderExportApi
     */
    public exportOrders(storeId: string, body?: ComIbmCommerceRestOrderHandlerOrderExportHandlerOrderExportRequest, options?: any) {
        return OrderExportApiFp(this.configuration).exportOrders(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }
}
