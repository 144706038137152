/* tslint:disable */
/* eslint-disable */
/**
 * HCL Commerce Transaction server Services 
 * These services provide APIs to interact with transaction
 *
 * The version of the OpenAPI document: 9.1.6
 * 
 * (C) Copyright HCL Technologies Limited 2021
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../../../../../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../../../../../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../../../../base';
// @ts-ignore
import { LayoutLayout } from '../../../../../com/hcl/commerce/typescript/axios';
/**
 * LayoutApi - axios parameter creator
 * @export
 */
export const LayoutApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds a widget to a layout.
         * @summary Add widget to layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutAddWidget: async (storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutAddWidget', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutAddWidget', 'layoutId', layoutId)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/widget`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates the layout.
         * @summary Create layout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {boolean} [autoGenerateName] The server automatically generates a name for the layout.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutCreateLayout: async (storeId: string, responseFormat?: string, autoGenerateName?: boolean, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutCreateLayout', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/layout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (autoGenerateName !== undefined) {
                localVarQueryParameter['autoGenerateName'] = autoGenerateName;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a layout for a specified store.
         * @summary Delete layout for store
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutDeleteLayout: async (storeId: string, layoutId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutDeleteLayout', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutDeleteLayout', 'layoutId', layoutId)
            const localVarPath = `/store/{storeId}/layout/{layoutId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a widget from a layout.
         * @summary Delete widget from layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutDeleteWidget: async (storeId: string, layoutId: string, widgetId: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutDeleteWidget', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutDeleteWidget', 'layoutId', layoutId)
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('layoutDeleteWidget', 'widgetId', widgetId)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/widget/{widgetId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId))
                .replace(`{${"widgetId"}}`, String(widgetId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets layout details by layout unique ID.
         * @summary Get layout details by unique ID
         * @param {string} storeId The store identifier.
         * @param {number} layoutId The layout unique ID.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutFindLayoutByUniqueId: async (storeId: string, layoutId: number, profileName: string, responseFormat?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutFindLayoutByUniqueId', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutFindLayoutByUniqueId', 'layoutId', layoutId)
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('layoutFindLayoutByUniqueId', 'profileName', profileName)
            const localVarPath = `/store/{storeId}/layout/{layoutId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates, updates, or deletes the location.
         * @summary Create, update, or delete location
         * @param {string} storeId The store identifier.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutManageLayoutStaticLocation: async (storeId: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutManageLayoutStaticLocation', 'storeId', storeId)
            const localVarPath = `/store/{storeId}/layout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs an action on layout properties. See each action for details on input and output.
         * @summary Update layout properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformLayoutPropertiesAction: async (storeId: string, layoutId: string, action: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutPerformLayoutPropertiesAction', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutPerformLayoutPropertiesAction', 'layoutId', layoutId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('layoutPerformLayoutPropertiesAction', 'action', action)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/layoutProperties`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs an action on layout static location. See each action for details on input and output.
         * @summary Update layout static location
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformLayoutStaticLocationAction: async (storeId: string, layoutId: string, action: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutPerformLayoutStaticLocationAction', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutPerformLayoutStaticLocationAction', 'layoutId', layoutId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('layoutPerformLayoutStaticLocationAction', 'action', action)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/layoutStaticLocation`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Performs an action on widgets extended data. See each action for details on input and output.
         * @summary Update widget extended data
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformWidgetExtendedDataAction: async (storeId: string, layoutId: string, widgetId: string, action: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutPerformWidgetExtendedDataAction', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutPerformWidgetExtendedDataAction', 'layoutId', layoutId)
            // verify required parameter 'widgetId' is not null or undefined
            assertParamExists('layoutPerformWidgetExtendedDataAction', 'widgetId', widgetId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('layoutPerformWidgetExtendedDataAction', 'action', action)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/widget/{widgetId}/extendedData`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId))
                .replace(`{${"widgetId"}}`, String(widgetId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Searches for layouts based on the layout state and the search criteria. The default sorting order is based on the created date of layouts.
         * @summary Get layouts by state and search criteria
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [state] The state of the layout. If this parameter is not specified, the default state is Active.
         * @param {number} [ownerID] The ownerID of the layout. When the managingTool is IBM_COMMERCE_INSIGHTS_TOOL, not specifying the ownerID parameter in a pagination request may result in variances based on the caller\&#39;s Access Control privileges.
         * @param {string} [locationName] The location name search criteria.
         * @param {string} [name] The layout name search criteria.
         * @param {string} [sortBy] The sort order for the layouts. The default sort criteria depends on the query.
         * @param {string} [sortOrder] The sorting order. The default sort order is ascending.
         * @param {string} [managingTool] The managing tool used. The Default value is IBM_COMMERCE_INSIGHTS_TOOL.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutSearchLayoutsByState: async (storeId: string, q: string, profileName: string, state?: string, ownerID?: number, locationName?: string, name?: string, sortBy?: string, sortOrder?: string, managingTool?: string, pageNumber?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutSearchLayoutsByState', 'storeId', storeId)
            // verify required parameter 'q' is not null or undefined
            assertParamExists('layoutSearchLayoutsByState', 'q', q)
            // verify required parameter 'profileName' is not null or undefined
            assertParamExists('layoutSearchLayoutsByState', 'profileName', profileName)
            const localVarPath = `/store/{storeId}/layout`
                .replace(`{${"storeId"}}`, String(storeId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (ownerID !== undefined) {
                localVarQueryParameter['ownerID'] = ownerID;
            }

            if (locationName !== undefined) {
                localVarQueryParameter['locationName'] = locationName;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (profileName !== undefined) {
                localVarQueryParameter['profileName'] = profileName;
            }

            if (managingTool !== undefined) {
                localVarQueryParameter['managingTool'] = managingTool;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a layout in the store.
         * @summary Update layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Update layout details body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutUpdateLayout: async (storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutUpdateLayout', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutUpdateLayout', 'layoutId', layoutId)
            const localVarPath = `/store/{storeId}/layout/{layoutId}`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates widget and widget properties in a layout.
         * @summary Update widget and widget properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutUpdateWidget: async (storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'storeId' is not null or undefined
            assertParamExists('layoutUpdateWidget', 'storeId', storeId)
            // verify required parameter 'layoutId' is not null or undefined
            assertParamExists('layoutUpdateWidget', 'layoutId', layoutId)
            const localVarPath = `/store/{storeId}/layout/{layoutId}/widget`
                .replace(`{${"storeId"}}`, String(storeId))
                .replace(`{${"layoutId"}}`, String(layoutId));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (responseFormat !== undefined) {
                localVarQueryParameter['responseFormat'] = responseFormat;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LayoutApi - functional programming interface
 * @export
 */
export const LayoutApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LayoutApiAxiosParamCreator(configuration)
    return {
        /**
         * Adds a widget to a layout.
         * @summary Add widget to layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutAddWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutAddWidget(storeId, layoutId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates the layout.
         * @summary Create layout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {boolean} [autoGenerateName] The server automatically generates a name for the layout.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutCreateLayout(storeId: string, responseFormat?: string, autoGenerateName?: boolean, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutCreateLayout(storeId, responseFormat, autoGenerateName, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a layout for a specified store.
         * @summary Delete layout for store
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutDeleteLayout(storeId: string, layoutId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutDeleteLayout(storeId, layoutId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a widget from a layout.
         * @summary Delete widget from layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutDeleteWidget(storeId: string, layoutId: string, widgetId: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutDeleteWidget(storeId, layoutId, widgetId, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets layout details by layout unique ID.
         * @summary Get layout details by unique ID
         * @param {string} storeId The store identifier.
         * @param {number} layoutId The layout unique ID.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutFindLayoutByUniqueId(storeId: string, layoutId: number, profileName: string, responseFormat?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutFindLayoutByUniqueId(storeId, layoutId, profileName, responseFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates, updates, or deletes the location.
         * @summary Create, update, or delete location
         * @param {string} storeId The store identifier.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutManageLayoutStaticLocation(storeId: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutManageLayoutStaticLocation(storeId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs an action on layout properties. See each action for details on input and output.
         * @summary Update layout properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutPerformLayoutPropertiesAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutPerformLayoutPropertiesAction(storeId, layoutId, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs an action on layout static location. See each action for details on input and output.
         * @summary Update layout static location
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutPerformLayoutStaticLocationAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutPerformLayoutStaticLocationAction(storeId, layoutId, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Performs an action on widgets extended data. See each action for details on input and output.
         * @summary Update widget extended data
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutPerformWidgetExtendedDataAction(storeId: string, layoutId: string, widgetId: string, action: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutPerformWidgetExtendedDataAction(storeId, layoutId, widgetId, action, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Searches for layouts based on the layout state and the search criteria. The default sorting order is based on the created date of layouts.
         * @summary Get layouts by state and search criteria
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [state] The state of the layout. If this parameter is not specified, the default state is Active.
         * @param {number} [ownerID] The ownerID of the layout. When the managingTool is IBM_COMMERCE_INSIGHTS_TOOL, not specifying the ownerID parameter in a pagination request may result in variances based on the caller\&#39;s Access Control privileges.
         * @param {string} [locationName] The location name search criteria.
         * @param {string} [name] The layout name search criteria.
         * @param {string} [sortBy] The sort order for the layouts. The default sort criteria depends on the query.
         * @param {string} [sortOrder] The sorting order. The default sort order is ascending.
         * @param {string} [managingTool] The managing tool used. The Default value is IBM_COMMERCE_INSIGHTS_TOOL.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutSearchLayoutsByState(storeId: string, q: string, profileName: string, state?: string, ownerID?: number, locationName?: string, name?: string, sortBy?: string, sortOrder?: string, managingTool?: string, pageNumber?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutSearchLayoutsByState(storeId, q, profileName, state, ownerID, locationName, name, sortBy, sortOrder, managingTool, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a layout in the store.
         * @summary Update layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Update layout details body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutUpdateLayout(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutUpdateLayout(storeId, layoutId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates widget and widget properties in a layout.
         * @summary Update widget and widget properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async layoutUpdateWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LayoutLayout>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.layoutUpdateWidget(storeId, layoutId, responseFormat, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LayoutApi - factory interface
 * @export
 */
export const LayoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LayoutApiFp(configuration)
    return {
        /**
         * Adds a widget to a layout.
         * @summary Add widget to layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutAddWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutAddWidget(storeId, layoutId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates the layout.
         * @summary Create layout
         * @param {string} storeId The store identifier.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {boolean} [autoGenerateName] The server automatically generates a name for the layout.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutCreateLayout(storeId: string, responseFormat?: string, autoGenerateName?: boolean, body?: LayoutLayout, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutCreateLayout(storeId, responseFormat, autoGenerateName, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a layout for a specified store.
         * @summary Delete layout for store
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutDeleteLayout(storeId: string, layoutId: string, responseFormat?: string, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutDeleteLayout(storeId, layoutId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a widget from a layout.
         * @summary Delete widget from layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutDeleteWidget(storeId: string, layoutId: string, widgetId: string, responseFormat?: string, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutDeleteWidget(storeId, layoutId, widgetId, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets layout details by layout unique ID.
         * @summary Get layout details by unique ID
         * @param {string} storeId The store identifier.
         * @param {number} layoutId The layout unique ID.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutFindLayoutByUniqueId(storeId: string, layoutId: number, profileName: string, responseFormat?: string, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutFindLayoutByUniqueId(storeId, layoutId, profileName, responseFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates, updates, or deletes the location.
         * @summary Create, update, or delete location
         * @param {string} storeId The store identifier.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutManageLayoutStaticLocation(storeId: string, body?: LayoutLayout, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutManageLayoutStaticLocation(storeId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs an action on layout properties. See each action for details on input and output.
         * @summary Update layout properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformLayoutPropertiesAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any): AxiosPromise<void> {
            return localVarFp.layoutPerformLayoutPropertiesAction(storeId, layoutId, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs an action on layout static location. See each action for details on input and output.
         * @summary Update layout static location
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformLayoutStaticLocationAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any): AxiosPromise<void> {
            return localVarFp.layoutPerformLayoutStaticLocationAction(storeId, layoutId, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Performs an action on widgets extended data. See each action for details on input and output.
         * @summary Update widget extended data
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} widgetId The widget unique ID.
         * @param {string} action The action of the rest service.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutPerformWidgetExtendedDataAction(storeId: string, layoutId: string, widgetId: string, action: string, body?: LayoutLayout, options?: any): AxiosPromise<void> {
            return localVarFp.layoutPerformWidgetExtendedDataAction(storeId, layoutId, widgetId, action, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Searches for layouts based on the layout state and the search criteria. The default sorting order is based on the created date of layouts.
         * @summary Get layouts by state and search criteria
         * @param {string} storeId The store identifier.
         * @param {string} q The query name.
         * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
         * @param {string} [state] The state of the layout. If this parameter is not specified, the default state is Active.
         * @param {number} [ownerID] The ownerID of the layout. When the managingTool is IBM_COMMERCE_INSIGHTS_TOOL, not specifying the ownerID parameter in a pagination request may result in variances based on the caller\&#39;s Access Control privileges.
         * @param {string} [locationName] The location name search criteria.
         * @param {string} [name] The layout name search criteria.
         * @param {string} [sortBy] The sort order for the layouts. The default sort criteria depends on the query.
         * @param {string} [sortOrder] The sorting order. The default sort order is ascending.
         * @param {string} [managingTool] The managing tool used. The Default value is IBM_COMMERCE_INSIGHTS_TOOL.
         * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
         * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutSearchLayoutsByState(storeId: string, q: string, profileName: string, state?: string, ownerID?: number, locationName?: string, name?: string, sortBy?: string, sortOrder?: string, managingTool?: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutSearchLayoutsByState(storeId, q, profileName, state, ownerID, locationName, name, sortBy, sortOrder, managingTool, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a layout in the store.
         * @summary Update layout
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Update layout details body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutUpdateLayout(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutUpdateLayout(storeId, layoutId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates widget and widget properties in a layout.
         * @summary Update widget and widget properties
         * @param {string} storeId The store identifier.
         * @param {string} layoutId The layout unique ID.
         * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
         * @param {LayoutLayout} [body] Request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        layoutUpdateWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any): AxiosPromise<LayoutLayout> {
            return localVarFp.layoutUpdateWidget(storeId, layoutId, responseFormat, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LayoutApi - object-oriented interface
 * @export
 * @class LayoutApi
 * @extends {BaseAPI}
 */
export class LayoutApi extends BaseAPI {
    /**
     * Adds a widget to a layout.
     * @summary Add widget to layout
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutAddWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutAddWidget(storeId, layoutId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates the layout.
     * @summary Create layout
     * @param {string} storeId The store identifier.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {boolean} [autoGenerateName] The server automatically generates a name for the layout.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutCreateLayout(storeId: string, responseFormat?: string, autoGenerateName?: boolean, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutCreateLayout(storeId, responseFormat, autoGenerateName, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a layout for a specified store.
     * @summary Delete layout for store
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutDeleteLayout(storeId: string, layoutId: string, responseFormat?: string, options?: any) {
        return LayoutApiFp(this.configuration).layoutDeleteLayout(storeId, layoutId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a widget from a layout.
     * @summary Delete widget from layout
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} widgetId The widget unique ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutDeleteWidget(storeId: string, layoutId: string, widgetId: string, responseFormat?: string, options?: any) {
        return LayoutApiFp(this.configuration).layoutDeleteWidget(storeId, layoutId, widgetId, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets layout details by layout unique ID.
     * @summary Get layout details by unique ID
     * @param {string} storeId The store identifier.
     * @param {number} layoutId The layout unique ID.
     * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutFindLayoutByUniqueId(storeId: string, layoutId: number, profileName: string, responseFormat?: string, options?: any) {
        return LayoutApiFp(this.configuration).layoutFindLayoutByUniqueId(storeId, layoutId, profileName, responseFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates, updates, or deletes the location.
     * @summary Create, update, or delete location
     * @param {string} storeId The store identifier.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutManageLayoutStaticLocation(storeId: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutManageLayoutStaticLocation(storeId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs an action on layout properties. See each action for details on input and output.
     * @summary Update layout properties
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} action The action of the rest service.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutPerformLayoutPropertiesAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutPerformLayoutPropertiesAction(storeId, layoutId, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs an action on layout static location. See each action for details on input and output.
     * @summary Update layout static location
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} action The action of the rest service.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutPerformLayoutStaticLocationAction(storeId: string, layoutId: string, action: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutPerformLayoutStaticLocationAction(storeId, layoutId, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Performs an action on widgets extended data. See each action for details on input and output.
     * @summary Update widget extended data
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} widgetId The widget unique ID.
     * @param {string} action The action of the rest service.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutPerformWidgetExtendedDataAction(storeId: string, layoutId: string, widgetId: string, action: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutPerformWidgetExtendedDataAction(storeId, layoutId, widgetId, action, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Searches for layouts based on the layout state and the search criteria. The default sorting order is based on the created date of layouts.
     * @summary Get layouts by state and search criteria
     * @param {string} storeId The store identifier.
     * @param {string} q The query name.
     * @param {string} profileName Profile name. Profiles determine the subset of data returned by a query.
     * @param {string} [state] The state of the layout. If this parameter is not specified, the default state is Active.
     * @param {number} [ownerID] The ownerID of the layout. When the managingTool is IBM_COMMERCE_INSIGHTS_TOOL, not specifying the ownerID parameter in a pagination request may result in variances based on the caller\&#39;s Access Control privileges.
     * @param {string} [locationName] The location name search criteria.
     * @param {string} [name] The layout name search criteria.
     * @param {string} [sortBy] The sort order for the layouts. The default sort criteria depends on the query.
     * @param {string} [sortOrder] The sorting order. The default sort order is ascending.
     * @param {string} [managingTool] The managing tool used. The Default value is IBM_COMMERCE_INSIGHTS_TOOL.
     * @param {number} [pageNumber] Page number. Valid values are positive integers starting at 1. Use pageNumber with pageSize.
     * @param {number} [pageSize] Page size. Used to limit the amount of data returned by a query. Valid values are integers starting with 1. Use pageSize with pageNumber.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutSearchLayoutsByState(storeId: string, q: string, profileName: string, state?: string, ownerID?: number, locationName?: string, name?: string, sortBy?: string, sortOrder?: string, managingTool?: string, pageNumber?: number, pageSize?: number, options?: any) {
        return LayoutApiFp(this.configuration).layoutSearchLayoutsByState(storeId, q, profileName, state, ownerID, locationName, name, sortBy, sortOrder, managingTool, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a layout in the store.
     * @summary Update layout
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {LayoutLayout} [body] Update layout details body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutUpdateLayout(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutUpdateLayout(storeId, layoutId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates widget and widget properties in a layout.
     * @summary Update widget and widget properties
     * @param {string} storeId The store identifier.
     * @param {string} layoutId The layout unique ID.
     * @param {string} [responseFormat] The response format. Valid values are json and xml. If the request contains an input body, it must use the format specified in responseFormat. If the responseFormat is not specified, the accept  HTTP header determines the format of the response. If the accept  HTTP header is not specified then default response format is json.
     * @param {LayoutLayout} [body] Request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LayoutApi
     */
    public layoutUpdateWidget(storeId: string, layoutId: string, responseFormat?: string, body?: LayoutLayout, options?: any) {
        return LayoutApiFp(this.configuration).layoutUpdateWidget(storeId, layoutId, responseFormat, body, options).then((request) => request(this.axios, this.basePath));
    }
}
