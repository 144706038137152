/*
 *---------------------------------------------------
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *---------------------------------------------------
 */
//Standard libraries
import { takeLatest, call, actionChannel, take } from "redux-saga/effects";
//Redux
import * as ACTIONS from "../../action-types/order";
import * as WORKERS from "../workers/order";
import { FETCHING_CART_ACTION, COPY_CART_SUCCESS_ACTION, GET_CART_ACTION } from "../../actions/order";
import * as OD_WORKERS from "../workers/orderDetails";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  LOGIN_SUCCESS_ACTION,
  GUEST_LOGIN_SUCCESS_ACTION,
  INIT_USER_FROM_STORAGE_SUCCESS_ACTION,
  GUEST_LOGIN_FETCH_CART_ACTION,
} from "../../actions/user";
import { UpdateItemPriceActionType, UpdateItemsShippingAddressActionType } from "solteq/src/types/order/order.types";
import { ORDER_STATUS } from "constants/order";
import shippingInfoService from "_foundation/apis/transaction/shippingInfo.service";

/**
 * Order watch saga
 * watchers to intercept order actions
 */
export function* watchSaga() {
  yield takeLatest(ACTIONS.ITEM_ADD_REQUESTED, WORKERS.addItem);
  yield takeLatest(ACTIONS.COPY_CART, WORKERS.copyCart);
  yield takeLatest(ACTIONS.ITEM_REMOVE_REQUESTED, removeItemAndFetchCart);
  yield takeLatest(ACTIONS.ITEMS_REMOVE_REQUESTED, removeItemsAndFetchCart);
  yield takeLatest(ACTIONS.ITEMS_COPY_REQUESTED, copyItemsAndFetchCart);
  yield takeLatest(ACTIONS.BULKUPDATE_SHIPPING_DATES_REQUESTED, updateItemAndFetchCart);
  yield takeLatest(
    [FETCHING_CART_ACTION, GET_CART_ACTION, LOGIN_SUCCESS_ACTION, GUEST_LOGIN_SUCCESS_ACTION, COPY_CART_SUCCESS_ACTION],
    WORKERS.fetchCart
  );
  yield takeLatest(GUEST_LOGIN_FETCH_CART_ACTION, conditionallyFetchCart);
  yield takeLatest(INIT_USER_FROM_STORAGE_SUCCESS_ACTION, WORKERS.initFromStorageFetchCart);
  yield takeLatest(ACTIONS.ITEM_UPDATE_REQUESTED, updateItemAndFetchCart);
  yield takeLatest([ACTIONS.SHIPINFO_GET_REQUESTED], WORKERS.fetchShipInfo);
  yield takeLatest([ACTIONS.SHIPMODES_GET_REQUESTED], WORKERS.fetchShipModes);
  yield takeLatest(ACTIONS.SHIPMODE_UPDATE_REQUESTED, updateShipModeAndFetchCart);
  yield takeLatest(ACTIONS.PAYMETHODS_GET_REQUESTED, WORKERS.fetchPayMethods);
  yield takeLatest(ACTIONS.CHECKOUT_REQUESTED, WORKERS.checkoutOrder);
  yield takeLatest(ACTIONS.UPDATE_ITEMS_SHIPPING_ADDRESS_REQUESTED, updateItemsShippingAddressAndFetchCart);
  yield takeLatest(ACTIONS.UPDATE_ITEMS_PRICE_REQUESTED, updateItemsPriceAndFetchCart);
  yield takeLatest(ACTIONS.FETCH_ALL_ORDERS, WORKERS.getAllOrders);
  yield takeLatest(ACTIONS.FETCH_ALLOWABLE_SHIPMODES, WORKERS.getAllowableShipmodes);
  yield takeLatest(ACTIONS.FETCH_ACTIVE_INPROGRESS_ORDER_ITEM, fetchInProgressOrderAndCascade);
  yield takeLatest(ACTIONS.REMOVE_INPROGRESS_ORDER_ITEM, removeInProgressItem);
  yield takeLatest(ACTIONS.UPDATE_INPROGRESS_ORDER_ITEM, updateInProgressItem);
  yield takeLatest(ACTIONS.FETCH_ALLOWABLE_PAYMETHODS, WORKERS.getAllowablePaymethods);
  yield takeLatest(ACTIONS.FETCH_OPEN_ORDER_LINES, WORKERS.getOpenOrderLines);

  // SQSTRCOM-902: handle the update shipping infos as a queue
  const shippingss = yield actionChannel(ACTIONS.UPDATE_SHIPPING_INFO);
  // eslint-disable-next-line no-constant-condition
  while (true) {
    const action = yield take(shippingss);
    yield call(shippingInfoService.updateOrderShippingInfo, action.payload);

    yield call(WORKERS.fetchShipInfo, action);
    yield call(WORKERS.fetchCart, action);
  }
}

/**
 * Avoid fetching cart if guest-login was already triggered by some cart action
 *
 * Realistically, this can only be the add-to-cart action, but we check for cart
 *   context in the url that triggered the guest-login action here to be sure
 *
 * We can do this because all cart-actions are proceeded by a fetch-cart action anyway
 *   and this particular action would've yielded a 404 in the first place
 *
 * @param action redux action
 **/
function* conditionallyFetchCart(action: any) {
  const { payload, ...otherAction } = action;
  const { url, ...other } = payload;

  // fetch cart only if non-cart action triggered the guest-login
  if (!url.match(/\bcart\b/)) {
    yield call(WORKERS.fetchCart, { ...otherAction, payload: other });
  }
  }

  function* removeItemAndFetchCart(action: any) {
    yield call(WORKERS.removeItem, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* removeItemsAndFetchCart(action: any) {
    yield call(WORKERS.removeItems, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* copyItemsAndFetchCart(action: any) {
    yield call(WORKERS.addItem, action);
    yield call(WORKERS.updateShippingAddress, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* updateItemAndFetchCart(action: any) {
    yield call(WORKERS.updateItem, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* updateShipModeAndFetchCart(action: any) {
    yield call(WORKERS.updateShipMode, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* updateItemsShippingAddressAndFetchCart(action: PayloadAction<UpdateItemsShippingAddressActionType>) {
    yield call(WORKERS.updateItemsShippingAddress, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* updateItemsPriceAndFetchCart(action: PayloadAction<UpdateItemPriceActionType>) {
    yield call(WORKERS.updateItemsPrice, action);
    yield call(WORKERS.fetchCart, action);
  }

  function* removeInProgressItem(action: any) {
    const { payload, ...nonPayload } = action;
    const { items, ...rest } = payload;
    for (const orderItemId of items) {
      yield call(WORKERS.removeItem, {
        ...nonPayload,
        payload: { orderItemId, ...rest },
      });
    }
    yield call(fetchInProgressOrderAndCascade, action);
  }

  function* updateInProgressItem(action: any) {
    yield call(WORKERS.updateItem, action);
    yield call(fetchInProgressOrderAndCascade, action);
  }

  function* fetchInProgressOrderAndCascade(action: any) {
    yield call(WORKERS.fetchActiveInprogressOrderItem, action);

    // SQSTRCOM-802: refresh the orders list after changing active order this way
    const params = {
      payload: {
        query: {
          orderType: "all",
          activeOrg: true,
        },
        status: ORDER_STATUS.PendingOrder,
        ...action.payload,
      },
    };

    yield call(WORKERS.getAllOrders, params);
    yield call(OD_WORKERS.getOrderDetails, action);
  }
