import { StyledGrid, StyledProgressPlaceholder } from "@hcl-commerce-store-sdk/react-component";
import { ROUTE_CONFIG } from "configs/routes";
import { Suspense, useCallback } from "react";
import { useRoutes } from "react-router-dom";
import styled from "@mui/styled-engine-sc";
import { useSite } from "_foundation/hooks/useSite";

const RouteRenderer = () => {
  const { mySite } = useSite();
  const e = useRoutes(mySite.isB2B ? ROUTE_CONFIG.B2B : ROUTE_CONFIG.B2C);
  return e;
};

export const SolteqMainContainer = () => {
  const MemRouteRenderer = useCallback(RouteRenderer, []);

  return (
    <MainContainer item xs className="main-container full-width">
      <Suspense fallback={<StyledProgressPlaceholder className="vertical-padding-20" />}>
        <MemRouteRenderer />
      </Suspense>
    </MainContainer>
  );
};

const MainContainer = styled(({ ...props }) => <StyledGrid {...props} />)`
  ${({ theme }) =>
    /* Some hardcoded values for margin to header for now. Observing header height a surprisingly complex problem.
     *DO NOT adjust these unless it's necessary.
     */
    `
     ${theme.breakpoints.up("xs")} {
       margin-top: 70px !important;
    }

    ${theme.breakpoints.up("sm")} {
       margin-top: 220px !important;
    }

    ${theme.breakpoints.up("md")} {
       margin-top: 320px !important;
    }

  `}

  @media (min-width: 600px) {
    padding: 0 14px 0 14px;
    margin: 0 auto;
  }

  // For MCM content
  > .page:not(.MuiContainer-root) {
    padding: 0 24px 0 24px;
    overflow-wrap: break-word;
  }

  @media (min-width: 600px) {
    padding: 0 14px 0 14px;
    margin: 0 auto;
  }

  @media (max-width: 4000px) {
    max-width: 1880px !important;
  }

  @media (max-width: 2000px) {
    max-width: 1580px !important;
  }

  @media (max-width: 1600px) {
    max-width: 1380px !important;
  }
`;
