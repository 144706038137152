import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as HeaderLogo } from "../assets/images/str_logo.svg";
import { ReactComponent as HeaderLogoSmall } from "../assets/images/str_logo_small.svg";
import styled from "@mui/styled-engine-sc";
import useMediaQuery from "@mui/material/useMediaQuery";

const HomeButton: React.FC = () => {
  // Calculated manually. DO NOT adjust this unless needed.
  const matches = useMediaQuery("(max-width: 800px)");
  return <Link to="/">{matches ? <SvgLogoSmall /> : <SvgLogo />}</Link>;
};

const SvgLogo = styled(HeaderLogo)`
  width: 17em;
  display: block;
  & path,
  polygon {
    fill: #ffffff;

    &.cls-2 {
      stroke: #ffffff;
      stroke-width: 0.3px;
    }
  }
`;

const SvgLogoSmall = styled(HeaderLogoSmall)`
  width: 3.1em;
  display: block;

  & path,
  polygon {
    fill: #ffffff;
  }
`;

export default HomeButton;
